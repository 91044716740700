import { IonButton, IonInput, IonLabel } from "@ionic/react";
import "./Styles.css";

function CustomStakeButtons() {
  const customStakeButtons = [
    {
      stakeBtn: "Button 01",
    },
    {
      stakeBtn: "Button 02",
    },
    {
      stakeBtn: "Button 03",
    },
    {
      stakeBtn: "Button 04",
    },
    {
      stakeBtn: "Button 05",
    },
    {
      stakeBtn: "Button 06",
    },
    {
      stakeBtn: "Button 07",
    },
    {
      stakeBtn: "Button 08",
    },
  ];
  return (
    <div className="custom-stake-container">
      <div>
        <div className="custom-stake-heading">Custom Stake Buttons</div>
      </div>
      <div className="custom-stake-input-container mt-10">
        {customStakeButtons.map((ele, index) => (
          <div key={index} className="custom-stake-input-div">
            <IonLabel>{ele?.stakeBtn}</IonLabel>
            <IonInput
              className="custom-stake-input"
              type="text"
              placeholder="Enter"
            />
          </div>
        ))}
      </div>
      <div className="mt-10">
        <IonButton expand="block" className="load-reports-button">
          Update Stake
        </IonButton>
      </div>
    </div>
  );
}

export default CustomStakeButtons;
