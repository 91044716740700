import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonModal,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { call } from "../../components/api/axios";
import {
  EDIT_USER_PROFILE,
  GET_USER_PROFILE_INFO,
  SMS_REQUEST,
  USER_IMAGE_UPLOAD,
} from "../../components/api/endpoints";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { setLoading } from "../../redux/actions";
import Otpinput from "./OtpInput";
import {
  person,
  mail,
  logoWhatsapp,
  logoSkype,
  callSharp,
} from "ionicons/icons";

const EditProfile = () => {
  let user_id = localStorage.getItem("user_id");

  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState([]);
  const [sendOtpError, setOtpSendError] = useState("");
  const [optLoad, setOtpLoad] = useState(false);
  const [isMobileNumberChanged, setIsChangesNumberChanged] = useState(false);
  const history = useHistory();
  const [isNumberVerified, setIsNumberVerified] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");

  const [editData, setEditData] = useState({});

  const [showAlert, setShowAlert] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [error, setError] = useState(false);

  const [userNameErr, setUserNameErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [whatsAppErr, setWhatsAppErr] = useState(false);
  const [profileImage, setProfileImage] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.detail.value });
  };

  const handleUpdateProfile = () => {
    if (editData?.userName?.trim() === "" || editData?.userName?.length === 0) {
      setErrMsg("Please enter username");
      setUserNameErr(true);
      return;
    }
    if (editData?.name?.trim() === "" || editData?.name?.length === 0) {
      setErrMsg("Please enter name");
      setNameErr(true);
      return;
    }
    if (editData?.email?.trim() === "" || editData?.email?.length === 0) {
      setErrMsg("Please enter your email");
      setEmailErr(true);
      return;
    }
    if (editData?.mobile?.trim() === "" || editData?.mobile?.length === 0) {
      setErrMsg("Please enter mobile number");
      setMobileErr(true);
      return;
    }
    if (
      editData?.watsapp_no?.trim() === "" ||
      editData?.watsapp_no?.length === 0
    ) {
      setErrMsg("Please enter whatsapp number");
      setWhatsAppErr(true);
      return;
    }
    editUserProfile();
  };

  const editUserProfile = async () => {
    let editProfilePayload = {
      user_id: user_id,
      mobile_no: editData?.mobile,
      email_id: editData?.email,
      user_name_full: editData?.name,
      skype_id: editData?.skype,
      watsapp_no: editData?.watsapp_no || "",
    };
    dispatch(setLoading(true));

    await call(
      {
        ...EDIT_USER_PROFILE,
        url: EDIT_USER_PROFILE.url,
      },
      editProfilePayload
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          if (res?.data?.error === "true") {
            setError(true);
            setErrMsg(res?.data?.message);
          } else {
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              history.push("/profile");
            }, 1000);
          }
        } else {
          dispatch(setLoading(false));
          setErrMsg(res?.data?.message);
          setError(true);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
        setErrMsg(err?.message);
        setError(true);
      });
  };
  const handleImageUpload = (e) => {
    transactionImageUpload(e.target.files[0]);
  };
  const transactionImageUpload = async (img) => {
    dispatch(setLoading(true));
    let transactionUploadData = {
      user_id: user_id,
      event_type: "user_profile_image",
    };
    await call(
      {
        ...USER_IMAGE_UPLOAD,
        url: USER_IMAGE_UPLOAD.url,
      },
      transactionUploadData
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          if (res?.data?.error === "true") {
            setErrMsg(res?.data?.message);
            setError(true);
          } else {
            uploadImgOnAWS(
              res?.data?.data?.result?.url,
              res?.data?.data?.result,
              img
            );
          }
        } else {
          setErrMsg(res?.data?.message);
          setError(true);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        setErrMsg(err?.message);
        dispatch(setLoading(false));
        setError(true);
        console.log(err);
      });
  };
  const uploadImgOnAWS = async (url, responseData, img) => {
    dispatch(setLoading(true));
    var formData = new FormData();
    formData.append("key", responseData?.fields?.key);
    formData.append(
      "x-amz-security-token",
      responseData?.fields["x-amz-security-token"]
    );
    formData.append("signature", responseData?.fields?.signature);
    formData.append("policy", responseData?.fields?.policy);
    formData.append("AWSAccessKeyId", responseData?.fields?.AWSAccessKeyId);
    formData.append("file", img);
    await axios({
      method: "post",
      url: url,
      data: formData,
    })
      .then((res) => {
        dispatch(setLoading(false));
        getProfileInfo();
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const getProfileInfo = async () => {
    dispatch(setLoading(true));
    await call(
      {
        ...GET_USER_PROFILE_INFO,
        url: GET_USER_PROFILE_INFO?.url,
      },
      { user_id: user_id }
    )
      .then((res) => {
        if (res?.status === 200) {
          setProfileImage(res?.data?.data?.result?.display_picture);
          dispatch(setLoading(false));
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const smsRequest = async () => {
    return call(SMS_REQUEST, {
      userId: user_id,
      mobileNo: mobileNumber,
    });
  };

  const getProfileData = async () => {
    dispatch(setLoading(true));
    await call(
      {
        ...GET_USER_PROFILE_INFO,
        url: GET_USER_PROFILE_INFO?.url,
      },
      { user_id: user_id }
    )
      .then((res) => {
        if (res?.status === 200) {
          let temp = res?.data?.data?.result;
          setEditData({
            ...profileData,
            userName: temp?.user_name || "",
            name: temp?.user_name_full || "",
            email: temp?.emailId || "",
            mobile: temp?.mobile_no || "",
            watsapp_no: temp?.watsapp_no || "",
            skype: "",
          });
          localStorage.setItem("image",temp?.display_picture)
          setProfileImage(temp?.display_picture);
          dispatch(setLoading(false));
        } else {
          console.log(res?.data?.message);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const handleSendOtp = async () => {
    setOtpLoad(true);
    let smsRequestResult = await smsRequest();
    if (smsRequestResult?.data?.error === "true") {
      setOtpLoad(false);
      return setOtpSendError(smsRequestResult?.data?.message);
    } else {
      setOtpLoad(false);
      setOtpSendError("");
      setIsOpen(true);
    }
  };

  useEffect(() => {
    getProfileData();
  }, [isNumberVerified]);

  return (
    <IonPage className="background">
      <div className="account-header-container">
        <Header title={`Edit Profile`} />
      </div>
      <div className="support-home edit-profile-container">
        <div className="edit-profile-img-container">
          <IonAvatar className="edit-profile-img">
            <IonImg
              src={
                profileImage != "NA"
                  ? profileImage
                  : process.env.PUBLIC_URL + "/images/profileAvatart.webp"
              }
              alt="edit-profil-image"
            />
          </IonAvatar>
        </div>
        <div className="edit-profile-icon">
          <label htmlFor="upload-button">
            <img
              className="edit-icon-black"
              src={process.env.PUBLIC_URL + "/images/editIconBlack.svg"}
            />
            {/* <IonIcon className="edit-icon-black" icon={pencilSharp} /> */}
          </label>
          <input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={(e) => handleImageUpload(e)}
          />
        </div>
        <div>
          <div
            className={`mb-5 transaction-input edit-profile-input-properties`}
          >
            <IonIcon
              icon={person}
              slot="start"
              className="edit-profile-user-icon"
            />
            <IonInput
              className="edit-profile-ionInput"
              placeholder="User Name"
              type="text"
              name="userName"
              onIonChange={(e) => {
                handleChange(e);
                setUserNameErr(false);
              }}
              value={editData?.userName}
            ></IonInput>
          </div>
          <div
            hidden={!userNameErr}
            className="error-message error-message-margin"
          >
            {errMsg}
          </div>
          <div
            className={`mb-5 mt-10 transaction-input edit-profile-input-properties`}
          >
            <IonIcon
              icon={person}
              slot="start"
              className="edit-profile-user-icon"
            />
            <IonInput
              className="edit-profile-ionInput"
              placeholder="Enter Name"
              type="text"
              name="name"
              onIonChange={(e) => {
                handleChange(e);
                setNameErr(false);
              }}
              value={editData?.name}
            ></IonInput>
          </div>
          <div hidden={!nameErr} className="error-message error-message-margin">
            {errMsg}
          </div>
          <div
            className={`mb-5 mt-10 transaction-input edit-profile-input-properties`}
          >
            <IonIcon
              icon={mail}
              slot="start"
              className="edit-profile-user-icon"
            />
            <IonInput
              className="edit-profile-ionInput"
              placeholder="Enter your email"
              type="email"
              name="email"
              onIonChange={(e) => {
                handleChange(e);
                setEmailErr(false);
              }}
              value={editData?.email}
            ></IonInput>
          </div>
          <div
            hidden={!emailErr}
            className="error-message error-message-margin"
          >
            {errMsg}
          </div>

          <div
            className={`mb-5 mt-10 transaction-input edit-profile-input-properties`}
          >
            <IonIcon
              icon={logoWhatsapp}
              slot="start"
              className="edit-profile-user-icon"
            />
            <IonInput
              className="edit-profile-ionInput"
              placeholder="Enter whatsApp number"
              type="nubmer"
              name="watsapp_no"
              onIonChange={(e) => {
                handleChange(e);
                setWhatsAppErr(false);
              }}
              value={editData?.watsapp_no}
            ></IonInput>
          </div>
          <div
            hidden={!whatsAppErr}
            className="error-message error-message-margin"
          >
            {errMsg}
          </div>
          <div
            className={`mb-5 mt-10 transaction-input edit-profile-input-properties`}
          >
            <IonIcon
              icon={logoSkype}
              slot="start"
              className="edit-profile-user-icon"
            />
            <IonInput
              className="edit-profile-ionInput"
              placeholder="Enter Skype id"
              type="text"
              name="skype"
              onIonChange={(e) => {
                handleChange(e);
              }}
              value={editData?.skype}
              clearInput
            ></IonInput>
          </div>
          <div className="verify-phn-container mt-10 mb-10">
            <div className="transaction-input edit-profile-input-properties">
              <IonIcon
                icon={callSharp}
                slot="start"
                className="edit-profile-user-icon"
              />
              <IonInput
                className="edit-profile-ionInput"
                placeholder="Enter phone no"
                type="nubmer"
                name="mobile"
                onIonChange={(e) => {
                  handleChange(e);
                  setMobileErr(false);
                  setMobileNumber(e.detail.value);
                  setIsChangesNumberChanged(
                    editData?.mobile !== e.detail.value ? true : false
                  );
                }}
                value={editData?.mobile}
              ></IonInput>
            </div>
            <IonButton
              className="ml-10 phn-verify"
              onClick={() => handleSendOtp()}
              disabled={!isMobileNumberChanged}
            >
              {optLoad ? (
                <div className="sign-in-loading">
                  <span>Sending OTP... </span>
                  <IonSpinner name="crescent" />
                </div>
              ) : (
                "UPDATE"
              )}
            </IonButton>
          </div>
          <div
            hidden={!mobileErr}
            className="error-message error-message-margin"
          >
            {errMsg}
          </div>
          {sendOtpError !== "" && (
            <div className="error-message error-message-margin">
              {sendOtpError}
            </div>
          )}
          <div className="login-btn mt-20 mb-5" style={{ width: "100%" }}>
            <IonButton
              onClick={() => handleUpdateProfile()}
              disabled={isOpen || isMobileNumberChanged}
            >
              Update Profile
            </IonButton>
          </div>
          <div hidden={!error} className="error-message error-message-margin">
            {errMsg}
          </div>
        </div>
      </div>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => {
          setShowAlert(false);
          history.push("/profile");
        }}
        cssClass="my-custom-class"
        subHeader={"Profile Updated Sucessfully"}
      />
      <div>
        <IonModal className="verify-otp-modal" isOpen={isOpen}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Verify OTP</IonTitle>
            </IonToolbar>
          </IonHeader>
          <Otpinput
            setIsNumberVerified={() => setIsNumberVerified(!isNumberVerified)}
            setIsOpen={() => setIsOpen(false)}
            setIsChangesNumberChanged={() =>
              setIsChangesNumberChanged(!isMobileNumberChanged)
            }
          />
        </IonModal>
      </div>

      <Footer />
    </IonPage>
  );
};

export default EditProfile;
