import { IonButton, IonIcon, IonPage } from "@ionic/react";
import { checkmarkSharp } from "ionicons/icons";
import "./styles.css";

function ResetPwdPopup() {
  return (
    <IonPage className="reset-password-popup-container">
      <div className="reset-support-home">
        <div className="reset-popup-rightIcon-div mt-20">
          <IonIcon className="reset-popup-rightIcon" icon={checkmarkSharp} />
        </div>
        <div className="reset-heading mt-10">Thank You!</div>
        <div className="reset-heading-one mt-5">
          Your Password Changes Successfully
        </div>
        <div className="reset-heading-two mt-5 mb-20">
          <p>
            Your Transaction is now being processed. We will let you know once
            the Trasaction is marked as complete from master/admin and amount
            will credited to your account.
          </p>
          <p className="mt-5">
            In the meantime you can also check the status of your Order
          </p>
        </div>
        <div className="reset-password-btn">
          <IonButton>Okay</IonButton>
        </div>
      </div>
    </IonPage>
  );
}

export default ResetPwdPopup;
