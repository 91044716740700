export const SET_LOADING = "SET_LOADING";
export const SET_LOGIN_MODAL = "SET_LOGIN_MODAL";
export const SET_AUTHENTICATOR_MODAL = "SET_AUTHENTICATOR_MODAL";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const SET_OFFERS_AND_NOTIFICATIONS = "SET_OFFERS_AND_NOTIFICATIONS";
export const SET_USER_WALLETS = "SET_USER_WALLETS";
export const SET_UPCOMING_EVENTS = "SET_UPCOMING_EVENTS";
export const SET_USER_TRANSACTIONS = "SET_USER_TRANSACTIONS";
export const SET_WEB_ACCOUNT_LIST = "SET_WEB_ACCOUNT_LIST";
export const SET_WEB_ACCOUNT_REGISTERED_LIST =
  "SET_WEB_ACCOUNT_REGISTERED_LIST";
export const SET_ACCOUNT_REFFERAL = "SET_ACCOUNT_REFFERAL";
export const SET_ALL_TICKETS = "SET_ALL_TICKETS";
export const SET_ALL_MEETINGS = "SET_ALL_MEETINGS";
export const SET_SUPPORT_DATA = "SET_SUPPORT_DATA";
export const WEB_ACCOUNT_INFO = " WEB_ACCOUNT_INFO";
export const RECENT_USER_TRANSACTIONS = "RECENT_USER_TRANSACTIONS";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_PRIVACY_POLICY = "SET_PRIVACY_POLICY";
export const SET_MEETING_IS_STARTED = "SET_MEETING_IS_STARTED";
export const SET_MINIMIZE = "SET_MINIMIZE";
export const SET_MAXMIZE = "SET_MAXMIZE";
