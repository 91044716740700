import React, { useEffect, useState } from "react";
import { IonButton, IonInput, IonItem, IonLabel ,IonModal,IonSelect,IonSelectOption } from "@ionic/react";
import { useHistory } from "react-router-dom";
import ThankYouModel from "../../../components/common/thankyou-model";
import Header from "../../../components/header";
import "./styles.css";
import { call } from "../../../components/api/axios";
import { GET_USER_WALLET_DETAILS, WEBSITE_REQUEST_NEW_ID } from "../../../components/api/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setUserWallets } from "../../../redux/actions";
function CreateId(props) {
  let accountDetails = props?.location?.state?.data;
  let userWalletsData = useSelector((state) => state?.data?.userWallets);
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [userName, setUserName] = useState("");
  const [userNameErr, setUserNameErr] = useState(false);
  const [intialDeposit, setIntialDeposit] = useState(0);
  const [intialDepositErr, setIntialDepositErr] = useState(false);
  const [userWalletData, setUserWalletData] = useState(userWalletsData);
  const [showCreateId, setShowCreateId]=useState(false);

  const [flag, setFlag] = useState(false);

  const handleUserName = (e) => {
    setUserNameErr(false);
    setUserName(e.detail.value);
  };

  const handleShowCreateId =(e)=>{
     setShowCreateId(true);
  };

  const handleInitialDeposit = (e) => {
    setIntialDepositErr(false);
    setIntialDeposit(parseInt(e.detail.value));
  };

  const websiteRequestNewId = async () => {
    dispatch(setLoading(true));

    let requestIdData = {
      user_id: `${localStorage?.getItem("user_id")}`,
      web_user_id_req: userName,
      ini_deposit: intialDeposit,
      website_id: accountDetails?.web_id,
      master_id: localStorage?.getItem("master_id"),
    };

    await call(
      {
        ...WEBSITE_REQUEST_NEW_ID,
        url: WEBSITE_REQUEST_NEW_ID.url,
      },
      requestIdData
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          if (res?.data?.error === "true") {
            setError(true);
            setErrMsg(res?.data?.message);
          } else {
            setFlag(true);
          }
        } else {
          dispatch(setLoading(false));
          setErrMsg(res?.data?.message);
          setError(true);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
        setErrMsg(err?.message);
        setError(true);
      });
  };

  const getUserWalletDetails = async () => {
    userWalletData?.length === 0 && dispatch(setLoading(true));
    await call(
      { ...GET_USER_WALLET_DETAILS, url: GET_USER_WALLET_DETAILS.url },
      { user_id: `${localStorage?.getItem("user_id")}` }
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          dispatch(setUserWallets(res?.data?.data?.result));
          setUserWalletData(res?.data?.data?.result);
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err?.message);
      });
  };

  const handleCreateId = () => {
      setFlag(true);
      // setShowCreateId(false);
    if (userName?.trim() === "" || userName?.length === 0) {
      setErrMsg("Please enter username");
      setUserNameErr(true);
      return;
    }
    if (intialDeposit?.length === 0) {
      setErrMsg("Please enter Initial deposit");
      setIntialDepositErr(true);
      return;
    }
    if (intialDeposit > userWalletData?.wallet_balance || userWalletData?.wallet_balance < 1000) {
      setErrMsg(`You don't enough balance in your wallet`);
      setIntialDepositErr(true);
      return;
    }
    if (intialDeposit < accountDetails?.web_min_deposit) {
      setErrMsg(`Please enter min deposit ${accountDetails?.web_min_deposit}`);
      setIntialDepositErr(true);
      return;
    }
    if (userWalletData?.wallet_balance - intialDeposit < 1000) {
      setErrMsg(`Remaining wallet balance should be greatethan 1000`);
      setIntialDepositErr(true);
      return;
    }
   
    websiteRequestNewId();
  };

  const handleCancel = () => {
    setShowCreateId(false);
  };
  const handleCancelModel = () => {
    setFlag(false);
    setTimeout(() => {
      history.push("/accounts");
    }, 500);
  };
  const handleBackToHome = () => {
    setFlag(false);
    setTimeout(() => {
      history.push("/");
    }, 500);
  };

  useEffect(()=>{
    getUserWalletDetails();
  }, [])

  return (
    <div className="create-id-container">
      <div className="accoutns-notification">
        <Header title="Create ID" />
      </div>
      <div className="id-details-heading">ID Details</div>
      <div className="id-details-content">
        <div className="id-details-sub-content mb-10">   
          <div><img className="id-details-icons" src={process.env.PUBLIC_URL + "/images/websiteIcon.png"} alt="webSiteIcon" />Website Name</div>
          <div>{accountDetails?.web_name || "-"}</div>
        </div>
        <div className="id-details-sub-content mb-10">
          <div><img className="id-details-icons" src={process.env.PUBLIC_URL + "/images/urlIcon.png"} alt="webSiteIcon" />URL</div>
          <div>{accountDetails?.web_url || "-"}</div>
        </div>
        <div className="id-details-sub-content mb-10">
          <div><img className="id-details-icons" src={process.env.PUBLIC_URL + "/images/depositIcon.png"} alt="webSiteIcon" />Min Deposit</div>
          <div>{accountDetails?.web_min_deposit || "-"}</div>
        </div>
        <div className="id-details-sub-content mb-10">
          <div><img className="id-details-icons" src={process.env.PUBLIC_URL + "/images/depositIcon.png"} alt="webSiteIcon" />Min Withdrawal</div>
          <div>{accountDetails?.web_min_withdraw || "-"}</div>
        </div>
        <div className="id-details-sub-content mb-10">
          <div><img className="id-details-icons" src={process.env.PUBLIC_URL + "/images/depositIcon.png"} alt="webSiteIcon" />Min Maintaining Bal</div>
          <div>{accountDetails?.web_min_balance || "-"}</div>
        </div>
        <div className="id-details-sub-content mb-10">
          <div><img className="id-details-icons" src={process.env.PUBLIC_URL + "/images/depositIcon.png"} alt="webSiteIcon" />Max Withdrawal</div>
          <div>{accountDetails?.web_max_withdraw || "-"}</div>
        </div>
      </div>
      <div className="create-id-form">
        <IonModal className="create-id-modal" isOpen={showCreateId}  onDidDismiss={()=>setShowCreateId(false)}>
          <div className="create-id-close-icon"><span onClick={() => handleCancel()} class="material-symbols-outlined">close</span></div>
          <div className="created-id-header mb-10">Create ID</div>
          <div className="create-id-input-container">
          <span class="material-symbols-outlined">account_circle</span>
          <IonInput
            className="create-id-input"
            placeholder="Username"
            type="text"
            name="username"
            onIonChange={(e) => handleUserName(e)}
            defaultValue={userName}
            clearInput
          ></IonInput>
          </div>
        
        <div
          hidden={!userNameErr}
          className="error-message error-message-margin"
        >
          {errMsg}
        </div>
          <div className="create-id-input-container">
          <span class="material-symbols-outlined">currency_rupee</span>
          <select
          className="create-id-select"
            type="number"
            name="intialDeposit"
            onIonChange={(e) => handleInitialDeposit(e)}
            defaultValue={intialDeposit}
            clearInput
          >
            <option>Initial Deposit</option>
            <option>500</option>
            <option>1000</option>
          </select>
          </div>
        <div
          hidden={!intialDepositErr}
          className="error-message error-message-margin"
        >
          {errMsg}
        </div>
    
        
        <div>
          {
            <ThankYouModel
              successAlert={flag}
              handleCancel={()=>handleCancelModel()}
              handleBackToHome={handleBackToHome}
            />
          }
        </div>
        <div
          hidden={!error}
          className="error-message error-message-margin mb-30"
        >
          {errMsg}
        </div>
        <div className="create-id-button mt-40 mb-20" style={{ width: "100%" }}>
          <IonButton onClick={() => handleCreateId()}>Create ID</IonButton>
        </div>
        <div className="signup-now text-center mb-20">
          <span onClick={() => handleCancel()}>Cancel</span>
        </div>
        </IonModal>
        <div className="login-btn mt-40 mb-5" style={{ width: "100%" }}>
          <IonButton onClick={() => handleShowCreateId()}>Create ID</IonButton>
        </div>        
      </div>
    </div>
  );
}

export default CreateId;
