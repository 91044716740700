import { IonIcon, IonImg } from "@ionic/react";
import WalletBalanceDetails from "./WalletBalanceDetails";
import { closeOutline } from "ionicons/icons";

function WalletModal(props) {
  return (
    <>
      <div className="flex-sp-b my-wallet-header">
        <div className="flex">
          <IonImg
            className="reports-image"
            src={process.env.PUBLIC_URL + "/images/myWalletImg.png"}
            alt="report-icons"
          ></IonImg>
          <div className="ml-5">Wallet Balance</div>
        </div>
        <div onClick={props.handleWalletModalClose}>
          <IonIcon className="my-wallet-cross-icon" icon={closeOutline} />
        </div>
      </div>
      <select className="my-wallet-dropdown mb-5 ml-5">
        <option className="my-wallet-dropdown-options">INR Wallet</option>
        <option className="my-wallet-dropdown-options">INR Wallet2</option>
        <option className="my-wallet-dropdown-options">INR Wallet3</option>
      </select>
      <WalletBalanceDetails />
    </>
  );
}

export default WalletModal;
