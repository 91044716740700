import WalletBalanceDetails from "./WalletBalanceDetails";
import DateRange from "./DateRange";

function CasinoWallet() {
  return (
    <div className="profit-loss-container">
      <WalletBalanceDetails />
      <DateRange />
    </div>
  );
}

export default CasinoWallet;
