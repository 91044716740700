import "./styles.css";

function SportsBook() {
  const sportsBookDetails = [
    {
      ballImg: "/images/cricketBallIcon.png",
      sportsName: "Cricket",
    },
    {
      ballImg: "/images/footBallIcon.png",
      sportsName: "Soccer",
    },
    {
      ballImg: "/images/tennisBallIcon.png",
      sportsName: "Tennis",
    },
    {
      ballImg: "/images/tableTennisIcon.png",
      sportsName: "TableTennis",
    },
    {
      ballImg: "/images/basketballIcon.png",
      sportsName: "Basketball",
    },
  ];
  const providersDetails = [
    {
      providerImage: "/images/evolationLogo.png",
    },
    {
      providerImage: "/images/royalGamingLogo.png",
    },
    {
      providerImage: "/images/ezugiLogo.png",
    },
    {
      providerImage: "/images/asiaGamingLogo.png",
    },
    {
      providerImage: "/images/oneTouchLogo.png",
    },
    {
      providerImage: "/images/superSpadeLogo.png",
    },
  ];
  return (
    <div className="sports-book-container mt-5">
      <div>
        <div className="sports-book-heading">Sports Book</div>
        <div className="sports-book-yellow-line"></div>
        <div className="custom-sports-container">
          <div className="username-div mt-10">
            {sportsBookDetails &&
              sportsBookDetails?.map((value, index) => (
                <div className="flex sports-book-cricketBall-div" key={index}>
                  <div className="sports-book-balls-div">
                    <img
                      className="sports-book-cricket-ball"
                      src={process.env.PUBLIC_URL + value.ballImg}
                      alt="Cricket-Ball"
                    />
                  </div>
                  <span className="mt-5">{value.sportsName}</span>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div>
        <div className="sports-book-heading">Providers</div>
        <div className="sports-book-providers-yellow-line"></div>
        <div className="sports-book-providers-div mt-5  flex">
          {providersDetails &&
            providersDetails?.map((value, index) => (
              <div className="custom-sports-provider-border">
                  <div key={index} className="sports-book-providers mt-5">
                <img
                  className="sports-book-providers-image "
                  src={process.env.PUBLIC_URL + value.providerImage}
                  alt="Providers-Logo"
                />
              </div>
              </div>
            
            ))}
        </div>
      </div>

      <hr className="sports-book-last-line mt-10 mb-10" />
    </div>
  );
}

export default SportsBook;
