import React, { useState, useEffect } from "react";
import { IonButton, IonIcon, IonInput, IonImg } from "@ionic/react";
import {
  chevronUpCircleOutline,
  chevronDownCircleOutline,
} from "ionicons/icons";
import DateRange from "./DateRange";

function CasinoReports() {
  const [activeIndex, setActiveIndex] = useState(false);
  const [indexPosition, setIndexPosition] = useState("");
  const [sportName, setSportName] = useState("");
  const [showSportList, setShowSportList] = useState(false);
  const handleActiveIndex = (index) => {
    setActiveIndex(!activeIndex);
    setIndexPosition(index);
  };
  const handleSportList = (value) => {
    setSportName(value);
  };
  const handleSport = () => {
    setShowSportList((prev) => !prev);
  };
  const matchDetails = [
    {
      game: "Casino",
      marketId: "262969153",
      matchName: "Benelux Slingshot(Auto-Roulette La Partage)",
      comm: "Comm",
      commData: 0.0,
      netWL: "Net W/L",
      netWLData: 260.0,
    },
    {
      game: "Casino",
      marketId: "262969153",
      matchName: "Bacarata",
      comm: "Comm",
      commData: 0.0,
      netWL: "Net W/L",
      netWLData: 9.0,
    },
    {
      game: "Casino",
      marketId: "262969153",
      matchName: "Andar Bahar",
      comm: "Comm",
      commData: 0.0,
      netWL: "Net W/L",
      netWLData: 9.0,
    },
  ];
  const sportsList = [
    "All",
    "Ezugi",
    "Evolution Game",
    "Indian Casino",
    "Virutal Sports",
    "Royal Gaming",
    "Super Spade",
    "One Touch",
  ];
  const individualData = [
    {
      marketName: "T Casino Park",
      betID: "547384993",
      placed: "06-06-2023 10:00:00",
      selection: "Benelux Slingshot(Auto-Roulette La Partage)",
      result: "",
      status: "Win",
      type: "Back",
      odd: 0.0,
      comm: 0,
      stake: 160,
      winLoss: 160,
    },
    {
      marketName: "T Casino Park",
      betID: "547384993",
      placed: "06-06-2023 10:00:00",
      selection: "Benelux Slingshot(Auto-Roulette La Partage)",
      result: "",
      status: "Loss",
      type: "Back",
      odd: 0.0,
      comm: 0,
      stake: 100,
      winLoss: 100,
    },
       {
      marketName: "T Casino Park",
      betID: "547384993",
      placed: "06-06-2023 10:00:00",
      selection: "Benelux Slingshot(Auto-Roulette La Partage)",
      result: "",
      status: "Loss",
      type: "Back",
      odd: 0.0,
      comm: 0,
      stake: 100,
      winLoss: 100,
    },
  ];

  return (
    <div className="profit-loss-container">
      <div className="account-statement-header">Order Type</div>
      <div
        className="flex website-dropdown mt-5 custom-dropdown"
        onClick={() => {
          handleSport();
        }}
      >
        {sportName ? sportName : "All"}
        <IonIcon
          className="websites-arrow"
          icon={
            showSportList ? chevronUpCircleOutline : chevronDownCircleOutline
          }
        />
        {showSportList && (
          <div className="wid-left betstatus-list custom-casino-dropdown">
            {sportsList.map((value, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    handleSportList(value);
                  }}
                >
                  {value}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <DateRange />
      <div className="account-statement-header mt-5 mb-5">Result Found</div>
      <div>
        {matchDetails &&
          matchDetails?.map((value, index) => (
            <div className="flex-column profit-loss-data my-wallet-content-div mb-5">
              <div className="flex-sp-b mb-5" key={index}>
                <div className="flex-column w-60">
                  <div className="my-wallet-tl-ml">{value.game}</div>
                  <div className="my-wallet-tl-ml font-w300">
                    {`Market Id : ${value.marketId}`}
                  </div>
                  <div className="my-wallet-tl-ml">{value.matchName}</div>
                </div>
                <div className="w-15">
                  <div>{value.comm}</div>
                  <div>{value.commData.toFixed(2)}</div>
                </div>
                <div className="w-15">
                  <div>{value.netWL}</div>

                  <div
                    className={`${
                      value.netWLData > 0 ? "profit-color" : "loss-color"
                    }`}
                  >
                    {value.netWLData.toFixed(2)}
                  </div>
                </div>
                <div>
                  <IonIcon
                    className="log-arrow"
                    icon={
                      indexPosition === index && activeIndex
                        ? chevronUpCircleOutline
                        : chevronDownCircleOutline
                    }
                    onClick={() => handleActiveIndex(index)}
                  />
                </div>
              </div>
              {activeIndex && indexPosition === index && (
                <div>
                  <div className="casino-report-data">
                  {individualData.map((event, index) => {
                    return (
                      <div
                        className="w-100 flex-sp-b fancty-report-result-div gap-4"
                        key={index}
                      >
                        <div className="w-30 profit-loss-report-result flex-column">
                          <div>{`Market Name:${event.marketName}`}</div>
                          <div>{`Bet Id: ${event.betID}`}</div>
                          <div>{`Placed : ${event.placed}`}</div>
                        </div>
                        <div className="w-40 profit-loss-report-result flex-column">
                          <div>{`Selection : ${event.selection}`}</div>
                          <div>{`Result : ${event.result}`}</div>
                        </div>
                        <div className="w-30 profit-loss-report-result flex-column">
                          <div className="status-win custom-win">
                            Status:
                            <span
                              className={`${
                                event.status === "Win"
                                  ? "profit-color"
                                  : "loss-color"
                              }`}
                            >
                              {event.status}
                            </span>
                          </div>
                          <div>{`Type:${event.type}`}</div>
                          <div>{`Odd: ${event.odd}`}</div>
                          <div>{`Comm : ${event.comm}`}</div>
                          <div>{`Stake : ${event.stake}`}</div>
                          <div
                          >Win/Loss :<span  className={`${
                            event.winLoss >= 0
                              ? "profit-color"
                              : "loss-color"
                          }`}> {`${event.winLoss}`}</span></div>
                        </div>
                      </div>
                    );
                  })}
                  </div>
                  <div className="flex-sp-a fancy-report-total-div mt-5">
                    <div className="flex-column">
                      <span>Market Sub Total :</span>
                      <span className="profit-color">260.00</span>
                    </div>
                    <div className="flex-column">
                      <span>Comm :</span>
                      <span className="profit-color">0.00</span>
                    </div>
                    <div className="flex-column">
                      <span>Net Market Total</span>
                      <span className="profit-color">260.00</span>
                    </div>
                  </div>
                </div>
              )}
              
            </div>
          ))}
      </div>
    </div>
  );
}

export default CasinoReports;
