import DateRange from "./DateRange";
import WalletBalanceDetails from "./WalletBalanceDetails";

function MyWallet() {
  const depositeWithdrawDetails = [
    {
      type: "Deposite",
      dateAndTime: "03-06-23, 3:12 PM",
      txnId: "TXN ID 3425272874",
      upiType: "Phone Pay",
      profitLoss: 450,
      balance: 50000000,
    },
    {
      type: "Withdraw",
      dateAndTime: "03-06-23, 2:21 PM",
      txnId: "TXN ID 342527284",
      upiType: "T Casino Park",
      profitLoss: -50,
      balance: 50,
    },
    {
      type: "Deposit",
      dateAndTime: "03-06-23, 2:29 PM",
      txnId: "TXN ID 342527234",
      upiType: "Google Pay",
      profitLoss: -450,
      balance: 100,
    },
    {
      type: "Deposit",
      dateAndTime: "03-06-23, 2:29 PM",
      txnId: "TXN ID 342527234",
      upiType: "Cash",
      profitLoss: 450,
      balance: 500,
    },
    {
      type: "Deposit",
      dateAndTime: "03-06-23, 2:29 PM",
      txnId: "TXN ID 342527234",
      upiType: "RTGS",
      profitLoss: 450,
      balance: 100,
    },
  ];
  return (
    <div className="profit-loss-container">
      <WalletBalanceDetails />
      <DateRange />
      <div>
        <div className="flex-sp-b my-wallet-heading-div mt-10 mb-10">
          <span className="flex-jc-c w-35">Trasaction</span>
          <span className="flex-jc-c w-22">Type</span>
          <span className="flex-jc-c w-28">Credit/Debit</span>
          <span className="flex-jc-c w-22">Balance</span>
        </div>
        <div className="account-statement-container">
          {depositeWithdrawDetails &&
            depositeWithdrawDetails?.map((value, index) => (
              <div className="flex-sp-b my-wallet-content-div mt-5" key={index}>
                <div className="flex-column w-40">
                  <span className="font-13 my-wallet-tl-ml">{value.type}</span>
                  <span className="my-wallet-tl-ml font-w300">
                    {value.dateAndTime}
                  </span>
                  <span className="my-wallet-tl-ml font-w300">
                    {value.txnId}
                  </span>
                </div>
                <div className="w-22 font-13">{value.upiType}</div>
                <div
                  className={`${
                    value.profitLoss >= 0
                      ? "w-28 font-13 profit-color"
                      : "w-28 font-13 loss-color"
                  }`}
                >
                  {value.profitLoss}
                </div>
                <div className="w-22 font-13">{value.balance}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
export default MyWallet;
