import React from 'react'
import './loading.css';

const Loading = () => {
    return (
        <div className="loader">Loading
            <span></span>
        </div>
    )
}

export default Loading