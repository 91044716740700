import React from 'react'
import "./style.css"

function Table({data,columns}) {
  return (
    <div className='tables-main'>
        <table className='table-container'>
      <thead className='match-position-table-header'>
        <tr>
            {columns.map((head)=>(<th className='table-cells'>{head.value}</th>))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.id}>
            <td className='table-cells'>{row.id}</td>
            <td className='table-cells'>{row.team}</td>
            <td className='table-cells'>{row.profit}</td>
            <td className='table-cells'>{row.ammount}</td>
            <td className='table-cells'>{row.fancyAmount}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
    
  )
}

export default Table

