//urls
const LOGIN = "/users-reg/user-login";
const CHECK_USER_NAME_AVAILABILITY = "/users-reg/check-name";
const GET_SECURITY_QUESTION_LIST = "/users-reg/get-sec-q-list";
const CREATE_USER = "/users-reg/regis-user";

const GET_USER_PROFILE_INFO = "/user-app/profile/profile-get-info";
const GET_USER_WALLET_DETAILS = "/user-app/dashboard/get-wallet-details";

const GET_OFFERS_AND_NOTIFICATIONS =
  "/user-app/dashboard/get-offers-notifications";
const GET_NOTIFICATIONS = "/user-app/profile/get-activity-notifications";

const GET_UPCOMING_EVENTS = "/user-app/dashboard/get-upcoming-events";
const GET_USER_TRANSACTIONS = "/user-app/websites/get-all-user-trnx-list";
const GET_USER_WEB_ACCOUNTS_REGISTRED_LIST =
  "/user-app/websites/web-account-get-list";
const GET_USER_WEB_ACCOUNT_INFO = "/user-app/websites/web-account-get-info";
const GET_USER_WEB_ACCOUNT_RECENT_TRANSACTIONS =
  "/user-app/websites/web-account-get-trnx";
const GET_USER_WEB_ACCOUNTS_LIST_TO_REGISTER =
  "/user-app/websites/web-account-get-list-to-reg";
const GET_PAYMENT_SERVICE_PROVIDERS_FOR_DEPOSIT =
  "/user-app/websites/web-account-get-payment-sp";
const GET_PAYMENT_SERVICE_PROVIDERS_FOR_WITHDRAWAL =
  "/user-app/profile/pay-accounts-list";
const WEBSITE_REQUEST_NEW_ID = "/user-app/websites/request-new-website-user-id";
const CREATE_NEW_TRANSACTION = "/user-app/websites/trnx-create-new-ticket";
const TRANSACTION_IMAGE_UPLOAD =
  "/user-app/websites/request-new-website-user-id-presign-url-sc";
const EDIT_USER_PROFILE = "/user-app/profile/profile-update-info";
const UPADATE_NOTIFICATION = "/user-app/profile/profile-update-notification";
const FORGOT_PASSWORD = "/users-reg/users-forgot-password";
const GET_USER_MESSAGES_IN_USER_APP = "/user-app/chat/get-user-messages";
const PRIVACY_POLICY = "/user-app/profile/profile-get-policy";
const USER_IMAGE_UPLOAD =
  "/user-app/websites/request-new-website-user-id-presign-url-sc";
const USER_ACCOUNT_LIST = "/user-app/profile/pay-accounts-list";
const RESET_PASSWORD = "/user-app/profile/reset-password";
const ADD_CARD = "/user-app/profile/add-pay-account";
const ALL_TICKETS = "/user-app/websites/web-accounts-tickets-list";
const ADD_CARD_UPDATE = "/user-app/profile/pay-accounts-update";
const ALL_MEETINGS = "/admin/master/get-all-meetings";
const GENERATE_TOKEN = "/group-call/signal-recording";
const SMS_REQUEST = "/sms/request";
const SMS_VERIFY = "/sms/verify";
const MASTER_PROFILE = "/master/home/get-master-profile";
const VERIFY_AUTHENTICATOR_OTP = "/users-reg/verify-otp-authentication";
const REQUEST_AUTHENTICATOR_OTP = "/users-reg/request-otp-authenticationapp";

//methods
const POST = "POST";
const DELETE = "DELETE";
const GET = "POST";
const GET1 = "GET";

exports.LOGIN = {
  url: LOGIN,
  method: POST,
};

exports.REQUEST_AUTHENTICATOR_OTP = {
  url: REQUEST_AUTHENTICATOR_OTP,
  method: POST,
};


exports.CHECK_USER_NAME_AVAILABILITY = {
  url: CHECK_USER_NAME_AVAILABILITY,
  method: POST,
};

exports.GET_SECURITY_QUESTION_LIST = {
  url: GET_SECURITY_QUESTION_LIST,
  method: GET,
};

exports.CREATE_USER = {
  url: CREATE_USER,
  method: POST,
};

exports.GET_USER_WALLET_DETAILS = {
  url: GET_USER_WALLET_DETAILS,
  method: GET,
};

exports.GET_OFFERS_AND_NOTIFICATIONS = {
  url: GET_OFFERS_AND_NOTIFICATIONS,
  method: GET,
};

exports.GET_NOTIFICATIONS = {
  url: GET_NOTIFICATIONS,
  method: GET,
};

exports.GET_UPCOMING_EVENTS = {
  url: GET_UPCOMING_EVENTS,
  method: GET,
};

exports.GET_USER_TRANSACTIONS = {
  url: GET_USER_TRANSACTIONS,
  method: GET,
};

exports.GET_USER_WEB_ACCOUNTS_REGISTRED_LIST = {
  url: GET_USER_WEB_ACCOUNTS_REGISTRED_LIST,
  method: GET,
};

exports.GET_USER_WEB_ACCOUNT_INFO = {
  url: GET_USER_WEB_ACCOUNT_INFO,
  method: GET,
};

exports.GET_USER_WEB_ACCOUNT_RECENT_TRANSACTIONS = {
  url: GET_USER_WEB_ACCOUNT_RECENT_TRANSACTIONS,
  method: GET,
};

exports.GET_USER_PROFILE_INFO = {
  url: GET_USER_PROFILE_INFO,
  method: GET,
};

exports.GET_USER_WEB_ACCOUNTS_LIST_TO_REGISTER = {
  url: GET_USER_WEB_ACCOUNTS_LIST_TO_REGISTER,
  method: GET,
};

exports.GET_PAYMENT_SERVICE_PROVIDERS_FOR_DEPOSIT = {
  url: GET_PAYMENT_SERVICE_PROVIDERS_FOR_DEPOSIT,
  method: GET,
};

exports.GET_PAYMENT_SERVICE_PROVIDERS_FOR_WITHDRAWAL = {
  url: GET_PAYMENT_SERVICE_PROVIDERS_FOR_WITHDRAWAL,
  method: GET,
};

exports.WEBSITE_REQUEST_NEW_ID = {
  url: WEBSITE_REQUEST_NEW_ID,
  method: POST,
};

exports.CREATE_NEW_TRANSACTION = {
  url: CREATE_NEW_TRANSACTION,
  method: POST,
};

exports.TRANSACTION_IMAGE_UPLOAD = {
  url: TRANSACTION_IMAGE_UPLOAD,
  method: GET,
};
exports.USER_IMAGE_UPLOAD = {
  url: USER_IMAGE_UPLOAD,
  method: GET,
};

exports.EDIT_USER_PROFILE = {
  url: EDIT_USER_PROFILE,
  method: POST,
};

exports.UPADATE_NOTIFICATION = {
  url: UPADATE_NOTIFICATION,
  method: POST,
};

exports.FORGOT_PASSWORD = {
  url: FORGOT_PASSWORD,
  method: POST,
};

exports.RESET_PASSWORD = {
  url: RESET_PASSWORD,
  method: POST,
};

exports.GET_USER_MESSAGES_IN_USER_APP = {
  url: GET_USER_MESSAGES_IN_USER_APP,
  method: GET,
};

exports.PRIVACY_POLICY = {
  url: PRIVACY_POLICY,
  method: GET,
};

exports.USER_ACCOUNT_LIST = {
  url: USER_ACCOUNT_LIST,
  method: GET,
};

exports.ADD_CARD = {
  url: ADD_CARD,
  method: POST,
};

exports.ALL_TICKETS = {
  url: ALL_TICKETS,
  method: GET,
};

exports.ALL_MEETINGS = {
  url: ALL_MEETINGS,
  method: GET,
};

exports.ADD_CARD_UPDATE = {
  url: ADD_CARD_UPDATE,
  method: POST,
};

exports.GENERATE_TOKEN = {
  url: GENERATE_TOKEN,
  method: POST,
};

exports.SMS_REQUEST = {
  url: SMS_REQUEST,
  method: GET,
};

exports.SMS_VERIFY = {
  url: SMS_VERIFY,
  method: GET,
};

exports.MASTER_PROFILE = {
  url: MASTER_PROFILE,
  method: GET,
};

exports.VERIFY_AUTHENTICATOR_OTP = {
  url: VERIFY_AUTHENTICATOR_OTP,
  method: POST
}