import { IonBadge, IonIcon, IonModal } from "@ionic/react";
import {
  chevronBackOutline,
  notificationsOutline,
  videocamOutline,
} from "ionicons/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { setALlMeetings, setLoading } from "../../redux/actions";
import { call } from "../api/axios";
import { ALL_MEETINGS, GET_NOTIFICATIONS } from "../api/endpoints";
import { timeDiff } from "../common/CommonServices";
import "./styles.css";
import CreateTransaction from "../../pages/transactions/create-transaction";

function Header({ page, title, videoIcon, Notified, cssClass = "background" }) {

  let userId = localStorage?.getItem("user_id");
  const dispatch = useDispatch();
  let history = useHistory();

  const [notificationCount, setNotificationCount] = useState(0);
  const handleBack = () => {
    history.goBack();
  };

  const [initialized, setInitialized] = useState(false);
  const [meetingAvailable, setMeetingAvailable] = useState(false);
  const [upcomingMeetingAvailable, setUpcomingMeetingAvailable] =
    useState(false);

  const getOffersAndNotifications = async () => {
    await call(GET_NOTIFICATIONS, { user_id: userId })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          let results = res?.data?.data?.results;
          results?.forEach((notification) => {
            if (
              moment(
                new Date(`${notification?.create_at} 'UTC`).toString()
              ).format("YYYY-MM-DD  hh:mm:ss") >
              moment(new Date(localStorage.getItem("timestamp"))).format(
                "YYYY-MM-DD  hh:mm:ss"
              )
            ) {
              setNotificationCount((prev) => prev + 1);
            }
          });
          // dispatch(setNotiications(results));
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const pathName = window?.location?.pathname;

  const getAllMeetings = async () => {
    await call(ALL_MEETINGS, { userId: userId })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          let meetingArray = res?.data?.data;
          dispatch(setALlMeetings(meetingArray));
          let upComingMeetingAvailable = meetingArray?.some(
            (meeting) =>
              meeting?.fileUrl === undefined &&
              !meeting?.fileUrl &&
              timeDiff(meeting?.eventTime, new Date().getTime(), "days") >= 0
          );
          setUpcomingMeetingAvailable(upComingMeetingAvailable);
          let temp = meetingArray?.some(
            (meeting) =>
              timeDiff(meeting?.eventTime, new Date().getTime(), "minutes") >=
                0 &&
              timeDiff(meeting?.eventTime, new Date().getTime(), "minutes") <=
                15
          );
          setMeetingAvailable(temp);
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const setIntervalCalls = async () => {
    setInitialized(true);
    await getAllMeetings();
  };

  useEffect(() => {
    !Notified && getOffersAndNotifications();
    if (videoIcon) {
      getAllMeetings();
    }
    let interval1 = null;
    if (!initialized && !interval1) {
      interval1 = setInterval(() => {
        videoIcon && setIntervalCalls();
      }, 30000);
    }
    return () => {
      clearInterval(interval1);
    };
  }, []);

  return (
    <div className={`${cssClass} header-container`}>
      <div className="header-box">
        <div className="header-content">
          <div>
            <IonIcon
              icon={chevronBackOutline}
              className="back-arrow"
              onClick={() => handleBack()}
            />
          </div>
          <div className="header-title ml-20">{title}</div>
        </div>
        <div className="flex">
          {videoIcon && localStorage.getItem("callFeatureEnabled") && (
            <Link to="/meetings" className="join-meet-btn">
              <IonIcon
                icon={videocamOutline}
                className={`notification-icon ${
                  upcomingMeetingAvailable && meetingAvailable ? "ripple" : ""
                }`}
              ></IonIcon>
            </Link>
          )}
          {page !== "notification" && pathName !== "/reset-password" && (
            <Link to="/notifications" className="header-notification">
              <IonIcon
                icon={notificationsOutline}
                className="notification-icon"
              />
              {notificationCount > 0 && (
                <IonBadge id="cart-badge">{notificationCount}</IonBadge>
              )}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
