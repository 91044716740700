import React from "react";
import {
  IonContent,
  IonIcon,
  IonPage,
  IonButton,
  IonCard,
  IonImg,
} from "@ionic/react";
import { logoWhatsapp, shareSocialOutline } from "ionicons/icons";
import "swiper/modules/zoom/zoom.min.css";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { Autoplay, Keyboard, Pagination, Zoom } from "swiper";
import "swiper/modules/autoplay/autoplay.min.css";
import "swiper/modules/keyboard/keyboard.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";
import "swiper/modules/zoom/zoom.min.css";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.min.css";
const referralCodes = [
  { name: "Referral code", codeNo:  process.env.PUBLIC_URL + "/assets/Copy-Clipboard-Icon.png", imageStyle: true },
  { name: "Master Code", codeNo: 9999 },
  { name: "Bonus Referral Code", codeNo: "Ja741" },
  { name: "Company Name", codeNo: "Texch" },
];
const offersData = [
  { id: 1, image: process.env.PUBLIC_URL + "/assets/Refer-Illustra.png" },
  { id: 2, image: process.env.PUBLIC_URL + "/assets/Refer-Illustra.png" },
  { id: 3, image: process.env.PUBLIC_URL + "/assets/Download-App.png" },
];
const referralData = [
  {
    id: 1,
    image: process.env.PUBLIC_URL + "/assets/Invite.png",
    description: "Invite your friends to Sign up",
  },
  {
    id: 2,
    dashedLine:true
  },
  {
    id: 3,
    image: process.env.PUBLIC_URL + "/assets/Download-App.png",
    description: "Your friends Download app",
  },
  {
    id: 4,
    dashedLine:true
  },
  {
    id: 5,
    image: process.env.PUBLIC_URL + "/assets/Gift-Box.png",
    description: "You and your friends get rewarded",
  },
];

function ReferYourFriend() {
  return (
    <IonPage className="background">
      <IonContent className="background-none">
        <div className="account-header-container">
          <Header title={"Refer and Earn"} page="offers" />
          <hr className="invite-your-friend-border" />
          <div className="refer-your-friend-container">
            {/* <img
              src={process.env.PUBLIC_URL + "/assets/Refer-Illustra.png"}
              alt="refer-your-friend"
              className="refer-image mb-10"
            /> */}
            <div className="referral-and-earn-carousal">
              <Swiper
                modules={[Autoplay, Keyboard, Pagination, Zoom]}
                autoplay={{ delay: 2000 }}
                keyboard={true}
                loop={true}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                spaceBetween={10}
                slidesPerView={"1"}
                centeredSlides={true}
                zoom={true}
              >
                {offersData?.length > 0 &&
                  offersData?.map((event, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <IonCard
                          className="carousel__snapper"
                          key={event?.id}
                        >
                          <IonImg src={event?.image} alt={event?.image} loa />
                        </IonCard>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>

            <hr className="invite-your-friend-border" />
            <div className="website-name font-size-20 mb-10 mt-10">
              Invite your Friends to Texch
            </div>
            <div className="referral-code-card">
              <div className="referral-code-data">
                {referralCodes.map((item, ind) => {
                  if(item.imageStyle) return <div key={ind} className="d-flex justify-space-bw mb-10">
                        <span className="referral-name">{item.name}</span>
                 <img src={item.codeNo} alt="referral code" className="referral-code-image" />
                </div>
                  return (
                    <div key={ind} className="d-flex justify-space-bw mb-10">
                      <span className="referral-name">{item.name}</span>
                      <span className="referral-code-num">{item.codeNo}</span>
                    </div>
                  );
                })}
                <div className="referral-description">
                  Refer a friend and earn up to ₹25 when your friend first order
                  is successfully delivered.Minimum order amount should be
                  ₹1000. Which allows you to earn upto ₹1000.
                </div>
              </div>

              <div className="buttons-container">
                <IonButton className="refer-btn wtsapp-btn mr-20">
                  {" "}
                  <IonIcon icon={logoWhatsapp} className="mr-5" />
                  <span className="fw-bold">Whatsapp</span>
                </IonButton>
                <IonButton className="refer-btn more-option-btn">
                  <IonIcon icon={shareSocialOutline} className="mr-5" />
                  <span className="fw-bold">More Option</span>
                </IonButton>
              </div>
              <div className="how-to-refer mb-10">How to Refer?</div>
              <div className="d-flex text-center justify-space-bw">
                {referralData.map((item) => {
                  if(item.dashedLine) return <div className="referral-dashed-lines"></div>
                  return (
                    <div key={item.id}>
                      <div className="refer-different-ways-card">
                        {" "}
                        <img
                          src={item.image}
                          alt={item.description}
                          style={{ width: "20px" }}
                        />
                      </div>

                      <span className="refer-desc">{item.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
}
export default ReferYourFriend;
