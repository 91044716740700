import React, { useState } from 'react'
import "./style.css"
import coins from "./assents/coins.png"
import moneyImage from "./assents/money.png"
import MatchPositionTables from './MatchPositionTables'
import downArrow from "./assents/downArrow.png"
import upArrow from "./assents/upArrow.png"
import { IonApp } from '@ionic/react'



function MatchPosition(props) {
    const [showDownIcon,setShowDownIcon]=useState(true)
    const [showUpIcon,setShowUpIcon]=useState(false)
    const handleClickDown=()=>{
         setShowDownIcon(false)
         setShowUpIcon(true)
      }
    const handleClickUP=()=>{
        setShowDownIcon(true)
        setShowUpIcon(false)
     }
   
  return (
    <div className='vc-match-position-container'>
        <center>
        <div className='match-position-header-container'>
           <img className='header-images' src={coins}/>
           <p>Match Position<br/><span>Check your position</span></p>
           {showDownIcon&&<img className='header-icons' src={downArrow} onClick={handleClickDown} name="chevron-down-outline"/>}
           {showUpIcon&&<img src={upArrow} onClick={handleClickUP} className='header-icons'/>}
           <img className='header-images' src={moneyImage}/>
        </div>
        {showUpIcon&&<MatchPositionTables/>}
        </center>
    </div>
  )
}

export default MatchPosition