import React from "react";
import { IonPage } from "@ionic/react";
import Header from "../../components/header";
import Footer from "../../components/footer";

const Help = () => {
  return (
    <div>
      <IonPage className="background">
        <div className="account-header-container">
          <Header title={`Help`} />
        </div>
        <div className="support-home term-and-services-container">
          <div className="notification-heading mb-20">{`Help`}</div>
          <div className="notification-heading" style={{ fontSize: "14px" }}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
              ullamcorper sagittis lectus sit amet bibendum. Ut finibus ultrices
              est, a vulputate justo condimentum a. Vivamus vulputate sem ut
              nunc ornare gravida. Donec dignissim orci nulla. Proin in
              fermentum risus. Pellentesque habitant morbi tristique senectus et
              netus et malesuada fames ac turpis egestas. Mauris et metus eget
              quam pretium mollis. Phasellus non bibendum sapien. In semper ut
              ex sit amet maximus. Mauris vitae quam ultricies, laoreet lectus
              quis, consequat tortor.
            </p>
            <p>
              Nam eget tortor placerat, sagittis tellus id, gravida ex. Sed
              malesuada vel mauris et molestie. Donec tristique massa ligula. In
              rutrum dui in sem faucibus, sed consectetur massa egestas. Nulla
              facilisi. Fusce non nunc bibendum, molestie mauris a, rhoncus
              sapien. Cras rhoncus, diam quis sodales feugiat, diam felis
              fermentum magna, in elementum lorem odio in sapien. Phasellus at
              laoreet ipsum.
            </p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            ullamcorper sagittis lectus sit amet bibendum. Ut finibus ultrices
            est, a vulputate justo condimentum a. Vivamus vulputate sem ut nunc
            ornare gravida. Donec dignissim orci nulla. Proin in fermentum
            risus. Pellentesque habitant morbi tristique senectus et netus et
            malesuada fames ac turpis egestas. Mauris et metus eget quam pretium
            mollis. Phasellus non bibendum sapien. In semper ut ex sit amet
            maximus. Mauris vitae quam ultricies, laoreet lectus quis, consequat
            tortor. Nam eget tortor placerat, sagittis tellus id, gravida ex.
            Sed malesuada vel mauris et molestie. Donec tristique massa ligula.
            In rutrum dui in sem faucibus, sed consectetur massa egestas. Nulla
            facilisi. Fusce non nunc bibendum, molestie mauris a, rhoncus
            sapien. Cras rhoncus, diam quis sodales feugiat, diam felis
            fermentum magna, in elementum lorem odio in sapien. Phasellus at
            laoreet ipsum.
          </div>
        </div>
        <Footer />
      </IonPage>
    </div>
  );
};

export default Help;
