import React from "react";
import { IonCard, IonImg, IonHeader, IonButton } from "@ionic/react";
import { Autoplay, Keyboard, Pagination, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

function MatchesContainer() {
  const matchesList = [
    {
      title: "Sports",
      matchesSubList: [
        {
          sportImage: "/assets/cricketBanner.png",
          sportName: "Cricket",
        },
        {
          sportImage: "/assets/footballBanner.png",
          sportName: "Football",
        },
        {
          sportImage: "/assets/tennisBanner.png",
          sportName: "Tennis",
        },
        {
          sportImage: "/assets/horseRacingBanner.png",
          sportName: "Horse Racing",
        },
        {
          sportImage: "/assets/greyhoundBanner.png",
          sportName: "Grey Hound",
        },
        {
          sportImage: "/assets/virtualSports.png",
          sportName: "Virtual Sports",
        },
      ],
    },
    {
      title: "Live Casino",
      matchesSubList: [
        {
          sportImage: "/assets/andarBaharBanner01.png",
          sportName: "Andar Bahar",
        },
        {
          sportImage: "/assets/teenPattiBanner01.png",
          sportName: "Teen Patti",
        },
        {
          sportImage: "/assets/blackJackDBanner01.png",
          sportName: "Black Jack D",
        },
        {
          sportImage: "/assets/noCommBaccaBanner01.png",
          sportName: "No Comm Bacca",
        },
        {
          sportImage: "/assets/ultimateRouletteBanner01.png",
          sportName: "Ulitmate Roulette",
        },
        {
          sportImage: "/assets/lucky7Banner01.png",
          sportName: "Lucky 7",
        },
      ],
    },
    {
      title: "Royal Gaming",
      matchesSubList: [
        {
          sportImage: "/assets/7UpAndDownBanner.png",
          sportName: "7UP & Down",
        },
        {
          sportImage: "/assets/roulletBanner.png",
          sportName: "Roulette",
        },
        {
          sportImage: "/assets/teenPattiBanner02.png",
          sportName: "Teen Patti",
        },
        {
          sportImage: "/assets/dragonAndTigerBanner.png",
          sportName: "Dragon/Tiger",
        },
        {
          sportImage: "/assets/cricketWarBanner.png",
          sportName: "Cricket War",
        },
        {
          sportImage: "/assets/bollywoodCasino.png",
          sportName: "Bollywood Casino",
        },
      ],
    },
    {
      title: "Virtual Sports",
      matchesSubList: [
        {
          sportImage: "/assets/virtualCricketBanner.png",
          sportName: "Virtual Cricket",
        },
        {
          sportImage: "/assets/virtualTennisBanner.png",
          sportName: "Virtual Tennis",
        },
        {
          sportImage: "/assets/virtualBaseBallBanner.png",
          sportName: "Virtaul Baseball",
        },
        {
          sportImage: "/assets/virtualHorsesBanner.png",
          sportName: "Virtaul Horses",
        },
        {
          sportImage: "/assets/virtualFootballBanner.png",
          sportName: "Virtual Football",
        },
        {
          sportImage: "/assets/virtualDogsBanner.png",
          sportName: "Virtual Dogs",
        },
      ],
    },
  ];

  return (
    <Swiper
      modules={[Autoplay, Keyboard, Pagination, Zoom]}
      autoplay={{ delay: 4000 }}
      keyboard={true}
      loop={true}
      pagination={{ clickable: true }}
      spaceBetween={10}
      slidesPerView={"1"}
      centeredSlides={true}
      zoom={true}
      className="sports-swiper"
    >
      {matchesList.map((category, index) => {
        return (
          <SwiperSlide key={index}>
            <div className="custom-border">
              <div className="upcoming-matches-container  mt-10">
                <IonHeader className="upcoming-matches-header ">
                 
                  <div className="matches-text ">{category.title}</div>
                  <IonButton className="header-deposit-btn " color="warning">
                    View All
                  </IonButton>
                 
                </IonHeader>
                <div className="sports-book-providers-yellow-line"></div>
                <div className="offer-swiper-container matches-swiper-container ">
                  {category.matchesSubList.map((match, matchIndex) => {
                    return (
                      <div key={matchIndex} className="matches-subcard">
                        <div className="custom-ion-border">
                          <IonCard className="cateogry-card ">
                            <IonImg
                              src={match.sportImage}
                              alt={match.sportName}
                            />

                            <div className="individual-sport-name ">
                              {match.sportName}
                            </div>
                          </IonCard>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
export default MatchesContainer;
