import { IonIcon, IonPage, IonTextarea } from "@ionic/react";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { call } from "../../../components/api/axios";
import { GET_USER_MESSAGES_IN_USER_APP } from "../../../components/api/endpoints";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import { setLoading, setSupportData } from "../../../redux/actions";
import { open, send } from "../../../utils/WebSocket";
import "./styles.css";
import { sendSharp } from "ionicons/icons";
// import WebSocket, { WebSocketServer } from "ws";

// const wss = new WebSocket('wscat -c wss://26mx6l4j8j.execute-api.us-west-2.amazonaws.com/production?userid=' + localStorage?.getItem("user_id"))

// `https://26mx6l4j8j.execute-api.us-west-2.amazonaws.com/production?userid=${localStorage?.getItem("user_id")}`
const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

class Support extends React.Component {
  state = {
    chat: [],
    userInput: "",
  };

  async componentDidMount() {
    this.getAllUserMessages();
    await open({ onmessage: this.onMessageRecieve });
  }

  inputHandler = (e) => {
    this.addMessage(this.state.userInput, 1);
    this.setState({ userInput: "" });
    send(this.state.userInput);
  };

  addMessage = (message, msg_c = 0) => {
    // console.log("new message : ", message);
    const { chat } = this.state;
    let date = "";
    let messageDate = new Date();

    if (messageDate.getFullYear() == new Date().getFullYear()) {
      date =
        weekday[messageDate.getDay()] +
        "," +
        messageDate.getDate() +
        " " +
        months[messageDate.getMonth()];
    }
    if (chat[date]) {
      chat[date].push({ message, ts: messageDate.getTime(), msg_c });
    } else {
      chat[date] = [{ message, ts: messageDate.getTime(), msg_c }];
    }
    //chat.push({ message, ts: messageDate.getTime(), msg_c });
    this.setState({ chat }, () => {
      this.scroll();
    });
  };

  handleInputChange = (e) => {
    this.setState({ userInput: e.target.value });
  };

  getAllUserMessages = async () => {
    this.props.supportData?.length === 0 && this.props.setLoading(true);
    await call(
      {
        ...GET_USER_MESSAGES_IN_USER_APP,
        url: GET_USER_MESSAGES_IN_USER_APP.url,
      },
      {
        user_id: `${localStorage?.getItem("user_id")}`,
        master_id: localStorage?.getItem("master_id"),
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          this.props.setLoading(false);
          let results = res?.data?.data?.results;
          let chatByDate = {};
          if (results && results.length > 0) {
            results.forEach((item) => {
              let messageDate = new Date(item.ts);
              let date = "";
              if (messageDate.getFullYear() == new Date().getFullYear()) {
                date =
                  weekday[messageDate.getDay()] +
                  "," +
                  messageDate.getDate() +
                  " " +
                  months[messageDate.getMonth()];
              } else {
                date =
                  messageDate.getDate() +
                  " " +
                  months[messageDate.getMonth()] +
                  " " +
                  messageDate.getFullYear();
              }
              if (chatByDate[date]) {
                chatByDate[date].push(item);
              } else {
                chatByDate[date] = [item];
              }
            });
          }

          this.setState({ chat: chatByDate });
          this.props.setSupportData(res?.data?.data?.results);
          // console.log("allMessage : ", this.state.chat);
          this.scroll();
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        this.props.setLoading(false);
        console.log(err);
      })
      .finally(() => {
        this.props.setLoading(false);
      });
  };

  onMessageRecieve = (event) => {
    // console.log("onMessageRecieve : ", event);
    if (!event.data) {
      return;
    }
    const msg = JSON.parse(event.data);
    if (msg.from_user_id === localStorage?.getItem("user_id")) {
      return;
    }
    this.addMessage(msg.message);
  };

  scroll = () => {
    var objDiv = document.getElementById("chat");
    if (objDiv) {
      objDiv.scrollTop = objDiv?.scrollHeight;
    }
  };

  render() {
    const { chat, userInput } = this.state;
    return (
      <IonPage className="background">
        <Header title="Support" videoIcon />
        <div className="support-home" id="chat">
          {chat &&
            Object.keys(chat)?.length > 0 &&
            Object.keys(chat)?.map((chatMessage, index1) => {
              return (
                <>
                  <div key={index1} className="message-time-stampDetails">
                    {chatMessage}
                  </div>
                  {chat[chatMessage].map((message, index) => {
                    return (
                      <div className="message-div " key={index1 + " " + index}>
                        <div
                          className={`${
                            !message?.msg_c
                              ? "admin-message"
                              : "customer-message"
                          }`}
                        >
                          <div
                            className="message"
                            style={{
                              borderRadius: !message?.msg_c
                                ? "10px 10px 10px 0px"
                                : "10px 10px 0px 10px",
                              backgroundColor: !message?.msg_c
                                ? "#111935"
                                : "#03aa50",
                            }}
                          >
                            {message?.message}
                          </div>
                          <div
                            className={`${
                              !message?.msg_c ? "time-div-ad" : "time-div-cu"
                            } `}
                          >
                            {moment(message?.ts).format("hh:mm a")}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              );
            })}
        </div>
        <div className="message-outer-div">
          <div className="chat-footer">
            <div className="message-send-div">
              <IonTextarea
                type="text"
                placeholder="Type your message here…"
                value={userInput}
                onIonChange={this.handleInputChange}
                className="massage-input"
                wrap="hard"
              />
            </div>
            <div
              className="send-icon-position"
              onClick={userInput === "" ? null : this.inputHandler}
            >
              {/* <img src="./assets/send_icon.svg" className="send-icon" /> */}
              <IonIcon className="send-icon-new" icon={sendSharp} />
            </div>
          </div>
        </div>
        <Footer />
      </IonPage>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    supportData: state?.data?.supportData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSupportData: (value) => dispatch(setSupportData(value)),
    setLoading: (value) => dispatch(setLoading(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Support);
