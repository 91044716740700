import { IonApp, IonFab, IonFabButton, IonIcon, setupIonicReact } from "@ionic/react";
import { IonReactHashRouter } from "@ionic/react-router";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { remove, videocamOutline } from "ionicons/icons";
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import "./components/common/common.css";
import { setMeetingStartStatus, setMinimize } from "./redux/actions/dataActions";
import Routes from "./routes";
import "./styles.css";
import "./theme/variables.css";
import callingUserIcon from "./pages/video-call-matchposition/assents/calling_user_minimizer1.jpg";

setupIonicReact();

const App = (props: any) => {
  const dispatch = useDispatch()
  const isMeetingStarted = useSelector((state: any) => state?.data?.isMeetingStarted
  );
  const isMinimize = useSelector((state: any) => state?.data?.isMinimize
  );
  const [minimize, setMinimize] = useState(false);
  useEffect(()=> {
    if(!window.location.hash.includes("/group-call") ){
   if(isMeetingStarted && isMinimize) {
      setMinimize(true);
    }
  }
  },[window.location.hash])
  console.log(minimize,"minimize");
  
  const reloadApplication = () => {
    props.setMeetingStartStatus(false);
    if (window.location.hash.includes("group-call")) {
      window.history.back();
    } else {
      //window.location.reload();
    }
  }
  useEffect(()=>{
    dispatch(setMeetingStartStatus(false))
  },[])
  const isVideoMeeting = localStorage?.getItem("meetingVideo") === "Y"
  const videoEnabled = document.getElementsByTagName("video")?.length>0
  const css = (isVideoMeeting && minimize) ? { 
  height: "20vh",
  width: "20vw",
  position: "absolute",
  right: "1%",
  bottom: "20%"}:{height: "70vh"}
  const displayP = minimize && !isVideoMeeting ? { display:'none'} :{}
  return (
    <IonApp>
      {isMeetingStarted &&
        <Fragment
        >
           
          {isMinimize && !window.location.hash.includes("/group-call") && <Fragment>
            {!minimize ?

              <IonFab
                vertical="top"
                onClick={() => isMinimize ? setMinimize(!minimize) : reloadApplication()}
                className="minimize-width"
              >
                <IonFabButton
                  size="small"
                  color="warning"
                >
                  <IonIcon
                    icon={remove}></IonIcon>
                </IonFabButton>
              </IonFab>
              :
              <IonFab
                vertical="top"
                className="minimize-width"
                onClick={() => setMinimize(!minimize)}
                style={{ display: videoEnabled ? 'none': ''}}
              >
                <img className="minimize-img-icon" src={callingUserIcon}  width="80" height="120"/>
              </IonFab>

            }
          </Fragment>}

          <div id={`group-call`}
            // style={{ display: minimize ? !isVideoMeeting ? 'none':"" : '',
            //   height:minimize ? '10vh':'70vh',
              
            // }}
            className={(isVideoMeeting && minimize) ? "disable-click-btn":""}
              style={{...(css as React.CSSProperties),...(displayP as React.CSSProperties)}}
                onClick={() => (isVideoMeeting && minimize) ? setMinimize(!minimize):null}
          ></div>
        </Fragment>
      }
      <IonReactHashRouter>
      {/* <VideoCallButtons /> */}

        <Routes />

      </IonReactHashRouter>
     
    </IonApp>
  )
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setMeetingStartStatus: (data: any) => dispatch(setMeetingStartStatus(data)),
    setMinimize:(data:any) => dispatch(setMinimize(data))
  };
};
const mapStateToProps = (state: any) => {
  return {
    isMeetingEnable: state?.data?.isMeetingStarted,
    isMinimize: state?.data?.isMinimize,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
