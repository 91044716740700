import { IonContent, IonImg, IonPage } from "@ionic/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { call } from "../../components/api/axios";
import { GET_NOTIFICATIONS } from "../../components/api/endpoints";
import { persistData } from "../../components/common/commonData";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { setLoading } from "../../redux/actions";
import "./styles.css";

function Notifications() {
  let result = persistData();
  const { notificationData, notificationDataFlag } = result;
  const dispatch = useDispatch();
  const [allNotficationData, setNotificationData] = useState(notificationData);
  const [notificationCount, setNotificationCount] = useState(0);

  const getOffersAndNotifications = async () => {
    notificationDataFlag && dispatch(setLoading(true));
    await call(GET_NOTIFICATIONS, { user_id: localStorage?.getItem("user_id") })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          let results = res?.data?.data?.results;
          results?.forEach((notification) => {
            if (
              moment(
                new Date(`${notification?.create_at} 'UTC`).toString()
              ).format("YYYY-MM-DD  hh:mm:ss") >
              moment(new Date(localStorage.getItem("timestamp"))).format(
                "YYYY-MM-DD  hh:mm:ss"
              )
            ) {
              setNotificationCount((prev) => prev + 1);
            }
          });
          setNotificationData(results);
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  useEffect(() => {
    localStorage.setItem(
      "timestamp",
      moment(new Date()).format("YYYY-MM-DD  hh:mm:ss")
    );
    getOffersAndNotifications();
  }, []);

  return (
    <IonPage className="background">
      <IonContent className="background-none">
        <div className="account-header-container">
          <Header
            title={"Notifications"}
            page="notification"
            notificationCount={notificationCount}
            Notified
          />
          <hr className="notification-border" />
        </div>
        <div>
          {allNotficationData?.length > 0 &&
            allNotficationData.map((notification) => {
              return (
                <div
                  className="notifications-view-all"
                  key={notification?.notification_id}
                >
                  <div>
                    <IonImg
                      className="offer-img"
                      src={process.env.PUBLIC_URL + "/images/offer_logo.svg"}
                    />
                  </div>
                  <div className="ml-20">
                    <div className="website-name mb-5">
                      {notification?.notif_head || ""}
                    </div>
                    <div className="website-username">
                      {notification?.notif_text || ""}
                    </div>
                    <div className="website-username">
                      <span className="green-clr">Created On</span>:{" "}
                      {new Date(`${notification?.create_at} 'UTC`).toString() ||
                        ""}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {allNotficationData?.length === 0 && (
          <div className="transaction-no-data-found">No Data Found</div>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
}

export default Notifications;
