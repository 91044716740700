import React, { useEffect, useState } from "react";
import { IonIcon, IonInput, IonLabel, IonItem, IonButton } from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { call } from "../../../components/api/axios";
import { CREATE_NEW_TRANSACTION, GET_USER_WALLET_DETAILS } from "../../../components/api/endpoints";
import ThankYouModel from "../../../components/common/thankyou-model";
import Header from "../../../components/header";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setUserWallets } from "../../../redux/actions";

function CreateTransactinoFromUser(props) {
  const dispatch = useDispatch();
  let websiteUserId = props?.location?.state?.website_user_id;
  let website_id = props?.location?.state?.website_id; 
  let websiteName = props?.location?.state?.websiteName;

  const history = useHistory();
  let userWalletsData = useSelector((state) => state?.data?.userWallets);

  const [successAlert, setSuccessAlert] = useState(false);

  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [transactionType, setTransactionType] = useState([]);
  const [transactionTypeErr, setTransactionTypeErr] = useState(false);

  const [transactionAmount, setTransactionAmount] = useState("");
  const [transactionAmountErr, setAmountTransactionErr] = useState(false);

  const [transactionTypeDropDown, setTransactionDropDown] = useState(false);
  const [userWalletData, setUserWalletData] = useState(userWalletsData);

  const transactionTypeData = [
    {
      type: "Deposit",
      id: 3,
    },
    {
      type: "Withdrawal",
      id: 4,
    },
  ];

  const handleTransactionType = (transaction) => {
    setTransactionType(transaction);
    setAmountTransactionErr(false);
    setTransactionTypeErr(false);
    setTransactionDropDown(false);
  };
  const handleAmountChange = (e) => {
    setAmountTransactionErr(false);
    setTransactionAmount(parseInt(e.detail.value));
  };

  const handleTransactionDropDown = () => {
    setTransactionDropDown(!transactionTypeDropDown);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSubmit = () => {
    if (transactionType?.length === 0) {
      setErrMsg("Please select transaction type");
      setTransactionTypeErr(true);
      return;
    }

    if (transactionAmount?.length === 0) {
      setErrMsg("Please enter transaction amount");
      setAmountTransactionErr(true);
      return;
    }
    if (transactionAmount === 0) {
      setErrMsg("Please enter valid amount");
      setAmountTransactionErr(true);
      return;
    }
    if (transactionAmount > userWalletData?.wallet_balance || userWalletData?.wallet_balance < 1000) {
      setErrMsg(`You don't enough balance in your wallet`);
      setAmountTransactionErr(true);
      return;
    }
    if (transactionType?.id === 3 && userWalletData?.wallet_balance - transactionAmount < 1000) {
      setErrMsg(`Remaing wallet balance should be greaterthan 1000`);
      setAmountTransactionErr(true);
      return;
    }
    createNewTransaction();
  };
  const getUserWalletDetails = async () => {
    userWalletData?.length === 0 && dispatch(setLoading(true));
    await call(
      { ...GET_USER_WALLET_DETAILS, url: GET_USER_WALLET_DETAILS.url },
      { user_id: `${localStorage?.getItem("user_id")}` }
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          dispatch(setUserWallets(res?.data?.data?.result));
          setUserWalletData(res?.data?.data?.result);
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err?.message);
      });
  };

  const createNewTransaction = async () => {
    let transactionData = {
      website_user_id: websiteUserId,
      user_id: `${localStorage?.getItem("user_id")}`,
      trnx_amount: transactionAmount,
      pay_trnx_id: "N/A",
      payment_method: "",
      pg_id: "",
      trnxType: transactionType?.id,
      website_id: website_id,
      web_name: websiteName,
    };
    dispatch(setLoading(true));
    await call(
      {
        ...CREATE_NEW_TRANSACTION,
        url: CREATE_NEW_TRANSACTION.url,
      },
      transactionData
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          if (res?.data?.error === "true") {
            setErrMsg(res?.data?.message);
            setError(true);
          } else {
            setSuccessAlert(true);
          }
        } else {
          setErrMsg(res?.data?.message);
          setError(true);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        setErrMsg(err?.message);
        dispatch(setLoading(false));
        setError(true);
        console.log(err);
      });
  };

  const handleCancelModel = () => {
    setSuccessAlert(false);
    setTimeout(() => {
      history.goBack();
      // history.push("/transactions");
    }, 500);
  };
  const handleBackToHome = () => {
    setSuccessAlert(false);
    setTimeout(() => {
      history.push("/");
    }, 500);
  };

  useEffect(() => {
    getUserWalletDetails();
  }, []);

  return (
    <div className="create-transaction-container login-page-container">
      <div className="accoutns-notification">
        <Header title="Create Transaction" />
      </div>
      <hr className="notification-border" />
      <div className="create-transaction-box form-container">
        <div
          className="input-dropdown login-input-field transaction-input"
          style={{
            border: transactionTypeDropDown
              ? "3px solid #a4930a"
              : "2px solid #a4930a",
          }}
          onClick={() => handleTransactionDropDown()}
        >
          <div>
            {transactionType?.type !== "" ? (
              <div>
                <div className="referred-selected-value">Transaction Type</div>
                <div>{transactionType?.type}</div>
              </div>
            ) : (
              "Transaction Type"
            )}
          </div>
          <div>
            <IonIcon icon={chevronDownOutline} />
          </div>
        </div>
        {transactionTypeDropDown ? (
          <div className="login-input-field reffered-by-values add-account-content transaction-input-field w-100">
            {transactionTypeData?.map((transaction, index) => (
              <div key={index}>
                <div
                  className="reffered-by-value"
                  name="refferdBy"
                  onClick={() => handleTransactionType(transaction)}
                >
                  {transaction?.type}
                </div>
                <hr className="referred-horizontal-line" />
              </div>
            ))}
          </div>
        ) : null}
        <div
          hidden={!transactionTypeErr}
          className="error-message error-message-margin"
          style={{ marginTop: "5px" }}
        >
          {errMsg}
        </div>
        <IonItem
          className={`${
            transactionAmountErr ? "mb-5" : "mb-20"
          } mt-20 transaction-input`}
        >
          <IonLabel position="floating">Enter Amount</IonLabel>
          <IonInput
            placeholder="Enter Amount"
            name="trnx_amount"
            type="number"
            min="1"
            onIonChange={(e) => handleAmountChange(e)}
            defaultValue={transactionAmount}
            clearInput
          ></IonInput>
        </IonItem>
        <div
          hidden={!transactionAmountErr}
          className="error-message error-message-margin"
          style={{ marginBottom: "20px" }}
        >
          {errMsg}
        </div>
        <div
          className="login-btn mt-40 mb-30"
          style={{
            width: "100%",
          }}
        >
          <IonButton onClick={() => handleSubmit()}>SUBMIT</IonButton>
        </div>
        <div hidden={!error} className="error-message error-message-margin">
          {errMsg}
        </div>
        <div className="signup-now text-center mb-20 mt-40">
          <span onClick={() => handleCancel()}>CANCEL</span>
        </div>
        {
          <ThankYouModel
            successAlert={successAlert}
            handleCancel={handleCancelModel}
            handleBackToHome={handleBackToHome}
          />
        }
      </div>
    </div>
  );
}

export default CreateTransactinoFromUser;
