import {
  IonButton,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import { arrowBack, chevronDownOutline, closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { call } from "../../../components/api/axios";
import {
  CREATE_NEW_TRANSACTION,
  GET_PAYMENT_SERVICE_PROVIDERS_FOR_DEPOSIT,
  GET_PAYMENT_SERVICE_PROVIDERS_FOR_WITHDRAWAL,
  GET_USER_PROFILE_INFO,
  GET_USER_WALLET_DETAILS,
  SMS_REQUEST,
  SMS_VERIFY,
  TRANSACTION_IMAGE_UPLOAD,
} from "../../../components/api/endpoints";
import ThankYouModel from "../../../components/common/thankyou-model";
import Header from "../../../components/header";
import {
  setLoading,
  setProfileDataReducer,
  setUserWallets,
} from "../../../redux/actions";
import { getQueryParams } from "../../../utils/AppUtils";
import "./styles.css";
import AddCardOrAccount from "../../accounts/add-account";
import OtpInput from "../../profile/OtpInput";

function CreateTransaction(props) {
  const dispatch = useDispatch();
  let websiteUserId = props?.location?.state?.website_user_id;
  let profileDataReducer = useSelector((state) => state?.data?.profileData);
  const [profileData, setProfileData] = useState(profileDataReducer);
  const [isMobileExits, setIsMobileExists] = useState(false);
  const [userMobileMsg, setUserMobileMsg] = useState("");
  const [withdrawalCheck, setWithdrawalCheck] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);

  var paymentMethodType = [
    {
      icon: "/images/phonePe.png",
      type: " Phone Pay",
      id: 1,
    },
    {
      icon: "/images/googlePay.png",
      type: " Google Pay",
      id: 2,
    },
    {
      icon: "/images/amazonPay.png",
      type: " Amazon Pay",
      id: 3,
    },
    {
      icon: "/images/bharatPe.png",
      type: " Bharath Pay",
      id: 4,
    },
    {
      icon: "/images/bankIcon.png",
      type: " NEFT/RTGS",
      id: 5,
    },
    {
      icon: "/images/qrCode.png",
      type: " QR Code",
      id: 6,
    },
    {
      icon: "/images/others.png",
      type: " Others",
      id: 7,
    },
  ];
  const history = useHistory();

  const [successAlert, setSuccessAlert] = useState(false);

  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [transactionType, setTransactionType] = useState([]);
  const [transactionTypeErr, setTransactionTypeErr] = useState(false);

  const [transactionAmount, setTransactionAmount] = useState("");
  const [transactionAmountErr, setAmountTransactionErr] = useState(false);

  const [paymentType, setPaymentType] = useState("");
  const [paymentTypeId, setPaymentTypeId] = useState("");

  const [paymentTypeErr, setPaymentTypeErr] = useState(false);

  const [uploadImage, setUploadImage] = useState(null);
  const [uploadImgErr, setUploadImgErr] = useState(false);
  const [fileObject, setFileObject] = useState("");

  const [paymentServiceProviders, setPaymentServiceProviders] = useState([]);
  const [selectedServiceProvider, setSelectedServiceProvider] = useState([]);
  const [closeSelectedPayemts, setCloseSelectedPayemts] = useState(false);
  const [selectedServiceProvideErr, setSelectedServiceProvideErr] =
    useState(false);

  const [transactionTypeDropDown, setTransactionDropDown] = useState(false);
  const [paymentDropDown, setPaymentDropDown] = useState(false);
  const [paymentServiceProvideDropDown, setPaymentServiceProviderDropDown] =
    useState(false);

  const [bankAccount, setBankAccount] = useState("");
  const [bankAccountErr, setBankAccountErr] = useState(false);
  const userWalletsData = useSelector((state) => state?.data?.userWallets);
  const [userWalletData, setUserWalletData] = useState(userWalletsData);
  const [payMethods, setPayMethods] = useState(paymentMethodType);
  const [otp, setOtp] = useState("");
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [openOtp, setOpenOtp] = useState(false);
  const [showAddpayment, setShowAddpayment] = useState(false);
  const [showPaymentCard, setShowPaymentCard] = useState(false);

  const [accountType, setAccountType] = useState(1);

  const transactionTypeData = [
    {
      type: "Deposit",
      id: 1,
    },
    {
      type: "Withdrawal",
      id: 2,
    },
  ];

  const smsRequest = async () => {
    return call(SMS_REQUEST, {
      userId: `${localStorage?.getItem("user_id")}`,
      mobileNo:
        selectedServiceProvider?.upi_mobile_no ||
        selectedServiceProvider?.pg_mobile_no,
      changeUpiNumber: true,
    });
  };

  const getProfileInfo = async () => {
    profileData?.length === 0 && dispatch(setLoading(true));
    await call(GET_USER_PROFILE_INFO, {
      user_id: `${localStorage?.getItem("user_id")}`,
    })
      .then((res) => {
        if (res?.status === 200) {
          let data = res?.data?.data?.result;
          dispatch(setLoading(false));
          dispatch(setProfileDataReducer(data));
          setProfileData(data);
        } else {
          console.log(res?.data?.message);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const smsVerfiy = async (otp) => {
    return call(SMS_VERIFY, {
      userId: `${localStorage?.getItem("user_id")}`,
      otp: +otp,
    });
  };

  const handleAddCard = () => {
    setShowPaymentCard(true);
    setShowSubmitButton(false);
  };

  const handleTransactionType = async (transaction) => {
    if (transaction?.id === 2) {
      setShowAddpayment(true);
      await getProfileInfo();
      getPaymentServiceProvidersForWithDrawals();
      setPayMethods(paymentMethodType);
    } else {
      getPaymentServiceProvidersForDeposits();
      setShowAddpayment(false);
    }
    setTransactionType(transaction);
    setTransactionTypeErr(false);
    setTransactionDropDown(false);
  };
  const handleAmountChange = (e) => {
    setAmountTransactionErr(false);
    setTransactionAmount(parseInt(e.detail.value));
  };
  const handlePaymentMethod = (payment) => {
    setPaymentTypeId(payment?.id);
    setPaymentType(payment?.type);
    setPaymentTypeErr(false);
    setPaymentDropDown(false);
    if (paymentType !== payment?.type) {
      setSelectedServiceProvider({});
      setCloseSelectedPayemts(false);
    }
    // console.log({payment})
    setAccountType(payment?.id);
  };

  const handlePaymentServieProvide = (type) => {
    setSelectedServiceProvider(type);
    setSelectedServiceProvideErr(false);
    setPaymentServiceProviderDropDown(false);
  };

  const handlePaymentDropDown = () => {
    setPaymentServiceProviderDropDown(false);
    setTransactionDropDown(false);
    setPaymentDropDown(!paymentDropDown);
  };
  const handlServiceProviderDropDown = () => {
    setPaymentDropDown(false);
    setTransactionDropDown(false);
    setPaymentServiceProviderDropDown(!paymentServiceProvideDropDown);
  };

  const handleBankAccount = (e) => {
    setBankAccountErr(false);
    setBankAccount(e?.detail?.value);
  };

  const handleTransactionDropDown = () => {
    setPaymentDropDown(false);
    setPaymentServiceProviderDropDown(false);
    setTransactionDropDown(!transactionTypeDropDown);
  };
  // for image upload

  const handleImageUpload = (e) => {
    setUploadImgErr(false);
    if (e.target.files.length) {
      setUploadImage(URL.createObjectURL(e.target.files[0]));
      setFileObject(e.target.files[0]);
    }
  };

  const handleRemoveUplaoadImage = () => {
    setUploadImage(null);
    setUploadImgErr(true);
  };
  // for image upload

  const handleCancel = () => {
    history.goBack();
    // props.handleCreateTransactionCancel()
  };

  const handleSubmitDeposit = () => {
    if (transactionType?.length === 0) {
      setErrMsg("Please select transaction type");
      setTransactionTypeErr(true);
      return;
    }
    if (transactionAmount?.length === 0) {
      setErrMsg("Please enter transaction amount");
      setAmountTransactionErr(true);
      return;
    }
    if (transactionAmount === 0) {
      setErrMsg("Please enter valid amount");
      setAmountTransactionErr(true);
      return;
    }
    if (paymentType?.length === 0) {
      setErrMsg("Please select payment type");
      setPaymentTypeErr(true);
      return;
    }
    if (paymentTypeId !== 5 && selectedServiceProvider?.length === 0) {
      setErrMsg("Please select payment method");
      setSelectedServiceProvideErr(true);
      return;
    }
    if (paymentType === "Others") {
      if (bankAccount?.length === 0) {
        setErrMsg("Please provide Bank Details");
        setBankAccountErr(true);
        return;
      }
    }
    if (transactionType?.id === 1 && uploadImage === null) {
      setUploadImgErr(true);
      setErrMsg("Please upload payment screenshot");
      return;
    }
    createNewTransaction();
  };

  const handleSubmitWithdrawal = async () => {
    setOpenOtp(true);
    if (transactionType?.length === 0) {
      setErrMsg("Please select transaction type");
      setTransactionTypeErr(true);
      return;
    }

    if (transactionAmount?.length === 0) {
      setErrMsg("Please enter transaction amount");
      setAmountTransactionErr(true);
      return;
    }
    if (transactionAmount === 0) {
      setErrMsg("Please enter valid amount");
      setAmountTransactionErr(true);
      return;
    }
    if (transactionAmount > userWalletData?.wallet_balance) {
      setErrMsg(`You don't enough balance in your wallet`);
      setAmountTransactionErr(true);
      return;
    }
    let smsRequestResult = await smsRequest();
    if (smsRequestResult?.data?.error === "true") {
      setErrMsg(smsRequestResult?.data?.message);
      return setError(true);
    } else {
      setWithdrawalCheck(true);
      setUserMobileMsg(smsRequestResult?.data?.message);
      setIsMobileExists(true);
    }
  };
  const handleClickAvailablePayments = (obj) => {
    setCloseSelectedPayemts(true);
    setSelectedServiceProvider(obj);
  };
  const getPaymentServiceProvidersForDeposits = async () => {
    dispatch(setLoading(true));
    await call(
      {
        ...GET_PAYMENT_SERVICE_PROVIDERS_FOR_DEPOSIT,
        url: GET_PAYMENT_SERVICE_PROVIDERS_FOR_DEPOSIT.url,
      },
      { user_id: `${localStorage?.getItem("user_id")}` }
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          setPaymentServiceProviders(res?.data?.data?.results);
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const getPaymentServiceProvidersForWithDrawals = async () => {
    dispatch(setLoading(true));
    await call(
      {
        ...GET_PAYMENT_SERVICE_PROVIDERS_FOR_WITHDRAWAL,
        url: GET_PAYMENT_SERVICE_PROVIDERS_FOR_WITHDRAWAL.url,
      },
      { user_id: `${localStorage?.getItem("user_id")}` }
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          setPaymentServiceProviders(res?.data?.data?.results);
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const createNewTransaction = async () => {
    let transactionDepositData = {
      website_user_id: websiteUserId,
      user_id: `${localStorage?.getItem("user_id")}`,
      trnx_amount: transactionAmount,
      pay_trnx_id: "N/A",
      payment_method: paymentTypeId,
      pg_id: selectedServiceProvider?.pg_id || "N/A",
      trnxType: transactionType?.id,
      website_id: "",
      web_name: "",
    };

    dispatch(setLoading(true));
    await call(
      {
        ...CREATE_NEW_TRANSACTION,
        url: CREATE_NEW_TRANSACTION.url,
      },
      transactionDepositData
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          if (res?.data?.error === "true") {
            setErrMsg(res?.data?.message);
            setError(true);
          } else {
            transactionImageUpload(res?.data?.data?.result?.trnx_id);
          }
        } else {
          setErrMsg(res?.data?.message);
          setError(true);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        setErrMsg(err?.data?.message);
        dispatch(setLoading(false));
        setError(true);
        console.log(err);
      });
  };

  const createTransactionWithdrawal = async (otp) => {
    if (otp === "" || !otp?.length) {
      setError(true);
      return setErrMsg("Enter OTP");
    } else {
      setError(false);
      setErrMsg("");
    }
    let smsverifyResult = await smsVerfiy(otp);
    if (smsverifyResult?.data?.error === "true") {
      setErrMsg(smsverifyResult?.data?.message);
      return setError(true);
    } else {
      setUserMobileMsg(smsverifyResult?.data?.message);
      setIsMobileVerified(true);
    }

    let transactionWithdrawalData = {
      website_user_id: websiteUserId,
      user_id: `${localStorage?.getItem("user_id")}`,
      trnx_amount: transactionAmount,
      pay_trnx_id: "N/A",
      payment_method: paymentTypeId,
      pa_id: selectedServiceProvider?.pa_id || "N/A",
      trnxType: transactionType?.id,
      website_id: "",
      web_name: "",
    };
    dispatch(setLoading(true));
    await call(
      {
        ...CREATE_NEW_TRANSACTION,
        url: CREATE_NEW_TRANSACTION.url,
      },
      transactionWithdrawalData
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          if (res?.data?.error === "true") {
            setErrMsg(res?.data?.message);
            setError(true);
          } else {
            setSuccessAlert(true);
          }
        } else {
          setErrMsg(res?.data?.message);
          setError(true);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        setErrMsg(err?.message);
        dispatch(setLoading(false));
        setError(true);
        console.log(err);
      });
  };

  const transactionImageUpload = async (id) => {
    dispatch(setLoading(true));
    let transactionUploadData = {
      event_type: "trnx_request",
      user_id: `${localStorage?.getItem("user_id")}`,
      trnx_id: id,
    };
    await call(
      {
        ...TRANSACTION_IMAGE_UPLOAD,
        url: TRANSACTION_IMAGE_UPLOAD.url,
      },
      transactionUploadData
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          if (res?.data?.error === "true") {
            setErrMsg(res?.data?.message);
            setError(true);
          } else {
            setSuccessAlert(true);
            uploadImgOnAWS(
              res?.data?.data?.result?.url,
              res?.data?.data?.result
            );
          }
        } else {
          setErrMsg(res?.data?.message);
          setError(true);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        setErrMsg(err?.message);
        dispatch(setLoading(false));
        setError(true);
        console.log(err);
      });
  };

  const uploadImgOnAWS = (url, responseData) => {
    var formData = new FormData();
    formData.append("key", responseData?.fields?.key);
    formData.append(
      "x-amz-security-token",
      responseData?.fields["x-amz-security-token"]
    );
    formData.append("signature", responseData?.fields?.signature);
    formData.append("policy", responseData?.fields?.policy);
    formData.append("AWSAccessKeyId", responseData?.fields?.AWSAccessKeyId);
    formData.append("file", fileObject);
    axios({
      method: "post",
      url: url,
      data: formData,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const handleCancelModel = () => {
    setSuccessAlert(false);
    setTimeout(() => {
      history.push("/transactions");
    }, 500);
  };
  const handleBackToHome = () => {
    setSuccessAlert(false);
    setTimeout(() => {
      history.push("/");
    }, 500);
  };

  const DisplayDetails = (props) => {
    let result = props?.data;
    return (
      <div className="id-details-sub-content">
        {result?.card_type === 1 && (
          <div className="id-details-hiden-content w-100">
            <div className="id-payments-header">Make your Payments Towords</div>
            <div className="id-details-icons-container">
              <div>
                <div className="providers-toward-div">
                  <img
                    className="id-details-icons mr-10"
                    src={process.env.PUBLIC_URL + "/images/useravatar.png"}
                    alt="icon"
                  />
                  Name
                </div>
                <div className="providers-toward-div">
                  <img
                    className="id-details-icons mr-10"
                    src={process.env.PUBLIC_URL + "/images/mobileimg.png"}
                    alt="icon"
                  />
                  Mobile No
                </div>
                <div className="providers-toward-div">
                  <img
                    className="id-details-icons mr-10"
                    src={process.env.PUBLIC_URL + "/images/bankIcon.png"}
                    alt="icon"
                  />
                  UPI
                </div>
              </div>
              <div>
                <div className="providers-toward-div">
                  {result?.card_name || "-"}
                </div>
                <div className="providers-toward-div">
                  {transactionType?.id === 2
                    ? result?.upi_mobile_no
                    : result?.pg_mobile_no || "-"}
                </div>
                <div className="providers-toward-div">
                  {transactionType?.id === 2
                    ? result?.upi_name
                    : result?.pg_upi || "-"}
                </div>
              </div>
              {result?.pg_upi_url && Object.keys(result).length > 0 && (
                <div>
                  <img src={result?.pg_upi_url} alt="qr_code" width={100} />
                  <div className="signup-now scan-qr text-center">Scan QR</div>
                </div>
              )}
            </div>
          </div>
        )}

        {result?.card_type === 2 && (
          <div className="id-details-hiden-content w-100">
            <div className="id-payments-header">Make your Payments Towords</div>
            <div className="id-details-icons-container">
              <div className="providers-toward-div">
                <img
                  className="id-details-icons mr-10"
                  src={process.env.PUBLIC_URL + "/images/bankIcon.png"}
                />
                Bank Name
              </div>
              <div className="providers-toward-div">
                <img
                  className="id-details-icons mr-10"
                  src={process.env.PUBLIC_URL + "/images/mobileimg.png"}
                />
                IFSC
              </div>
              <div className="providers-toward-div">
                <img
                  className="id-details-icons mr-10"
                  src={process.env.PUBLIC_URL + "/images/bankIcon.png"}
                />
                Account No
              </div>
            </div>
            <div className="id-details-icons-container">
              <div className="providers-toward-div">
                {result?.bank_name || "-"}
              </div>
              <div className="providers-toward-div">
                {result?.bank_ifsc || "-"}
              </div>
              <div className="providers-toward-div">
                {result?.bank_account_no || "-"}
              </div>
            </div>
          </div>
        )}
        {(result?.card_type === 3 || result?.card_type === 4) && (
          <div className="id-details-hiden-content w-100">
            <div className="id-payments-header">Make your Payments Towords</div>
            <div>
              <div className="providers-toward-div">Card Number</div>
              <div className="providers-toward-div">CVV</div>
              <div className="providers-toward-div">Expiry</div>
            </div>
            <div>
              <div className="providers-toward-div">
                {result?.atm_card || "-"}
              </div>
              <div className="providers-toward-div">
                {result?.atm_cvv || "-"}
              </div>
              <div className="providers-toward-div">
                {result?.atm_expiry || "-"}
              </div>
            </div>
          </div>
        )}
        {paymentTypeId === 5 && (
          <div className="id-details-hiden-content w-100">
            <div className="id-payments-header">Make your Payments Towords</div>
            <div>
              <div className="providers-toward-div">Payment Details</div>
            </div>
            <div>
              <div className="providers-toward-div">{bankAccount}</div>
            </div>
          </div>
        )}
      </div>
    );
  };
  const getCardDisplayName = (service) => {
    if (service?.card_type === 1) {
      return transactionType?.id === 1 ? service?.pg_upi : service?.upi_name;
    } else if (service?.card_type === 2) {
      return service?.bank_account_no;
    } else if (service?.card_type === 3 || service?.card_type === 4) {
      return service?.atm_card;
    }
  };

  const getUserWalletDetails = async () => {
    userWalletData?.length === 0 && dispatch(setLoading(true));
    await call(
      { ...GET_USER_WALLET_DETAILS, url: GET_USER_WALLET_DETAILS.url },
      { user_id: `${localStorage?.getItem("user_id")}` }
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          dispatch(setUserWallets(res?.data?.data?.result));
          setUserWalletData(res?.data?.data?.result);
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err?.message);
      });
  };

  useEffect(() => {
    getUserWalletDetails();
    const queryParams = getQueryParams(props.location.search);
    queryParams["trnx-type"] &&
      handleTransactionType(
        transactionTypeData.find(
          (trans) => trans.type.toLowerCase() === queryParams["trnx-type"]
        )
      );
  }, []);
  const availablePaymentMethod = paymentServiceProviders?.filter((itm) =>
    paymentType == "Others"
      ? (itm.pg_upi || itm.upi_name) == "NA"
      : (itm.pg_upi || itm.upi_name)
          ?.toLocaleLowerCase()
          ?.replaceAll(/ /g, "") ===
        paymentType?.toLocaleLowerCase()?.replaceAll(/ /g, "")
  );
  return (
    <div className="create-transaction-container login-page-container">
      <div className="create-transaction-box form-container">
        <div className="create-transaction-header ml-20 mb-10">
          Create Transaction
        </div>
        <div
          className="input-dropdown login-input-field transaction-input add-account-form"
          style={{
            color: "black",
            borderRadius: "10px",
            border: transactionTypeDropDown
              ? "1px solid gray"
              : "0px solid gray",
          }}
          onClick={() => handleTransactionDropDown()}
        >
          <div className="display-flex-property">
            <img
              className="id-details-icons"
              src={process.env.PUBLIC_URL + "/images/transactions.png"}
            />
            {transactionType?.type ? (
              <div>
                {/* <div className="referred-selected-value">Transaction Type</div> */}
                <div>{transactionType?.type}</div>
              </div>
            ) : (
              "Transaction Type"
            )}
          </div>
          <div>
            <IonIcon icon={chevronDownOutline} />
          </div>
        </div>
        {transactionTypeDropDown ? (
          <div className="login-input-field reffered-by-values transaction-input-field add-account-content">
            {transactionTypeData?.map((transaction, index) => (
              <div key={index}>
                <div
                  className="reffered-by-value"
                  name="refferdBy"
                  onClick={() => handleTransactionType(transaction)}
                >
                  {transaction?.type}
                </div>
              </div>
            ))}
          </div>
        ) : null}
        <div
          hidden={!transactionTypeErr}
          className="error-message error-message-margin"
          style={{ marginTop: "5px" }}
        >
          {errMsg}
        </div>
        <IonItem
          className={`${
            transactionAmountErr ? "mb-5" : "mb-20"
          } mt-20 transaction-input`}
        >
          {/* <IonLabel position="floating">Enter Amount</IonLabel> */}
          <img
            className="id-details-icons"
            src={process.env.PUBLIC_URL + "/images/ruppe.png"}
          />
          <IonInput
            placeholder="Enter Amount"
            name="trnx_amount"
            type="number"
            min="1"
            onIonChange={(e) => handleAmountChange(e)}
            defaultValue={transactionAmount}
            clearInput
          ></IonInput>
        </IonItem>
        <div
          hidden={!transactionAmountErr}
          className="error-message error-message-margin"
          style={{ marginBottom: "20px" }}
        >
          {errMsg}
        </div>
        <div>
          <div
            className="input-dropdown login-input-field transaction-input mt-20 add-account-form"
            style={{
              borderRadius: "10px",
              borderBottom: paymentDropDown
                ? "1px solid gray"
                : "0.5px solid gray",
            }}
            onClick={() => handlePaymentDropDown()}
          >
            <div className="display-flex-property">
              <img
                className="id-details-icons"
                src={process.env.PUBLIC_URL + "/images/paymentMethod.png"}
              />
              {paymentType ? (
                <div>
                  {/* <div className="referred-selected-value">Payment Method</div> */}
                  <div>{paymentType}</div>
                </div>
              ) : (
                "Payment Method"
              )}
            </div>
            <div>
              <IonIcon icon={chevronDownOutline} />
            </div>
          </div>
          {paymentDropDown && transactionType?.length !== 0 ? (
            <div
              id="payment-type-container"
              className="login-input-field reffered-by-values transaction-input-field add-account-content"
            >
              {payMethods?.map((payment, index) => (
                <div key={index}>
                  <div
                    className="reffered-by-value"
                    name="refferdBy"
                    onClick={() => handlePaymentMethod(payment)}
                  >
                    <img
                      className="id-details-icons"
                      src={process.env.PUBLIC_URL + payment.icon}
                    ></img>
                    {payment?.type}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {!closeSelectedPayemts && (
            <div>
              {availablePaymentMethod?.map((itm, id) => (
                <div className="reffered-by-value payment-getway-div">
                <div className="payment-get-way-children-div1">
                  <div className="" name="refferdBy">
                    <img
                      className="id-details-icons"
                      src={process.env.PUBLIC_URL + "/images/profileImage.png"}
                    ></img>
                    {itm?.pg_mobile_no || itm?.upi_mobile_no}
                  </div>
                  <span
                    className="green-clr payment-get-way-selected-provider"
                    onClick={() => handleClickAvailablePayments(itm)}
                  >
                    {" "}
                    Select{" "}
                  </span>
                </div>
                </div>
            ))}
            </div>
          )}
          <div
            hidden={!paymentTypeErr}
            className="error-message error-message-margin"
            style={{ marginTop: "5px" }}
          >
            {errMsg}
          </div>
          {paymentTypeId !== "" &&
            paymentTypeId !== 5 &&
            paymentServiceProviders?.filter(
              (data) => data?.card_type === paymentTypeId
            )?.length === 0 && (
              <div>
                {" "}
                <div
                  className="error-message error-message-margin"
                  style={{ marginTop: "5px" }}
                >
                  {`No payment methods available please ${
                    transactionType?.id === 2 ? "add." : "contact master."
                  }`}
                </div>
                {/* {transactionTypeDropDown?.transactionType?.id === 2 && (
                  <Link className="add-payment" to="/accounts/referral">Add Payment</Link>
                )} */}
              </div>
            )}

          <div className="mt-20 mb-20"></div>
          {paymentTypeId !== "" &&
          paymentTypeId !== 5 &&
          paymentServiceProviders?.filter(
            (data) => data?.card_type === paymentTypeId
          )?.length !== 0 ? (
            <span>
              {/* <div
                className="input-dropdown login-input-field transaction-input mt-20 add-account-form"
                style={{
                  border: paymentServiceProvideDropDown
                    ? "1px solid #000000"
                    : "0.5px solid #000000",
                }}
                onClick={() => handlServiceProviderDropDown()}
              >
                <div className="display-flex-property">
                <img className="id-details-icons" src={process.env.PUBLIC_URL + "/images/transactions.png"}/>
                  {selectedServiceProvider?.length !== 0 ? (
                    <div>
                      <div className="referred-selected-value">
                        UPI SERVICE PROVIDER
                      </div>
                      <div>
                        {transactionType?.id === 1
                          ? selectedServiceProvider?.pg_upi
                          : selectedServiceProvider?.pa_id}
                      </div>
                    </div>
                  ) : (
                    "Service Provider"
                  )}
                </div>
                <div>
                  <IonIcon icon={chevronDownOutline} />
                </div>
              </div> */}
              {paymentServiceProvideDropDown ? (
                <div className="login-input-field reffered-by-values transaction-input-field add-account-content">
                  {paymentServiceProviders
                    ?.filter((data) => data?.card_type === paymentTypeId)
                    .map((service, index) => (
                      <div key={index}>
                        <div
                          className="reffered-by-value"
                          name="refferdBy"
                          onClick={() => {
                            handlePaymentServieProvide(service);
                          }}
                        >
                          {getCardDisplayName(service)}
                        </div>
                      </div>
                    ))}
                </div>
              ) : null}
              <div
                hidden={!selectedServiceProvideErr}
                className="error-message error-message-margin"
                style={{ marginTop: "5px" }}
              >
                {errMsg}
              </div>
            </span>
          ) : (
            paymentTypeId === 5 && (
              <span>
                <IonItem
                  className={`${
                    bankAccountErr ? "mb-5" : "mb-20"
                  } mt-20 transaction-input`}
                >
                  <IonLabel position="floating"> Enter Details</IonLabel>
                  <IonInput
                    placeholder="Enter Details"
                    name="bank_account"
                    type="text"
                    onIonChange={(e) => handleBankAccount(e)}
                    defaultValue={bankAccount}
                    clearInput
                  ></IonInput>
                </IonItem>
                <div
                  hidden={!bankAccountErr}
                  className="error-message error-message-margin"
                  style={{ marginBottom: "20px" }}
                >
                  {errMsg}
                </div>
              </span>
            )
          )}

          <div className="transacrion-payment-details">
            <DisplayDetails data={selectedServiceProvider} />
          </div>
          <div>
            <div
              className={
                transactionType?.id === 1
                  ? "image-upload-container mt-40 mb-30"
                  : "upload-none"
              }
            >
              <>
                {uploadImage ? (
                  <div className="close-icon-image">
                    <img
                      src={uploadImage}
                      alt="dummy"
                      width="100%"
                      height="175"
                    />
                    <IonIcon
                      icon={closeOutline}
                      className="close-icon-logo"
                      onClick={() => handleRemoveUplaoadImage()}
                    />
                  </div>
                ) : (
                  <label htmlFor="upload-button">
                    <IonImg
                      className="upload-image-icon"
                      src={process.env.PUBLIC_URL + "/images/upload_icon.svg"}
                    />
                  </label>
                )}
              </>
              {uploadImage ? null : (
                <div>Upload your payment screenshot here</div>
              )}
              <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={(e) => handleImageUpload(e)}
              />
              <br />
            </div>
          </div>
          <div
            hidden={!uploadImgErr}
            className="error-message error-message-margin"
            style={{ marginBottom: "10px" }}
          >
            {errMsg}
          </div>
          {showAddpayment && availablePaymentMethod.length === 0 && (
            // <Link className="add-payment" to="/accounts/referral">+ Add Payment</Link>
            <div className="add-payment" onClick={handleAddCard}>
              + Add Payment
            </div>
          )}
          {showPaymentCard && <AddCardOrAccount accountType={accountType} />}
          {showSubmitButton && (
            <div className="login-btn mt-40 mb-30">
              <IonButton
                disabled={withdrawalCheck}
                onClick={() =>
                  transactionType?.id === 2
                    ? handleSubmitWithdrawal()
                    : handleSubmitDeposit()
                }
              >
                SUBMIT
              </IonButton>
            </div>
          )}
          {withdrawalCheck && (isMobileExits || isMobileVerified) && (
            <div className="verified-text">{userMobileMsg}</div>
          )}
          {/* <IonModal isOpen={openOtp}>
          {withdrawalCheck && isMobileExits && (
            <div className="mt-20 verify-btn-container verify-btn">
              <IonItem>
                <IonLabel position="floating"> Enter OTP</IonLabel>
                <IonInput
                  placeholder="Enter otp"
                  name="otp"
                  type="text"
                  onIonChange={(e) => setOtp(e.detail.value)}
                  defaultValue={otp}
                  clearInput
                ></IonInput>
              </IonItem>{" "}
              <IonButton onClick={() => createTransactionWithdrawal()}>
                VERIFY
              </IonButton>
            </div>
          )}
          </IonModal> */}
          {withdrawalCheck && (
            <IonModal className="verify-otp-modal" isOpen={openOtp}>
              <IonHeader>
                <IonToolbar>
                  <IonTitle>Verify OTP</IonTitle>
                </IonToolbar>
              </IonHeader>
              <OtpInput
                setIsNumberVerified={() => setWithdrawalCheck(!withdrawalCheck)}
                modalSumbitMethod={(e) => createTransactionWithdrawal(e)}
                setIsOpen={() => setOpenOtp(!openOtp)}
                modalType="withdraw trnx"
              />
            </IonModal>
          )}
        </div>
        <div
          hidden={!error}
          className="mt-5 error-message error-message-margin"
        >
          {errMsg}
        </div>
        <div className="signup-now text-center mb-20 mt-40">
          <span onClick={props.handleCreateTransactionCancel}>CANCEL</span>
        </div>
        {
          <ThankYouModel
            successAlert={successAlert}
            handleCancel={handleCancelModel}
            handleBackToHome={handleBackToHome}
          />
        }
      </div>
    </div>
  );
}

export default withRouter(CreateTransaction);
