import { LocalNotifications } from "@awesome-cordova-plugins/local-notifications";
import {
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonPage,
} from "@ionic/react";
import "@ionic/react/css/ionic-swiper.css";
import { gridOutline, ellipse, grid } from "ionicons/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Autoplay, Keyboard, Pagination, Zoom } from "swiper";
import "swiper/modules/autoplay/autoplay.min.css";
import "swiper/modules/keyboard/keyboard.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";
import "swiper/modules/zoom/zoom.min.css";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.min.css";
import notificationsAll from "../../components/GroupCall/Notifications";
import MatchesContainer from "./MatchesContainer";
import { call } from "../../components/api/axios";
import {
  ALL_MEETINGS,
  GET_NOTIFICATIONS,
  GET_OFFERS_AND_NOTIFICATIONS,
  GET_UPCOMING_EVENTS,
  GET_USER_WALLET_DETAILS,
  MASTER_PROFILE,
} from "../../components/api/endpoints";
import { timeDiff } from "../../components/common/CommonServices";
import { persistData } from "../../components/common/commonData";
import Footer from "../../components/footer";
import {
  setALlMeetings,
  setNotiications,
  setUpcomingEvents,
  setUserWallets,
} from "../../redux/actions";
import BannerImage from "./assets/bannerImage.jpg";
import IplImage from "./assets/iplImage.jpg";
import WcImage from "./assets/t20WcImage.jpg";
// import { IonIcon } from "@ionic/react";
import { isUserVerified } from "../../helper";
import { setLoginModal } from "../../redux/actions/dataActions";
import Login from "../login";
import MainHeader from "./MainHeader";
import "./styles.css";
import SportsBook from "./SportsBook";
import SocialNetworks from "./SocialNetworks";
function Home() {
  let result = persistData();
  const {
    notificationData,
    notificationDataFlag,
    userWallets,
    userWalletsFlag,
    upcomingEvents,
    upcomingEventsFlag,
    allMeetings,
    allMeetingsFlag,
  } = result;
  const dispatch = useDispatch();
  const [notificationCount, setNotificationCount] = useState(0);
  const [initialized, setInitialized] = useState(false);

  let token = localStorage?.getItem("token");
  let isVerified = localStorage?.getItem("isVerified");

  const upcomingEventsStaticData = [
    {
      events_id: "1",
      event_image: BannerImage,
      event_name: "IPL",
      event_location: "Barcelonia",
    },
    {
      events_id: "2",
      event_image: BannerImage,
      event_name: "WTC",
      event_location: "Englad & wales",
    },
  ];
  const gridMenu = [
    {
      cssClass: "yellow-mode",
    },
    {
      cssClass: "White-mode",
    },
    {
      cssClass: "blue-mode",
    },
    {
      cssClass: "green-mode",
    },
  ];
  let upComingMatchesList = [
    {
      events_id: "1",
      event_image: IplImage,
      event_name: "IPL",
      event_location: "Barcelonia",
    },
    {
      events_id: "2",
      event_image: WcImage,
      event_name: "WTC",
      event_location: "Englad & wales",
    },
  ];
  const [status, setStatus] = useState(false);
  const [userWalletData, setUserWalletData] = useState(
    userWallets && userWallets[0]
  );
  const tabs = ["Exchange", "Multi Market", "Sports Book", "Live Casino"];
  let resultsData = upcomingEventsStaticData;
  let resultsDataUpcomingEvents = upComingMatchesList;
  if (isUserVerified()) {
    resultsData = upcomingEvents;
    resultsDataUpcomingEvents = upcomingEvents;
  }
  const [upcomingEventsData, setUpcomingEventsData] = useState(resultsData);
  const [upComingList, setUpComingList] = useState(resultsDataUpcomingEvents);

  const history = useHistory();
  const [allNotficationData, setNotificationData] = useState(notificationData);
  const [offers, setOffers] = useState([]);
  const [callFeature, setCallFeature] = useState(false);
  const [allMeetingsData, setAllMeetings] = useState(allMeetings);
  const [meetingAvailable, setMeetingAvailable] = useState(false);
  const [upcomingMeetingAvailable, setUpcomingMeetingAvailable] =
    useState(false);

  const partners = [
    {
      partnerImg: "/images/tCasinoParkLogo.png",
      partnerName: "T Casino Park",
      partnerGames: "2 Games",
    },
    {
      partnerImg: "/images/spark247Logo.png",
      partnerName: "Spark 247",
      partnerGames: "5 games",
    },
    {
      partnerImg: "/images/spark999Logo.png",
      partnerName: "Spark Book",
      partnerGames: "All games",
    },
    {
      partnerImg: "/images/sparkBookLogo.png",
      partnerName: "Spark999",
      partnerGames: "5 Games",
    },
    {
      partnerImg: "/images/tExchangeLogo.png",
      partnerName: "T Exchange",
      partnerGames: "1 Game",
    },
  ];
  const init = async () => {
    LocalNotifications.on("click").subscribe((data) => {
      history.push({
        pathname: "meetings",
      });
    });
  };

  const openUserSelect = () => {
    setStatus(true);
  };

  init();
  let user_id = localStorage.getItem("user_id");
  let master_id = localStorage.getItem("master_id");

  const getMasterProfile = async () => {
    await call(MASTER_PROFILE, { master_id: master_id })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem(
            "callFeatureEnabled",
            res?.data?.data?.result?.call_feature_enabled === "Y" ? true : false
          );
        } else {
          console.log(res.err);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOffersAndNotifications = async () => {
    await call(GET_NOTIFICATIONS, { user_id: user_id })
      .then((res) => {
        if (res?.status === 200) {
          let results = res?.data?.data?.results;
          results?.forEach((notification) => {
            if (
              moment(
                new Date(`${notification?.create_at} 'UTC`).toString()
              ).format("YYYY-MM-DD  hh:mm:ss") >
              moment(new Date(localStorage.getItem("timestamp"))).format(
                "YYYY-MM-DD  hh:mm:ss"
              )
            ) {
              setNotificationCount((prev) => prev + 1);
            }
          });
          setNotificationData(results);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllMeetings = async () => {
    notificationsAll.clearAllNotifications();
    await call(ALL_MEETINGS, { userId: user_id })
      .then((res) => {
        if (res?.status === 200) {
          const meetingArray = res?.data?.data;
          let upComingMeetingAvailable = meetingArray?.some(
            (meeting) =>
              meeting?.fileUrl === undefined &&
              !meeting?.fileUrl &&
              timeDiff(meeting?.eventTime, new Date().getTime(), "days") >= 0
          );
          setUpcomingMeetingAvailable(upComingMeetingAvailable);
          dispatch(setALlMeetings(meetingArray));
          setAllMeetings(res?.data?.data);
          let temp = meetingArray?.some(
            (meeting) =>
              timeDiff(meeting?.eventTime, new Date().getTime(), "minutes") >=
                0 &&
              timeDiff(meeting?.eventTime, new Date().getTime(), "minutes") <=
                15
          );
          setMeetingAvailable(temp);
          if (meetingArray) {
            meetingArray.forEach(async (item) => {
              let time = item.eventTime / 1000 - Date.now() / 1000;
              let eventDate = new Date(item.eventTime);
              let scheduleArray = [];
              if (time >= 3600) {
                scheduleArray?.push(
                  await scheduleNotificationHourly(
                    eventDate,
                    item?.eventName,
                    item?.roomId
                  )
                );
                scheduleArray?.push(
                  await scheduleNotificationMinutes(
                    eventDate,
                    30,
                    item?.eventName?.item?.roomId
                  )
                );
                scheduleArray?.push(
                  await scheduleNotificationMinutes(
                    eventDate,
                    10,
                    item?.eventName,
                    item?.roomId
                  )
                );
                scheduleArray?.push(
                  await scheduleNotificationMinutes(
                    eventDate,
                    5,
                    item?.eventName,
                    item?.roomId
                  )
                );
                scheduleArray?.push(
                  await scheduleNotificationMinutes(
                    eventDate,
                    1,
                    item?.eventName,
                    item?.roomId
                  )
                );
                notificationsAll.schedule(scheduleArray);
              } else if (time >= 3600 / 2) {
                scheduleArray?.push(
                  await scheduleNotificationMinutes(
                    eventDate,
                    30,
                    item?.eventName,
                    item?.roomId
                  )
                );
                scheduleArray?.push(
                  await scheduleNotificationMinutes(
                    eventDate,
                    10,
                    item?.eventName,
                    item?.roomId
                  )
                );
                scheduleArray?.push(
                  await scheduleNotificationMinutes(
                    eventDate,
                    5,
                    item?.eventName,
                    item?.roomId
                  )
                );
                scheduleArray?.push(
                  await scheduleNotificationMinutes(
                    eventDate,
                    1,
                    item?.eventName,
                    item?.roomId
                  )
                );
                notificationsAll.schedule(scheduleArray);
              } else if (time >= 600) {
                scheduleArray?.push(
                  await scheduleNotificationMinutes(
                    eventDate,
                    10,
                    item?.eventName,
                    item?.roomId
                  )
                );
                scheduleArray?.push(
                  await scheduleNotificationMinutes(
                    eventDate,
                    5,
                    item?.eventName,
                    item?.roomId
                  )
                );
                scheduleArray?.push(
                  await scheduleNotificationMinutes(
                    eventDate,
                    1,
                    item?.eventName,
                    item?.roomId
                  )
                );
                notificationsAll?.schedule(scheduleArray);
              } else if (time >= 300) {
                scheduleArray?.push(
                  await scheduleNotificationMinutes(
                    eventDate,
                    5,
                    item?.eventName,
                    item?.roomId
                  )
                );
                scheduleArray?.push(
                  await scheduleNotificationMinutes(
                    eventDate,
                    1,
                    item?.eventName,
                    item?.roomId
                  )
                );
                notificationsAll?.schedule(scheduleArray);
              } else if (time > 60) {
                scheduleArray?.push(
                  await scheduleNotificationMinutes(
                    eventDate,
                    1,
                    item?.eventName,
                    item?.roomId
                  )
                );
                notificationsAll?.schedule(scheduleArray);
              }
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const scheduleNotificationHourly = (eventDate, eventName, roomId) => {
    const eventTriggerDate = eventDate.setHours(eventDate.getHours() - 1);
    return {
      id: eventTriggerDate * 100 + (roomId % 100),
      text: "Up comming event in 1 hour",
      title: eventName,
      trigger: {
        at: new Date(eventTriggerDate),
      },
      vibrate: true,
      foreground: true,
    };
  };
  const scheduleNotificationMinutes = async (
    eventDate,
    time,
    eventName,
    roomId
  ) => {
    const eventTriggerDate = eventDate.setMinutes(
      eventDate.getMinutes() - time
    );
    console.log("eventTriggerDate.getTime()", eventTriggerDate);
    return {
      id: eventTriggerDate * 100 + (roomId % 100),
      text:
        "Up comming event in " + (time > 1 ? time + " minutes" : "a minute"),
      title: eventName,
      trigger: {
        at: new Date(eventTriggerDate),
      },
      vibrate: true,
      foreground: true,
    };
  };
  const getUpcomingEvents = async () => {
    await call(
      GET_UPCOMING_EVENTS,

      { user_id: user_id }
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setUpcomingEvents(res?.data?.data?.results));
          setUpcomingEventsData(res?.data?.data?.results);
          setUpComingList(res?.data?.data?.results);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserWalletDetails = async () => {
    await call(GET_USER_WALLET_DETAILS, { user_id: user_id })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setUserWallets(res?.data?.data?.result));
          setUserWalletData(res?.data?.data?.result);
          localStorage?.setItem(
            "master_id",
            res?.data?.data?.result?.master_user_id
          );
        } else {
        }
      })
      .catch((err) => {
        console.log(err?.message);
      });
  };

  const navigate = (path, status) => {
    handleLoginModal();
    if (isUserVerified()) {
      history.push({
        pathname: path,
        state: {
          data: status,
        },
      });
    }
  };
  const getOffers = async () => {
    await call(
      GET_OFFERS_AND_NOTIFICATIONS,

      { user_id: user_id }
    )
      .then((res) => {
        if (res?.status === 200) {
          setOffers(res?.data?.data?.results);
        } else {
          dispatch(setNotiications(res?.data?.data?.results));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setIntervalCalls = async () => {
    setInitialized(true);
    await getAllMeetings();
  };

  const handleLoginModal = () => {
    if (
      token === undefined ||
      token === "undefined" ||
      !token ||
      isVerified === undefined ||
      isVerified === "undefined" ||
      !isVerified
    ) {
      dispatch(setLoginModal(true));
    }
  };
  useEffect(() => {
    if (isUserVerified()) {
      getAllMeetings();
      getUserWalletDetails();
      getUpcomingEvents();
      getOffersAndNotifications();
      getOffers();
      getMasterProfile();
      let interval1 = null;
      if (!initialized && !interval1) {
        interval1 = setInterval(() => {
          setIntervalCalls();
        }, 30000);
      }

      return () => {
        clearInterval(interval1);
      };
    }
  }, []);

  return (
    <>
      <IonPage className="homepage-bg">
        <IonContent className="homepage-bg">
          <MainHeader
            notificationCount={notificationCount}
            userWalletData={userWalletData}
            navigate={navigate}
          />
          <div className="scrolling-content flex w-100">
            <div>
              <div className="custom-menuicon-container">
                {gridMenu.map((value, index) => {
                  return (
                    <div
                      className={`${value.cssClass} custom-menu-yellow flex`}
                    >
                      <IonIcon icon={grid}></IonIcon>
                    </div>
                  );
                })}
                {/* <div className="custom-menu-yellow flex">
                  <IonIcon icon={grid}></IonIcon>
                </div>
                <div>
                  <IonIcon icon={grid}></IonIcon>
                </div>
                <div>
                  <IonIcon icon={grid}></IonIcon>
                </div>
                <div>
                  <IonIcon icon={grid}></IonIcon>
                </div> */}
              </div>
              <div className="master-name">
                {userWalletData?.master_user_name || "Master Name"}
              </div>
            </div>

            <div className="flex-sp-b w-70 tabs-div">
              {tabs.map((value, index) => {
                return (
                  <div key={index} className="web-tabs">
                    {value}
                  </div>
                );
              })}
            </div>
            <div className="w-100 flex scrolling-text">
              <img src={process.env.PUBLIC_URL + "/assets/promate.png"} />
              {/* <marquee>
                Welcome to our exchange!! I Now enjoy beting on toss I 1500+
                games on casino
              </marquee> */}
              <div className="scroll-container">
                <div className="scroll-text">
                  Welcome to our exchange!! I Now enjoy beting on toss I 1500+
                  games on casino
                </div>
              </div>
            </div>
          </div>
          <div className="homepage-content ion-padding">
            <div className="today-games-div">
              <Swiper
                modules={[Autoplay, Keyboard, Pagination, Zoom]}
                autoplay={{ delay: 2000 }}
                keyboard={true}
                loop={true}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                spaceBetween={10}
                slidesPerView={"1"}
                centeredSlides={true}
                zoom={true}
              >
                {upcomingEventsData?.length > 0 &&
                  upcomingEventsData?.map((event, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <IonCard
                          className="today-games-card carousel__snapper custom-banner-border"
                          key={event?.events_id}
                        >
                          <IonImg
                            src={event?.event_image}
                            alt={event?.event_image}
                            loa
                          />
                        </IonCard>
                      </SwiperSlide>
                    );
                  })}
                {/* {upcomingEventsStaticData.map((value, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <IonCard className="today-games-card carousel__snapper">
                        <IonImg src={value.event_image} />
                      </IonCard>
                    </SwiperSlide>
                  );
                })} */}
              </Swiper>
            </div>
            <div className="partners-div">
              <div className="partner-text ion-margin-vertical">
                Multi Market
              </div>
              <div className="sports-book-yellow-line"></div>
              <div className="custom-multimarket-container mt-5">
                <div
                  className="partners-logos "
                  onClick={() => navigate("/accounts", 1)}
                >
                  {partners?.map(
                    ({ partnerGames, partnerImg, partnerName }, index) => (
                      <div className="partners-index" key={index}>
                        <div className="custom-round-border">
                          <div className="patner-image-container">
                            <IonImg
                              className="partner-img"
                              src={`${process.env.PUBLIC_URL + partnerImg}`}
                              altp="partner-img"
                            ></IonImg>
                          </div>
                        </div>

                        <div className="partners-game">
                          <div className="partners-content">
                            {partnerName}
                            <div className="games-counter">{partnerGames}</div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>

            <MatchesContainer />

            <div className="w2call-container ion-margin-vertical">
              <div className="refer-friend">
                <div className="coins-container">
                  <IonImg
                    className="coins-image"
                    src={process.env.PUBLIC_URL + "/images/coin.png"}
                    alt="coin-image"
                  ></IonImg>
                </div>
                <div
                  className="refer-container"
                  onClick={() => navigate("/refer-your-friend")}
                >
                  <div className="content-container">
                    <ul className="content-container-list">
                      {offers?.length > 0 ? (
                        offers?.map((item, index) => {
                          return (
                            <li
                              className="content-container-list-item"
                              key={index}
                            >
                              {item.offer_name}
                              <br />
                              <span className="container-text">
                                {item.offer_description}
                              </span>
                            </li>
                          );
                        })
                      ) : (
                        <div className="refer-container">
                          <h5 className="container-h4">Offers</h5>
                          <span className="container-text">
                            Refer Your friend &amp; Earn Money
                          </span>
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="money-div">
                  <IonImg
                    className="money-image"
                    src={process.env.PUBLIC_URL + "/images/money.png"}
                    alt="money-img"
                  ></IonImg>
                </div>
              </div>
              <div
                onClick={() => navigate("/meetings")}
                className={`callsupport-container ${
                  upcomingMeetingAvailable && meetingAvailable
                    ? "meeting-ripple"
                    : ""
                }`}
              >
                <div className="supporttext-container">
                  <h5 className="container-h4">We2Call</h5>
                  <span className="container-text">For Support</span>
                </div>
                <div className="icons-container">
                  <div className="icons-pngs">
                    <IonImg
                      className="chatbox-image"
                      src={process.env.PUBLIC_URL + "/images/chatBox.png"}
                      alt="chat-box"
                    ></IonImg>
                  </div>
                  <div className="callman-div">
                    <IonImg
                      className="callman-image"
                      src={process.env.PUBLIC_URL + "/images/callMen.png"}
                      alt="call-men"
                    ></IonImg>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-border-container">
              <div className="tickets-ids ">
                <div className="tickets-container ">
                  <div className="tickets-right-div">
                    <div
                      className="tickets-image-box"
                      onClick={() => {
                        navigate("/tickets");
                      }}
                    >
                      <div className="tickets-image-wraper">
                        <IonImg
                          className="ticket-icons"
                          src={
                            process.env.PUBLIC_URL + "/images/ticketIcon01.png"
                          }
                          alt="tickets"
                        ></IonImg>
                      </div>
                      <span className="ticket-text">
                        {isNaN(
                          userWalletData?.trnx_tickets_all +
                            userWalletData?.web_tickets_all
                        )
                          ? 0
                          : userWalletData?.trnx_tickets_all +
                            userWalletData?.web_tickets_all}{" "}
                        Total tickets{" "}
                      </span>
                    </div>
                    <div
                      className="tickets-box"
                      onClick={() => {
                        navigate("/tickets", 1);
                      }}
                    >
                      <div className="tickets-image-wraper">
                        <IonImg
                          className="ticket-icons"
                          src={
                            process.env.PUBLIC_URL + "/images/ticketIcon02.png"
                          }
                          alt="tickets"
                        ></IonImg>
                      </div>
                      <span className="ticket-text">
                        {" "}
                        {isNaN(
                          userWalletData?.trnx_tickets_approved +
                            userWalletData?.web_tickets_approved
                        )
                          ? 0
                          : userWalletData?.trnx_tickets_approved +
                            userWalletData?.web_tickets_approved}{" "}
                        Approved tickets{" "}
                      </span>
                    </div>
                  </div>
                  <div className="tickets-left-div">
                    <div
                      className="tickets-box-two"
                      onClick={() => {
                        navigate("/accounts");
                      }}
                    >
                      <div className="tickets-image-wraper">
                        <IonImg
                          className="ticket-icons"
                          src={process.env.PUBLIC_URL + "/images/idsIcon.png"}
                          alt="ids"
                        ></IonImg>
                      </div>
                      <span className="ticket-text">
                        {userWalletData?.active_ids || 0} Active ids
                      </span>
                    </div>
                    <div
                      className="tickets-box-three"
                      onClick={() => navigate("/refer-your-friend")}
                    >
                      <div className="tickets-image-wraper">
                        <IonImg
                          className="ticket-icons"
                          src={
                            process.env.PUBLIC_URL + "/images/giftBoxIcon.png"
                          }
                          alt="giftBox"
                        ></IonImg>
                      </div>
                      <span className="ticket-text">
                        {userWalletData?.offers_count || 0} Offers
                      </span>
                    </div>
                  </div>
                </div>
                <div className="giftbox-container">
                  <IonImg
                    className="giftbox-image"
                    src={process.env.PUBLIC_URL + "/images/giftBox.png"}
                    alt="gift-box"
                  ></IonImg>
                </div>
              </div>
            </div>

            <SportsBook />
            <div>
              <SocialNetworks />
            </div>
          </div>
        </IonContent>
        <Footer />
      </IonPage>
      <Login />
      {/* <VerifyOTPModal /> */}
    </>
  );
}

export default Home;
