const moment = require("moment");

exports.timeDiff = (eventTime, currentTime, type) => {
  return moment(eventTime).diff(currentTime, type);
  
};

exports.isDateChanged = (date1, date2) => {
  return (
    moment(date1).format("YYYY-MM-DD") === moment(date2).format("YYYY-MM-DD")
  );
};
