import { IonBadge, IonButton, IonIcon, IonImg, IonModal } from "@ionic/react";
import { mic, notifications, person } from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router";
import WalletBalanceDetails from "../texch-websites/WalletBalanceDetails";
import { closeOutline } from "ionicons/icons";
import CreateTransaction from "../transactions/create-transaction";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import WalletModal from "../texch-websites/WalletModal";
import { RiWallet3Fill } from "react-icons/ri";
function MainHeader(props) {
  const [createTransactionModel, setCreateTrasactionModel] = useState(false);
  const handleCreateTransaction = () => {
    setCreateTrasactionModel(true);
  };
  const handleCreateTransactionCancel = () => {
    setCreateTrasactionModel(false);
  };
  const history = useHistory();
  const { notificationCount, userWalletData, navigate } = props;
  const [walletModal, setWalletModal] = useState(false);
  const handleWalletModalOpen = () => {
    setWalletModal(true);
  };
  const handleWalletModalClose = () => {
    setWalletModal(false);
  };
  const headerLeft = [
    // {
    //   icon: person,
    //   path: "/profile",
    // },
    // {
    //   icon: mic,
    //   path: "/meetings",
    // },
    {
      image: notifications,

      path: "/images/Notification.png",
    },
  ];

  const displayAmount = () => {
    let amt = userWalletData?.wallet_balance || 0;
    return amt.toLocaleString("en-IN", {
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="user-outer-div">
      {/* <div className="menu-icon-container">
        <IonImg
          className="menu-icon"
          src={process.env.PUBLIC_URL + "/images/menu.png"}
          onClick={() => history.push("/side-menu")}
        ></IonImg>
      </div> */}
      <div className="flex">
        <IonImg
          className="homepage-logo"
          src="./assets/dupunt_icon.png"
          alt="logo"
        />
      </div>
      <div className="header-right">
        {/* {localStorage.getItem("callFeatureEnabled") && (
                <Link to="/meetings" className="join-meet-btn">
                  <IonIcon
                    icon={videocamOutline}
                    className={`notification-icon ${
                      upcomingMeetingAvailable && meetingAvailable
                        ? "ripple"
                        : ""
                    }`}
                  ></IonIcon>
                </Link>
              )} */}
        <div
          className="header-balance-container "
          // onClick={() => navigate("/multi-wallet")}
        >
          <div
            className="flex-jc-c header-balance-title"
            onClick={() => handleWalletModalOpen()}
          >
            <RiWallet3Fill className="wallet-icon" />
            Balance <MdOutlineKeyboardDoubleArrowDown className="wallet-icon" />
          </div>
          <div className="header-balance-amount">
            {`${
              localStorage?.getItem("currency_code") || "₹"
            }${displayAmount()}`}
          </div>
        </div>
        <IonButton
          className="header-deposit-btn"
          color="success"
          // onClick={() => navigate("/transactions")}
          onClick={() => handleCreateTransaction()}
        >
          Deposit
        </IonButton>

        <IonButton
          className="header-deposit-btn"
          color="warning"
          // onClick={() => navigate("/transactions")}
          onClick={() => handleCreateTransaction()}
        >
          Withdraw
        </IonButton>
        {headerLeft?.map(({ icon, path }) => (
          <div to={path} key={icon} onClick={() => navigate(path)}>
            <img
              src={process.env.PUBLIC_URL + "/images/Notification.png"}
              className="header-icons"
            ></img>
          </div>
        ))}

        <IonButton
          className="header-deposit-btn"
          color="warning"
          onClick={() => navigate("/texch-websites")}
        >
          Reports
        </IonButton>
      </div>
      {notificationCount > 0 && (
        <IonBadge className="notification-badge"></IonBadge>
      )}
      <div className="flex-sp-a">
        <IonModal isOpen={walletModal} className="wallet-balance-modal">
          <WalletModal handleWalletModalClose={handleWalletModalClose} />
        </IonModal>
      </div>
      <IonModal
        className="create-transaction-model"
        isOpen={createTransactionModel}
      >
        <CreateTransaction
          handleCreateTransactionCancel={handleCreateTransactionCancel}
        />
      </IonModal>
    </div>
  );
}

export default MainHeader;
