import { IonButton, IonImg, IonInput } from "@ionic/react";

function DateRange() {
  return (
    <div>
      <div className="website-select-date flex">
        <div className="flex flex-column w-45">
          <span className="mt-5 ml-5">Start Date</span>
          <IonInput
            className="website-date text-uppercase start-end-date mt-5"
            type="date"
          >
            <IonImg
              src={process.env.PUBLIC_URL + "/images/CalenderIcon.png"}
              alt="calendar-icon"
              className="calendar-icon"
            />
          </IonInput>
        </div>
        <IonImg
          className="arrow-image mt-10"
          src={process.env.PUBLIC_URL + "/images/leftRightArrow.png"}
        ></IonImg>
        <div className="flex flex-column w-45">
          <span className="mt-5 ml-5">End Date</span>
          <IonInput
            className="website-date text-uppercase start-end-date mt-5"
            type="date"
          >
            <IonImg
              src={process.env.PUBLIC_URL + "/images/CalenderIcon.png"}
              alt="calendar-icon"
              className="calendar-icon"
            />
          </IonInput>
        </div>
      </div>
      <div className="mt-10">
        <IonButton expand="block" className="load-reports-button">
          Load Report
        </IonButton>
      </div>
    </div>
  );
}

export default DateRange;
