import * as actionTypes from "../actions/actionConstants";

const initialState = {
  profileData: [],
  offersAndNotification: [],
  offersAndNotificationFlag: true,
  userWallets: [],
  userWalletsFlag: true,
  upcomingEvents: [],
  upcomingEventsFlag: true,
  userTransactions: [],
  userTransactionsFlag: true,
  webAccountsRegisteredList: [],
  webAccountsRegisteredListFlag: true,
  webAccountsList: [],
  webAccountsListFlag: true,
  accountRefferal: [],
  accountRefferalFlag: true,
  allTicketsData: [],
  allTicketsDataFlag: true,
  supportData: [],
  recentUserTransactions: [],
  webAccountInfo: [],
  notificationData: [],
  notificationDataFlag: true,
  pricayPolicyData: [],
  pricayPolicyDataFlag: true,
  allMeetings: [],
  allMeetingsFlag: true,
  loginModal: false,
  authenticatorModal: false,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROFILE_DATA:
      return {
        ...state,
        profileData: action.data,
      };
    case actionTypes.SET_OFFERS_AND_NOTIFICATIONS:
      return {
        ...state,
        offersAndNotification: action.data,
        offersAndNotificationFlag: false,
      };
    case actionTypes.SET_USER_WALLETS:
      return {
        ...state,
        userWallets: [action.data],
        userWalletsFlag: false,
      };
    case actionTypes.SET_UPCOMING_EVENTS:
      return {
        ...state,
        upcomingEvents: action.data,
        upcomingEventsFlag: false,
      };
    case actionTypes.SET_USER_TRANSACTIONS:
      return {
        ...state,
        userTransactions: action.data,
        userTransactionsFlag: false,
      };
    case actionTypes.SET_WEB_ACCOUNT_LIST:
      return {
        ...state,
        webAccountsList: action.data,
        webAccountsListFlag: false,
      };
    case actionTypes.SET_WEB_ACCOUNT_REGISTERED_LIST:
      return {
        ...state,
        webAccountsRegisteredList: action.data,
        webAccountsRegisteredListFlag: false,
      };
    case actionTypes.SET_ACCOUNT_REFFERAL:
      return {
        ...state,
        accountRefferal: action.data,
        accountRefferalFlag: false,
      };
    case actionTypes.SET_ALL_TICKETS:
      return {
        ...state,
        allTicketsData: action.data,
        allTicketsDataFlag: false,
      };
    case actionTypes.SET_ALL_MEETINGS:
      return {
        ...state,
        allMeetings: action.data,
        allMeetingsFlag: false,
      };
    case actionTypes.SET_SUPPORT_DATA:
      return {
        ...state,
        supportData: action.data,
      };
    case actionTypes.RECENT_USER_TRANSACTIONS:
      return {
        ...state,
        recentUserTransactions: action.data,
      };
    case actionTypes.WEB_ACCOUNT_INFO:
      return {
        ...state,
        webAccountInfo: action.data,
      };
    case actionTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        notificationData: action.data,
        notificationDataFlag: false,
      };
    case actionTypes.SET_PRIVACY_POLICY:
      return {
        ...state,
        pricayPolicyData: action.data,
        pricayPolicyDataFlag: false,
      };
    case actionTypes.SET_MEETING_IS_STARTED:
      return {
        ...state,
        isMeetingStarted: action.data,
      };
    case actionTypes.SET_MINIMIZE:
      return {
        ...state,
        isMinimize: action.data,
      };
    case actionTypes.SET_MAXMIZE:
      return {
        ...state,
        isMaxmize: action.data,
      };
    case actionTypes.SET_LOGIN_MODAL:
      return {
        ...state,
        loginModal: action.data,
      };
    case actionTypes.SET_AUTHENTICATOR_MODAL:
      return {
        ...state,
        authenticatorModal: action.data,
      };
    default:
      return state;
  }
};

export default dataReducer;
