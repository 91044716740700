import * as actionTypes from "../actions/actionConstants";

const initialState = {
  loading: false,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.data,
      };

    default:
      return state;
  }
};

export default commonReducer;
