import React from 'react'
import Table from './Table';
import roundArrow from "./assents/roundDownArrow.png"
function MatchPositionTables() {
    const tableData = [
        { id: 1, team: 'india', profit: "p",ammount:100000 },
        { id: 2, team: 'srilanka', profit: "p",ammount:100000 },
        { id: 3, team: 'india', profit: "p",ammount:100000 },
        { id: 4, team: 'srilanka', profit: "p",ammount:100000 },
      ];
      const headerList =[
        {value:"Sno"},
        {value:"Team"},
        {value:"P/E"},
        {value:"Ammount"}
      ]
      const fancyOvers=[{
        fancy:"6overs",
        amount:"+50000"
      },
      {
        fancy:"10overs",
        amount:"+50000"
      },
      {
        fancy:"15overs",
        amount:"+50000"
      },
      {
        fancy:"20overs",
        amount:"+50000"
      }
    ]
    const fancyHeader =[
      {value:"Over"},
      {value:"Team"},
      {value:"Run"},
      {value:"Y/N"},
      {value:"Amount"}
    ]
    const fancyData = [
      { id: 20, team: 'India', profit: "175",ammount:"Y",fancyAmount:"100000" },
      { id: 20, team: 'India', profit: "176",ammount:"Y" ,fancyAmount:"100000"},
      { id: 20, team: 'India', profit: "187",ammount:"N" ,fancyAmount:"200000"},
      { id: 20, team: 'India', profit: "189",ammount:"Y",fancyAmount:"150000" },
    ];

  return (
<div className='table-page-conatainer'>
<div className='flex-box'>
    <div>19-04-2023</div>
    <div>T20</div>
    <div>Ind vs Sl</div>
</div>
<div className='user-match-position flex-box'>srinivas-Match Position<img className='down-icon' src={roundArrow}/></div>
<div className='table-title'>
    <div>india<br/><span className='win-amount'>+100000</span></div>
    <div>Sl<br/><span className='loose-amount'>+100000</span></div>
</div>
<Table data={tableData} columns={headerList}/>
<div className='user-match-position flex-box'><p>Fancy P/L=<span className='win-amount'>500000</span></p><img className='down-icon' src={roundArrow}></img></div>
<div className='table-title'>
  <div className='innings-name'>1st Inn</div>
  {fancyOvers.map((value,index)=>(<div key={index}>{value.fancy}<br/><span className='win-amount'>{value.amount}</span></div>))} 
</div>
<Table data={fancyData} columns={fancyHeader}/>
</div>
      );
    };

export default MatchPositionTables