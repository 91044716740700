import {
  IonAlert,
  IonButton,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel
} from "@ionic/react";
import { eye, eyeOff } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { call } from "../../components/api/axios";
import {
  CHECK_USER_NAME_AVAILABILITY,
  CREATE_USER,
  GET_SECURITY_QUESTION_LIST
} from "../../components/api/endpoints";
import { validation } from "../../helper";
import { setLoading } from "../../redux/actions";
import { setLoginModal } from "../../redux/actions/dataActions";
import "./styles.css";

function SignUp() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  const [securityQuestionList, setSecurityQuestionList] = useState([]);
  const [inputData, setInputData] = useState({
    referred_code: '9999'
  });
  const [errorObj, setErrorObj] = useState({});
  const [recovery, setRecovery] = useState(false);
  const [recoveryType, setRecoveryType] = useState("");
  const [securityQuestion, setSecurityQuestion] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleCnfPassword, setToggleCnfPassword] = useState(false);

  const SIGNUP_INPUT_FIELDS = [
    {
      name: "referred_code",
      id: "referred_code",
      placeholder: "Referred By",
      label: "Reffered By",
      value: inputData?.referred_code || "",
      icon: "",
      type: "text",
    },
    {
      name: "user_name",
      id: "user_name",
      placeholder: "Username",
      label: "Enter Username",
      value: inputData?.user_name || "",
      icon: "",
      type: "text",
      required: true,
      rules: {
        method: "minLen",
        condition: 5,
      },
    },
    {
      name: "user_password",
      id: "user_password",
      placeholder: "Enter Password",
      label: "Password",
      value: inputData?.user_password || "",
      icon: togglePassword ? eye : eyeOff,
      type: togglePassword ? "text" : "password",
      required: true,
      rules: {
        method: "minLen",
        condition: 5,
      },
    },
    {
      name: "cnfPwd",
      id: "cnfPwd",
      placeholder: "Enter Confirm Password",
      label: "Confirm Password",
      value: inputData?.cnfPwd || "",
      icon: toggleCnfPassword ? eye : eyeOff,
      type: toggleCnfPassword ? "text" : "password",
      required: true,
      rules: {
        method: "compare",
        condition: inputData?.user_password,
      },
    },
  ];

  const recoveryArr = [
    { label: "Select", value: "" },
    { label: "Email ID", value: "Email" },
    { label: "Phone Number", value: "Phone Number" },
    { label: "Security Questions", value: "Security Questions" },
  ];

  const validate = async () => {
    let arr = [];
    SIGNUP_INPUT_FIELDS?.forEach((input) => {
      let errMsg;
      if (input?.required) {
        errMsg = validation(
          input?.value,
          input?.rules?.method,
          input?.rules?.condition
        );
        if (errMsg) {
          return arr.push({ [input.name]: errMsg });
        } else {
          return arr.push({ [input.name]: false });
        }
      }
    });

    let temp;
    arr?.forEach((item) => {
      temp = { ...temp, ...item };
    });
    setErrorObj({ ...errorObj, ...temp });
    return temp;
  };

  const handleSignUp = async () => {
    let isValid = await validate();

    if (Object.keys(isValid).length === 0) {
      return;
    }

    for (let key in isValid) {
      if (isValid[key]) {
        return;
      }
    }

    let signUpData = {
      user_name: inputData?.user_name,
      user_password: inputData?.user_password,
      mobile_tkn: "Here is the device token",
      referred_code: inputData?.referred_code,
    };
    if (recoveryType === "Security Questions") {
      signUpData = {
        ...signUpData,
        security_q_1: securityQuestion,
        security_a_1: inputData?.securityAns,
      };
    } else if (recoveryType === "Email") {
      signUpData = {
        ...signUpData,
        email_id: inputData?.securityAns,
      };
    } else if (recoveryType === "Phone Number") {
      signUpData = {
        ...signUpData,
        mobile_no: inputData?.securityAns,
      };
    }
    createUser(signUpData);
  };

  const checkUserNameAvailabilty = async (name) => {
    await call(
      {
        ...CHECK_USER_NAME_AVAILABILITY,
        url: CHECK_USER_NAME_AVAILABILITY.url,
      },
      { user_name: name }
    )
      .then((res) => {
        if (res?.data?.message === "duplicate") {
          setErrorObj({
            ...errorObj,
            ["user_name"]: "username already exists",
          });
        } else {
          setErrorObj({ ...errorObj, ["user_name"]: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSecurityQuestions = async () => {
    dispatch(setLoading(true));
    await call({
      ...GET_SECURITY_QUESTION_LIST,
      url: GET_SECURITY_QUESTION_LIST.url,
    })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          setSecurityQuestionList(res?.data?.data?.results);
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
  };

  const createUser = async (signUpData) => {
    dispatch(setLoading(true));
    await call(
      {
        ...CREATE_USER,
        url: CREATE_USER.url,
      },
      signUpData
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          if (res?.data?.error === "true") {
            setError(res?.data?.message);
          } else {
            setShowAlert(false);
            setTimeout(() => {
              setShowAlert(false);
              navigate("/guidelines", true);
            }, 1000);
          }
        } else {
          setError(res?.data?.message);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
        setError(err?.message);
      });
  };

  const handleChange = (e) => {
    if (e.target.name === "user_name") {
      checkUserNameAvailabilty(e.detail.value);
    }
    setInputData({ ...inputData, [e.target.name]: e.detail.value });
    setErrorObj({ ...errorObj, [e.target.name]: false });
  };

  const navigate = async (path, flag) => {
    dispatch(setLoginModal(flag));
    setTimeout(() => {
      history.push(path);
    }, 200);
  };

  const handleToggelPassword = (name) => {
    name === "cnfPwd"
      ? setToggleCnfPassword((prev) => !prev)
      : setTogglePassword((prev) => !prev);
  };

  useEffect(() => {
    getSecurityQuestions();
  }, []);

  return (
    <div className="signup-container sign-up ion-page">
      <div className="signup-main-container ion-padding">
        <IonImg
          src={process.env.PUBLIC_URL + "/images/LoginLogo.png"}
          alt="texchnage"
          className="logo"
        ></IonImg>
        <div className="signup-header-text">
          <h1 className="heading">
            Hi!
            <IonImg
              src={process.env.PUBLIC_URL + "/images/handEmoji.png"}
              alt="handEmoji"
              className="handEmojiImg"
            />
          </h1>
          <p>Create Your Account</p>
        </div>
        <div>
          {SIGNUP_INPUT_FIELDS?.map(
            ({ name, icon, id, label, placeholder, type, value }) => (
              <div key={id}>
                <IonItem
                  fill="outline"
                  className="ion-input signup-ion-input-bg ion-margin-vertical"
                >
                  <IonInput
                    type={type}
                    name={name}
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    onIonChange={(e) => handleChange(e)}
                  />
                  <IonIcon
                    icon={icon}
                    className="hideeye-icon"
                    onClick={() => handleToggelPassword(name)}
                  />
                </IonItem>
                <IonLabel size="small" color="danger">
                  {errorObj[name] && errorObj[name]}
                </IonLabel>
              </div>
            )
          )}

          <div className="account-recovery">
            <input
              type="checkbox"
              id="recovery"
              className="recovery-checkbox"
              onChange={() => setRecovery((prev) => !prev)}
            />
            <label htmlFor="recovery">Account Recovery Options</label>
          </div>
          <div className="recovery">
            <select
              className="recovery-select"
              placeholder="Select an Option"
              value={recoveryType}
              onChange={(e) => {
                setRecoveryType(e.target.value);
                setInputData({ ...inputData, ["securityAns"]: "" });
              }}
              disabled={!recovery}
            >
              {recoveryArr?.map(({ label, value }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
          {recoveryType && recoveryType === "Security Questions" ? (
            <div>
              <div className="recovery">
                <select
                  className="recovery-select"
                  placeholder="Select an Option"
                  value={securityQuestion}
                  onChange={(e) => {
                    setSecurityQuestion(e.target.value);
                    setInputData({ ...inputData, ["securityAns"]: "" });
                  }}
                >
                  {securityQuestionList?.map((item) => (
                    <option value={item?.s_id} key={item?.s_id}>
                      {item?.question}
                    </option>
                  ))}
                </select>
              </div>
              {securityQuestion && (
                <>
                  <IonItem
                    fill="outline"
                    className="ion-input signup-ion-input-bg ion-margin-vertical"
                  >
                    <IonInput
                      type="text"
                      name="securityAns"
                      id="securityAns"
                      placeholder={securityQuestion?.question}
                      value={inputData?.securityAns || ""}
                      onIonChange={(e) => handleChange(e)}
                    />
                  </IonItem>
                  <IonLabel size="small" color="danger">
                    {errorObj["securityAns"] && errorObj["securityAns"]}
                  </IonLabel>
                </>
              )}
            </div>
          ) : (
            recoveryType && (
              <>
                <IonItem
                  fill="outline"
                  className="ion-input signup-ion-input-bg ion-margin-vertical"
                >
                  <IonInput
                    type="text"
                    name="securityAns"
                    id="securityAns"
                    placeholder={recoveryType}
                    value={inputData?.securityAns || ""}
                    onIonChange={(e) => handleChange(e)}
                  />
                </IonItem>
                <IonLabel size="small" color="danger">
                  {errorObj["securityAns"] && errorObj["securityAns"]}
                </IonLabel>
              </>
            )
          )}
          <IonLabel size="small" color="danger">
            {error && error}
          </IonLabel>
          <IonItem className="signupButton">
            <IonButton
              className="signup-btn"
              fill="clear"
              type="submit"
              onClick={() => handleSignUp()}
            >
              Sign Up
            </IonButton>
          </IonItem>
        </div>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => {
            setShowAlert(false);
            navigate("/", true);
          }}
          cssClass="my-custom-class"
          message={"Registered Sucessfully"}
          buttons={[
            {
              text: "Okay",
              id: "confirm-button",
              handler: () => {
                navigate("/guidelinespage", true);
              },
            },
          ]}
        />
        <div className="log-in-details">
          <IonButton fill="clear" className="addition-details">
            <span> Already have an account?</span>
          </IonButton>
          <IonButton
            fill="clear"
            className="login-here"
            onClick={() => {
              dispatch(setLoginModal(true));
              history.push("/");
            }}
          >
            Log in here
          </IonButton>
        </div>
      </div>
    </div>
  );
}
export default SignUp;
