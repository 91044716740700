import { Browser } from "@capacitor/browser";
import { IonContent, IonPage, useIonAlert } from "@ionic/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { call } from "../../components/api/axios";
import {
  ALL_MEETINGS,
  EDIT_USER_PROFILE,
  GET_USER_PROFILE_INFO
} from "../../components/api/endpoints";
import { persistData } from "../../components/common/commonData";
import {
  isDateChanged,
  timeDiff
} from "../../components/common/CommonServices";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { setALlMeetings, setLoading } from "../../redux/actions";
const Meetings = () => {
  let result = persistData();
  const history = useHistory();
  const { allMeetings, allMeetingsFlag } = result;
  const dispacth = useDispatch();
  const [flag, setFlag] = useState(true);
  const [meetings, setMeetings] = useState(allMeetings);
  const [initialized, setInitialized] = useState(false);
  const [completedMeetings, setCompletedMeetings] = useState(false);
  const [meetingsAvailable, setMeetingsAvailable] = useState(false);
  const alias_name = localStorage?.getItem("alias_name");
  const [alias, setAlias] = useState(false);
  const [profileData, setProfileData] = useState([]);
  let user_id = localStorage.getItem("user_id");

  const [presentAlert] = useIonAlert();

  const getAllMeetings = async () => {
    allMeetingsFlag && dispacth(setLoading(true));
    await call(ALL_MEETINGS, { userId: `${localStorage?.getItem("user_id")}` })
      .then((res) => {
        if (res?.status === 200) {
          let meetingsArray = res?.data?.data;
          let meetingAvailable = meetingsArray?.some(
            (meeting) =>
              meeting?.fileUrl === undefined &&
              !meeting?.fileUrl &&
              timeDiff(meeting?.eventTime, new Date().getTime(), "days") >= 0
          );
          let isMeetingCompleted = meetingsArray?.some(
            (meeting) =>
              meeting?.fileUrl !== undefined ||
              !isDateChanged(meeting?.eventTime, new Date().getTime()) ||
              meeting?.fileUrl
          );
          setMeetingsAvailable(meetingAvailable);
          setCompletedMeetings(isMeetingCompleted);
          dispacth(setLoading(false));
          setMeetings(meetingsArray);
          dispacth(setALlMeetings(meetingsArray));
        } else {
          dispacth(setLoading(false));
        }
      })
      .catch((err) => {
        dispacth(setLoading(false));
        console.log(err);
      });
  };

  const setIntervalCalls = async () => {
    setInitialized(true);
    await getAllMeetings();
  };

  useEffect(() => {
    getProfileInfo();
  }, []);

  useEffect(() => {
    getAllMeetings();
    let interval1 = null;
    if (!initialized && !interval1) {
      interval1 = setInterval(() => {
        setIntervalCalls();
      }, 30000);
    }
    return () => {
      clearInterval(interval1);
    };
  }, []);

  const MeetingCard = ({ eventName, eventTime, roomId }) => {
    return (
      <div className="accounts-content">
        <div className="account-content-text ml-10">
          <div className="website-name mb-5">{eventName}</div>
          <div className="last-activity">
            Start time: {moment(eventTime).format("DD-MM-YYYY |  h:mm A")}
          </div>
          {/* <div className="last-activity">Room Id: {roomId}</div> */}
        </div>
      </div>
    );
  };

  const handleJoinRoom = (roomId, isVideoEnabled) => {
    if (
      alias ||
      (alias_name !== "" &&
        alias_name !== undefined &&
        alias_name !== "undefined")
    ) {
      localStorage.setItem("meetingVideo", isVideoEnabled);
      history.push(`/group-call/${roomId}`);
    } else {
      presentAlert({
        header: "Please enter Alias Name",
        buttons: [
          {
            text: "Submit",
            handler: (data) => {
              data?.name?.length > 0 && editUserProfile(data?.name);
            },
          },
        ],
        inputs: [
          {
            placeholder: "Name",
            name: "name",
            type: "text",
          },
        ],
      });
    }
  };
  const getProfileInfo = async () => {
    await call(
      {
        ...GET_USER_PROFILE_INFO,
        url: GET_USER_PROFILE_INFO?.url,
      },
      { user_id: `${localStorage?.getItem("user_id")}` }
    )
      .then((res) => {
        if (res?.status === 200) {
          let results = res?.data?.data?.result;
          setProfileData(results);
          console.log(results,"resultsresultsresults");
          localStorage.setItem("alias_name", results?.user_alias );
        } else {
          console.log(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editUserProfile = async (aliasName) => {
    let editProfilePayload = {
      user_id: user_id,
      mobile_no: profileData?.mobile_no,
      email_id: profileData?.emailId,
      user_name_full: profileData?.user_name_full,
      skype_id: profileData?.skype_id,
      watsapp_no: profileData?.watsapp_no,
      user_alias: aliasName,
    };

    await call(
      {
        ...EDIT_USER_PROFILE,
        url: EDIT_USER_PROFILE.url,
      },
      editProfilePayload
    )
      .then((res) => {
        if (res?.status === 200) {
          // window.location.reload("")
          setAlias(true);
          // getAllMeetings()
          if (res?.data?.error === "true") {
            console.log(res?.data?.message);
          }
        } else {
          console.log(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openCapacitorSite = async (capUrl) => {
    if (!(capUrl.match("http://") || capUrl.match("https://"))) {
      capUrl = "http://" + capUrl;
    }
    //window.open(capUrl,'_system');
    await Browser.open({ url: capUrl });
  };
  return (
    <IonPage className="background">
      <IonContent className="background-none">
        <div className="accounts-container">
          <div className="account-header-container">
            <div>
              <Header title="Meetings" />
            </div>
            <div className="account-header">
              <div
                className={`${flag ? "active" : ""}`}
                onClick={() => setFlag(true)}
              >
                Upcoming
              </div>
              <div
                className={`${flag ? "" : "active"}`}
                onClick={() => setFlag(false)}
              >
                Completed
              </div>
            </div>
          </div>

          <div className="account-container-scroll">
            {flag ? (
              meetingsAvailable ? (
                meetings?.length &&
                meetings?.map(
                  (
                    { eventName, roomId, eventTime, fileUrl, isVideoEnabled },
                    index
                  ) => {
                    return (
                      <div
                        className="your-accounts-container meeting-card"
                        key={index}
                      >
                        {fileUrl === undefined &&
                          !fileUrl &&
                          timeDiff(eventTime, new Date().getTime(), "days") >=
                            0 && (
                            <div className="accounts-box">
                              <MeetingCard
                                eventName={eventName}
                                eventTime={eventTime}
                                roomId={roomId}
                              />

                              {timeDiff(
                                eventTime,
                                new Date().getTime(),
                                "minutes"
                              ) <= 15 ? (
                                <div
                                  onClick={() => {
                                    handleJoinRoom(roomId, isVideoEnabled);
                                  }}
                                  className="create-id"
                                >
                                  Join
                                </div>
                              ) : (
                                <div className="create-id">Yet to start</div>
                              )}
                            </div>
                          )}
                      </div>
                    );
                  }
                )
              ) : (
                <div className="transaction-no-data-found">No Data Found</div>
              )
            ) : null}
            {!flag ? (
              completedMeetings ? (
                meetings?.length &&
                meetings?.map(
                  ({ eventName, roomId, eventTime, fileUrl }, index) => {
                    return (
                      <div
                        className="your-accounts-container meeting-card"
                        key={index}
                      >
                        {(fileUrl !== undefined ||
                          fileUrl ||
                          !isDateChanged(eventTime, new Date().getTime())) &&
                          timeDiff(eventTime, new Date().getTime(), "days") <
                            0 && (
                            <div className="accounts-box">
                              <MeetingCard
                                eventName={eventName}
                                eventTime={eventTime}
                                roomId={roomId}
                              />
                              <div className="create-id">Completed</div>
                            </div>
                          )}
                      </div>
                    );
                  }
                )
              ) : (
                <div className="transaction-no-data-found">No Data Found</div>
              )
            ) : null}
          </div>
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default Meetings;
