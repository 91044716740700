  // Import stylesheets
import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions";
import { IonContent, IonPage } from "@ionic/react";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import crypto from "crypto";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import MatchPosition from "../../pages/video-call-matchposition/MatchPosition";
import VideoCallButtons from "../../pages/video-call-matchposition/VideoCallButtons";
import { setLoading } from "../../redux/actions";
import {
  setMeetingStartStatus,
  setMinimize
} from "../../redux/actions/dataActions";
import { call } from "../api/axios";
import { GENERATE_TOKEN } from "../api/endpoints";
import Header from "../header";
import notifications from "./Notifications.ts";
import "./styles.css";
import defaultImg from '../../pages/video-call-matchposition/assents/calling_profile_image.png'
import { persistData } from "../common/commonData";
let result = persistData();

class GroupCall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableDataShow:false,
      isAudioOn:false,
      meetingInputs:{
        audio:true,
        video:true
      }
    }
  // Create a new MutationObserver instance and bind the callback function to `this`
  this.observer = new MutationObserver(this.handleMutation);
  this.audio = new MutationObserver(this.handleAudioMutation) ;
  this.video="";
  this.realTimeAudio="";
  this.onGoingMeeting="";
  this.realTimeVideo="";

  // Define the options for the MutationObserver
  this.options = {
    childList: true,  // Listen for changes to the child nodes of the target node
    subtree: true,    // Listen for changes to the entire subtree of the target node
  };
  }
  roomId = this.props.match.params.id;
  appDiv;
  zp;
  systemReq;
  devicesInfo;
  signatureNonce = crypto.randomBytes(8).toString("hex");
  meetingVideo = localStorage?.getItem("meetingVideo");
  async componentDidMount() {
    if (this.zp) {
      this.zp.destroy();
    }
    this.props.setMeetingStartStatus(true);
    if (
      !AndroidPermissions.hasPermission(AndroidPermissions.PERMISSION.CAMERA)
    ) {
      await AndroidPermissions.requestPermission(
        AndroidPermissions.PERMISSION.CAMERA
      );
    }
    if (
      !AndroidPermissions.hasPermission(
        AndroidPermissions.PERMISSION.RECORD_AUDIO
      )
    ) {
      await AndroidPermissions.requestPermission(
        AndroidPermissions.PERMISSION.RECORD_AUDIO
      );
    }
    
  }
  handleAudioMutation = () => {
    let elemToObserve = document.getElementById('audio_video_div')?.childNodes[0];
    const that = this;
    const {allMeetings} = result
    const currentMeeting = allMeetings.filter((item=>item.roomId === this.roomId))?.[0]||{}
    if(!document.getElementsByClassName('invite-img-div')[0]){
      const imageDiv = document.createElement("div") 
      imageDiv.className = "invite-img-div"
      const img =  document.createElement("img")
      img.className = "meeting-avator"
      img.src = currentMeeting.callerImg ? `https://texch-images.s3.eu-west-1.amazonaws.com/profile-images/${currentMeeting.callerImg}` : defaultImg
      imageDiv.appendChild(img)
      const pTag =  document.createElement("p")
      pTag.className = "meeting-invitor-div"
      pTag.innerText = `${currentMeeting.createdBy || "unknown"}`
      imageDiv.appendChild(pTag)
      const pTag2 =  document.createElement("p")
      pTag2.className = "meeting-invitor-call"
      pTag2.innerText = `Inviting for video call...`
      imageDiv.appendChild(pTag2)
      document.getElementById('audio_video_div')?.parentNode.appendChild(imageDiv)
    }
    // this.setState({meetingInputs:{...this.state.meetingInputs, audio:true}})

    this.audio=  new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
           if(mutation.attributeName == "class"){
            if(that.state.meetingInputs.audio) {
              that.setState({meetingInputs:{...that.state.meetingInputs, audio:false}})
              document.getElementById('audio_call_on')?.setAttribute("id","audio_call_off")
            }else {
              that.setState({meetingInputs:{...that.state.meetingInputs, audio:true}})
              document.getElementById('audio_call_off')?.setAttribute("id","audio_call_on")
            }
           }
       });
   });
   if(elemToObserve!=null) {
   this.audio.observe(elemToObserve, {attributes: true});
   }
  }
  handleVideoMutation = () => {
    let element =  document.getElementById('audio_video_div')?.childNodes;
    let elemToObserve = element?.length == 3?element[2]:element?.length ==2?element[1]:null;
    const that =  this
    this.video= new MutationObserver(function(mutations) {
       mutations.forEach(function(mutation) {
           if(mutation.attributeName == "class"){
            if(that.state.meetingInputs.video) {
              that.setState({meetingInputs:{...that.state.meetingInputs, video:false}})

              document.getElementById('video_call_on').setAttribute("id","video_call_off")
            }else {
              that.setState({meetingInputs:{...that.state.meetingInputs, video:true}})
              document.getElementById('video_call_off')?.setAttribute("id","video_call_on")
            }
           }
       });
   });
   if(elemToObserve!=null) {
    this.video.observe(elemToObserve, {attributes: true});
   }
 
  }
  handleMutation = (mutationsList) => {
    // Perform some action when the observed elements change
    
    if( this.meetingVideo.includes("Y") && !mutationsList[0]?.nextSibling?.children[1]?.getAttribute("id")) {
      mutationsList[0]?.nextSibling?.setAttribute("id","audio_video_div");
    mutationsList[0]?.nextSibling?.children[1]?.setAttribute("id","video_call_on")
    mutationsList[0]?.nextSibling?.children[0]?.setAttribute("id","audio_call_on");
      this.handleAudioMutation();
      this.handleVideoMutation();
    }else if(!this.meetingVideo.includes("Y") && !mutationsList[1]?.nextSibling?.children[1]?.getAttribute("id")){
      mutationsList[1]?.nextSibling?.setAttribute("id","audio_video_div");
      mutationsList[1]?.nextSibling?.children[0]?.setAttribute("id","audio_call_on");
      this.handleAudioMutation();
    }
   
 
    // this.setState({previousSate});
    // console.log("meeting",document.getElementById("group-call")?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[1]?.childNodes[0]);
    // let index = 1;
    // if(document.getElementById("audio_video_div")?.childNodes?.length ==3) {
    //   index = 2;
    // }
    // console.log("previousSate?.nextSibling?.children",previousSate?.nextSibling?.children);
    // if( document.getElementById("group-call")?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[1]?.childNodes[index]?.classList[0] !== previousSate?.nextSibling?.children[index]?.classList[0]){
    //   if( document.getElementById("video_call_off")) {
    //     document.getElementById("video_call_off").setAttribute("id","video_call_on")
    //   }else {
    //     document.getElementById("video_call_on").setAttribute("id","video_call_off")
    //   }
    // }
    // console.log(document.getElementById("group-call")?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[2]?.childNodes[0]);
    // console.log(previousSate?.nextSibling?.children[0]?.classList,"#####");
    // console.log("this.state.isAudioOn",this.state.isAudioOn);
    // if(!this.state.isAudioOn) {
    //   console.log("if executing",this.state.isAudioOn);
    //   mutationsList[0]?.nextSibling?.children[0]?.setAttribute("id","audio_call_on")
    //   this.setState({
    //     isAudioOn:true
    //   })
    // }else {
    //   console.log("else executing",this.state.isAudioOn);
    //   mutationsList[0]?.nextSibling?.children[0]?.setAttribute("id","audio_call_off")
    //   this.setState({
    //     isAudioOn:false
    //   })
    // }

    // if(document.getElementById("group-call")?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[2]?.childNodes[0]?.classList[0] !== previousSate?.nextSibling?.children[0]?.classList[0]){
    //  let audioOnOrOff = 'audio_call_off';
    //   if(document.getElementById("audio_call_off")){
    //     audioOnOrOff = 'audio_call_on'
    //   }
    //   mutationsList[0]?.nextSibling?.children[0]?.setAttribute("id",audioOnOrOff)
    // }
    // const voiceCallClass = document.getElementById("audio_call_on")?.classList[0]
    // this.setState({voiceCallClass})
    // console.log(voiceCallClass,this.state.voiceCallClass,"voiceCallClass");
    // if(this.state.voiceCallClass && voiceCallClass!==this.state.voiceCallClass){
    //   const micInput = mutationsList[0]?.nextSibling?.children[0].getAttribute('id') == "audio_call_on" ? "audio_call_off":"audio_call_on"
    //   console.log(micInput,document.getElementById("audio_call_on"),"micInput$$$$$");
    //   this.setState({micInput})
    //   document.getElementById(micInput!=="audio_call_on" ? "audio_call_on":"audio_call_off").setAttribute("id",micInput)
    //   // mutationsList[0]?.nextSibling?.children[0]?.setAttribute("id",micInput)
    //   console.log(micInput,mutationsList[0]?.nextSibling?.children[0],"micInput$$$$$");
    // }

  }
  componentWillUnmount() {
    if (!this.props.isMinimize && this.zp) {
      this.props.setMeetingStartStatus(false);
      this.zp.destroy();
    }
    this.appDiv = document.getElementById(`group-call`);
    this.appDiv?.classList?.remove("pre-join-meeting")
    this.appDiv?.classList?.remove("meeting-started")
  }

  handleBack = () => {
    this.props.history.push('/')
  };
  componentDidUpdate(prevPros, prevState) {
    if (this.props.isMeetingEnable && !prevPros.isMeetingEnable) {
      this.appDiv = document.getElementById(`group-call`);
      this.appDiv.innerHTML = ``;
      this.appDiv?.classList?.add("pre-join-meeting")
      // if(this.state.isTableDataShow && this.appDiv.children){
      //   const child  = document.getElementById('video_call_controlls')
      //   this.appDiv.appendChild(child);
      // }
      const targetElement = document.getElementById('group-call');
      this.setState({targetElement})
    this.observer.observe(targetElement, this.options);
      this.init();
      notifications.schedule(0, 30);
      //this.handleBack();
    }
    
    // console.log()
  }

  async generateToken04() {
    return call(GENERATE_TOKEN, {
      userId: `${localStorage?.getItem("user_id")}`,
      roomId: this.roomId,
      type: "generate_token",
      userType:'user',
      callUserId:`${localStorage?.getItem("user_id")}`+""+this.roomId
    });
  }

  getUrlParams(url) {
    let urlStr = url.split("?")[1];
    const urlSearchParams = new URLSearchParams(urlStr);
    const result = Object.fromEntries(urlSearchParams.entries());
    return result;
  }
  handleRealTimeAudioMutation = () => {

    let elemToObserve = document.getElementById('r_audio_video_div')?.childNodes[this.meetingVideo.includes("Y")?2:0];
    const that = this;
    this.realTimeAudio= new MutationObserver(function(mutations) {
      
       mutations.forEach(function(mutation) {
           if(mutation.attributeName == "class" ){
             if(that.state.meetingInputs.audio) {
              that.setState({meetingInputs:{...that.state.meetingInputs, audio:false}})
              document.getElementById('r_audio_call_on')?.setAttribute("id","r_audio_call_off")
            }else if(!that.state.meetingInputs.audio) {
              that.setState({meetingInputs:{...that.state.meetingInputs, audio:true}})
              document.getElementById('r_audio_call_off')?.setAttribute("id","r_audio_call_on")
            }
           }
       });
   });
   this.realTimeAudio.observe(elemToObserve, {attributes: true});
  }
    handleRealTimeVideoMutation = () => {
    let elemToVideoObserve = document.getElementById('r_audio_video_div')?.childNodes[1];
    const that = this;
    this.realTimevideo= new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        if(mutation.attributeName == "class" ){
            if(document.getElementById('r_video_call_on')) {
              that.setState({meetingInputs:{...that.state.meetingInputs, video:false}})
              document.getElementById('r_video_call_on')?.setAttribute("id","r_video_call_off")
            }else {
              that.setState({meetingInputs:{...that.state.meetingInputs, video:true}})
              document.getElementById('r_video_call_off')?.setAttribute("id","r_video_call_on")
            }
           }
       });
   });
   this.realTimevideo.observe(elemToVideoObserve, {attributes: true});
  }
  // handleRealTimeVideoMutation = () => {
  //   let elemToObserve = document.getElementById('r_audio_video_div')?.childNodes[1];
  //   // const previousAudioData= document.getElementsByClassName("unmuteVideo")[0]?.nextElementSibling?.nextElementSibling?.classList?.length
  //   this.realTimeVideo= new MutationObserver(function(mutations) {
  //      mutations.forEach(function(mutation) {
  //          if(mutation.attributeName == "class" ){
  //           console.log("attributeName",elemToObserve);
  //           if(document.getElementById('r_audio_call_on')) {
  //             document.getElementById('r_audio_call_on').setAttribute("id","r_audio_call_off")
  //           }else {
  //             document.getElementById('r_audio_call_off')?.setAttribute("id","r_audio_call_on")
  //           }
  //          }
  //      });
  //  });
  //  this.realTimeVideo.observe(elemToObserve, {attributes: true});
  // }
  handleOnGoingMeetingMutation = () => {
    let elemToObserve = document.getElementById(this.meetingVideo.includes("Y") ?'video-call-div':'audio-call-div');
    const that = this;
    this.onGoingMeeting= new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) { 
        const indexNode = that.meetingVideo.includes("Y") && that.state.meetingInputs.video ? 1 : 0; 
           if(that.meetingVideo.includes("Y") && document.getElementById('video-call-div')?.childNodes[indexNode]?.childNodes[2]){

            if(!document.getElementById('r_audio_call_on') &&  !document.getElementById('r_audio_call_off')) {
              document.getElementById('video-call-div')?.childNodes[indexNode]?.childNodes[2]?.setAttribute("id",'r_audio_call_on');
              document.getElementById('video-call-div')?.childNodes[indexNode]?.setAttribute("id","r_audio_video_div")
              if(that.state.meetingInputs.audio ){
                that.setState({meetingInputs:{...that.state.meetingInputs, audio:true}})
              document.getElementById('video-call-div')?.childNodes[indexNode]?.childNodes[2]?.setAttribute("id",'r_audio_call_on');
              } else  {
                that.setState({meetingInputs:{...that.state.meetingInputs, audio:false}})
              document.getElementById('video-call-div')?.childNodes[indexNode]?.childNodes[2]?.setAttribute("id",'r_audio_call_off');
              }
              that.handleRealTimeAudioMutation();
            }
           }
           else if(that.meetingVideo.includes("Y")==false  && document.getElementById('audio-call-div')?.childNodes[indexNode]?.childNodes[0]){
            document.getElementById('audio-call-div')?.childNodes[indexNode]?.childNodes[0]?.setAttribute("id",'r_audio_call_on');
            document.getElementById('audio-call-div')?.childNodes[indexNode]?.setAttribute("id","r_audio_video_div")
            if(that.state.meetingInputs.audio ){
              that.setState({meetingInputs:{...that.state.meetingInputs, audio:true}})
              document.getElementById('audio-call-div')?.childNodes[indexNode]?.childNodes[0]?.setAttribute("id",'r_audio_call_on');
            } else  {
              that.setState({meetingInputs:{...that.state.meetingInputs, audio:false}})
              document.getElementById('audio-call-div')?.childNodes[indexNode]?.childNodes[0]?.setAttribute("id",'r_audio_call_off');
            }
            that.handleRealTimeAudioMutation();
           }
           if( that.meetingVideo.includes("Y")  && document.getElementById('video-call-div')?.childNodes[indexNode]?.childNodes[1]){
            if(!document.getElementById('r_video_call_on') &&  !document.getElementById('r_video_call_off')) {
              document.getElementById('video-call-div')?.childNodes[indexNode]?.childNodes[1].setAttribute("id",'r_video_call_on');
              document.getElementById('video-call-div')?.childNodes[indexNode]?.setAttribute("id","r_audio_video_div")
              if(document.getElementById('video-call-div')?.childNodes[indexNode]?.childNodes[1]?.length){
                const previousAudioIDValue =  document.getElementById('video-call-div')?.childNodes[1]?.childNodes[1]?.getAttribute()
                this.setState({previousAudioIDValue})
              }
              if(that.state.meetingInputs.video ){
                that.setState({meetingInputs:{...that.state.meetingInputs, video:true}})
              document.getElementById('video-call-div')?.childNodes[indexNode]?.childNodes[1]?.setAttribute("id",'r_video_call_on');
              } else  {
                that.setState({meetingInputs:{...that.state.meetingInputs, video:false}})
              document.getElementById('video-call-div')?.childNodes[indexNode]?.childNodes[1]?.setAttribute("id",'r_video_call_off');
              }
              that.handleRealTimeVideoMutation();
            }
           }
       });
   });
   this.onGoingMeeting.observe(elemToObserve,  this.options);
  }
  async init() {
    const secret = "adc8991cbf43c596b04fb2281bcf4ff0";
    const appId = 1415017287;
    const payloadObject = {
      room_id: this.roomId,
      privilege: {
        1: 1, // loginRoom: 1 pass , 0 not pass
        2: 0, // publishStream: 1 pass , 0 not pass
      },
      stream_id_list: null,
    }; //
    const payload = JSON.stringify(payloadObject);
    let result = await this.generateToken04(appId, secret, 10000, payload);
    let kitToken = ZegoUIKitPrebuilt.generateKitTokenForProduction(
      +result?.data?.appId,
      result?.data?.token,
      this.roomId,
      localStorage?.getItem("user_id")+""+this.roomId,
      localStorage?.getItem("alias_name"),
    );
    this.zp = ZegoUIKitPrebuilt.create(kitToken);
    // this.systemReq = await this.zp.checkSystemRequirements();

    let userSettings = this.meetingVideo.includes("Y")
    // this.devicesInfo = await this.zp.enumDevices();
    this.zp.joinRoom({
      container: this.appDiv,
      maxUsers: 500,
      facingMode: "user",
      turnOnCameraWhenJoining: userSettings,
      showMyCameraToggleButton: userSettings,
      showAudioVideoSettingsButton: userSettings,
      // showScreenSharingButton: userSettings,
      showOnlyAudioUser:false,
      showNonVideoUser:false,
      showScreenSharingButton:true,
      // sharedLinks: [
      //   {
      //     name: "Personal link",
      //     url:
      //       window.location.origin +
      //       window.location.pathname +
      //       "?roomID=" +
      //       this.roomId,
      //   },
      // ],
      scenario: {
        mode: ZegoUIKitPrebuilt.VideoConference, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
        config: {
          VideoConference: {
            role: "Cohost",
          },
        },
      },
      showPreJoinView: true,
      showLayoutButton: false,
      showUserList: false,
      onJoinRoom: async (users) => {
        // console.warn("============================", users);
        this.props.setMinimize(true);
        this.setState({isTableDataShow:true})
        this.appDiv = document.getElementById(`group-call`);
        this.appDiv?.classList?.remove("pre-join-meeting")
        this.appDiv?.classList?.add("meeting-started")
        const parent  = document.getElementsByClassName('ZegoRoomMobile_ZegoRoom')[0]
      
        if(parent){
        if(this.meetingVideo.includes("Y")){
          parent?.classList?.add('video-call-div');
          parent?.setAttribute("id",'video-call-div');
          this.handleOnGoingMeetingMutation();
        } else {
          parent?.classList?.add('audio-call-div');
          parent?.setAttribute("id",'audio-call-div');
       //   document.getElementById("audio-call-div")?.childNodes[1]?.childNodes[0].setAttribute("id",'r_audio_call_on');
        this.handleOnGoingMeetingMutation();
            //  this.handleRealTimeAudioMutation();
        
        }
      }
        // const audioCallEnable = document.getElementsByClassName("ZegoRoomMobile_ZegoRoom")[0]?.children[0]?.children?.length
      },
      onLeaveRoom: (users) => {
        // console.warn("============================", users);
        this.props.setMeetingStartStatus(false);
        this.props.setMinimize(false);
        this.zp.destroy();
        alert("Session closed")

        if (window.location.hash.includes("group-call")) {
          this.handleBack();
        }
      },
      onUserJoin: (users) => {
        // console.warn("============================", users);
      },
      onUserLeave: (users) => {
        // console.warn("============================", users);
      },
      preJoinViewConfig: {
        title: "Welcome",
      },
      layout: "Sidebar",
    });
  }
  GenerateUASignature(appId, signatureNonce, serverSecret, timeStamp) {
    const hash = crypto.createHash("md5"); //Use the MD5 hashing algorithm.
    var str = appId + signatureNonce + serverSecret + timeStamp;
    hash.update(str);
    //hash.digest('hex') indicates that the output is in hex format
    return hash.digest("hex");
  }

  //Use the AppID and ServerSecret of your project.
  stopRecord(taskId, roomId, signature, timeStamp) {
    let obj = {
      TaskId: taskId,
    };
    let url = `https://cloudrecord-api.zego.im/?Action=StopRecord&AppId=${1415017287}&SignatureNonce=${
      this.signatureNonce
    }&Timestamp=${timeStamp}&Signature=${signature}&SignatureVersion=2.0&IsTest=false&ServerSecret=adc8991cbf43c596b04fb2281bcf4ff0`;
    fetch(url, {
      method: "POST",
      body: JSON.stringify(obj),
    }).then((res) => {
      return res.clone().json();
    });
  }
  async startRecord(roomId, signature, timeStamp) {
    let obj = {
      RoomId: this.roomId,
      RecordInputParams: {
        RecordMode: 2,
        StreamType: 3,
        MaxIdleTime: 60,
        MixConfig: {
          MixMode: 2,
          MixOutputStreamId: "mix",
          MixOutputVideoConfig: {
            Width: 1280,
            Height: 720,
            Fps: 15,
            Bitrate: 1130000,
          },
        },
      },
      RecordOutputParams: {
        OutputFileFormat: "mp4",
        OutputFolder: "record/",
      },
      StorageParams: {
        Vendor: 1,
        Region: "eu-west-1",
        Bucket: "zego-call-recordings",
        AccessKeyId: "AKIAWOCAITZ32J7B7NN5",
        AccessKeySecret: "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",
      },
    };
    let url = `https://cloudrecord-api.zego.im/?Action=StartRecord&AppId=${1415017287}&SignatureNonce=${
      this.signatureNonce
    }&Timestamp=${timeStamp}&Signature=${signature}&SignatureVersion=2.0&IsTest=false&ServerSecret=adc8991cbf43c596b04fb2281bcf4ff0`;
    let data = await fetch(url, {
      method: "POST",
      body: JSON.stringify(obj),
    }).then((res) => {
      return res.clone().json();
    });
    return data;
  }
  render() {
    const {allMeetings} = result
    const currentMeeting = allMeetings.filter((item=>item.roomId === this.roomId))?.[0]||{}
    return (
      <IonPage className={`background ${currentMeeting.eventName !=="personal meeting" ? "background-meeting":"normal-meeting"} ${(this.state.isTableDataShow && currentMeeting.eventName !=="personal meeting")? "meeting-started-background":""}`}>
        <IonContent className="background-none">
          <div className="accounts-container">
            <div className="account-header-container">
              <Header title="Meetings" cssClass ="" />
        {this.state.isTableDataShow && currentMeeting.eventName !=="personal meeting"&&
          <MatchPosition  />}
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setToken: (data) => dispatch(setLoading(data)),
    setMeetingStartStatus: (data) => dispatch(setMeetingStartStatus(data)),
    setMinimize: (data) => dispatch(setMinimize(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    isMeetingEnable: state?.data?.isMeetingStarted,
    isMinimize: state?.data?.isMinimize,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GroupCall));
