

import React, { useState } from "react";
import {
  IonPage,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  IonAlert,
} from "@ionic/react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import { call } from "../../../components/api/axios";
import { UPADATE_NOTIFICATION } from "../../../components/api/endpoints";
import { useHistory } from "react-router-dom";
import { chevronDownOutline } from "ionicons/icons";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/actions";

const UpdateNotifcations = () => {
  const updateType = [
    {
      type: "Email",
      contact_id: 1,
    },
    {
      type: "SMS",
      contact_id: 2,
    },
    {
      type: "WhatsApp",
      contact_id: 3,
    },
  ];
  const history = useHistory();
  const dispatch = useDispatch()

  const [errMsg, setErrMsg] = useState("");
  const [error, setError] = useState(false);

  const [notificationType, setNotificationType] = useState([]);
  const [notificationTypeErr, setNotificationTypeErr] = useState(false);
  const [notificationDropDown, setNotificationDropDown] = useState(false);

  const [contact, setContact] = useState("");
  const [contactErr, setContactErr] = useState("");

  const [showAlert, setShowAlert] = useState(false);

  const handleTransactionDropDown = () => {
    setNotificationDropDown(!notificationDropDown);
  };

  const handleNotificationType = (transaction) => {
    setNotificationType(transaction);
    setNotificationTypeErr(false);
    setNotificationDropDown(false);
  };

  const handleUpdateProfile = () => {
    if (notificationType?.length === 0) {
      setErrMsg("Select notification type");
      setNotificationTypeErr(true);
      return;
    }

    if (contact.trim() === "" || contact?.length === 0) {
      setErrMsg(
        `Please enter ${notificationType?.contact_id === 1 ? "email" : "contact number"
        }`
      );
      setContactErr(true);
      return;
    }

    updateNotification();
  };

  const updateNotification = async () => {
    let notificationPayload = {
      user_id: `${localStorage?.getItem("user_id")}`,
      contact_on: contact,
      contact_type: notificationType?.contact_id,
    };

    dispatch(setLoading(true))

    await call(
      {
        ...UPADATE_NOTIFICATION,
        url: UPADATE_NOTIFICATION.url,
      },
      notificationPayload
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false))
          if (res?.data?.error === "true") {
            setError(true);
            setErrMsg(res?.data?.message);
          } else {
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 1000)
            // history.push("/notifications");
          }
        } else {
          console.log(res);
          dispatch(setLoading(false))
          setErrMsg(res?.data?.message);
          setError(true);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false))
        console.log(err);
        setErrMsg(err?.message);
        setError(true);
      });
  };
  return (
    <IonPage className="background">
      <div className="account-header-container">
        <Header title={`NOTIFY BY`} />
      </div>
      <div className="support-home edit-profile-container">
        <div className="form-conatiner">
          <div
            className="input-dropdown login-input-field transaction-input add-account-form"
            style={{
              borderBottom: notificationDropDown
                ? "3px solid #a4930a"
                : "2px solid #a4930a",
            }}
            onClick={() => handleTransactionDropDown()}
          >
            <div>
              {notificationType?.type !== "" ? (
                <div>
                  <div className="referred-selected-value">NOTIFY BY</div>
                  <div>{notificationType?.type}</div>
                </div>
              ) : (
                "Transaction Type"
              )}
            </div>
            <div>
              <IonIcon icon={chevronDownOutline} />
            </div>
          </div>
          {notificationDropDown ? (
            <div className="login-input-field reffered-by-values w-86 transaction-input-field add-account-content">
              {updateType?.map((notification, index) => (
                <div key={index}>
                  <div
                    className="reffered-by-value"
                    name="refferdBy"
                    onClick={() => handleNotificationType(notification)}
                  >
                    {notification?.type}
                  </div>
                  <hr className="referred-horizontal-line" />
                </div>
              ))}
            </div>
          ) : null}
          <div
            hidden={!notificationTypeErr}
            className="error-message error-message-margin"
            style={{ marginTop: "5px" }}
          >
            {errMsg}
          </div>
        </div>
        <div>
          <IonItem className={`mb-5 mt-20 transaction-input`}>
            <IonLabel position="floating">CONTACT ME ON</IonLabel>
            <IonInput
              placeholder={`Enter Your ${notificationType?.contact_id === 1 ? "Email" : "Number"
                }`}
              type={`${notificationType?.contact_id === 1 ? "email" : "number"}`}
              name="userName"
              onIonChange={(e) => {
                setContact(e.target.value);
                setContactErr(false);
              }}
              value={contact}
              clearInput
            ></IonInput>
          </IonItem>
          <div
            hidden={!contactErr}
            className="error-message error-message-margin"
          >
            {errMsg}
          </div>
          <div className="mt-40"></div>
          <div className="login-btn mt-40 mb-5" style={{ width: "100%" }}>
            <IonButton onClick={() => handleUpdateProfile()}>UPDATE</IonButton>
          </div>
          <div hidden={!error} className="error-message error-message-margin">
            {errMsg}
          </div>
        </div>
      </div>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => {
          setShowAlert(false);
          history.push("/notifications");
        }}
        cssClass="my-custom-class"
        subHeader={"Notification Updated Sucessfully"}
      />
      <Footer />
    </IonPage>
  );
};

export default UpdateNotifcations;

