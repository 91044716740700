import {
  IonAlert,
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage
} from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { call } from "../../components/api/axios";
import {
  FORGOT_PASSWORD,
  GET_SECURITY_QUESTION_LIST
} from "../../components/api/endpoints";
import { setLoading } from "../../redux/actions";
import { setLoginModal } from "../../redux/actions/dataActions";
import "./styles.css";

function ForgotPassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [recoveryDropDown, setRecoveryDropDown] = useState(false);
  const [securityDropDown, setSecurityDropDown] = useState(false);
  const [error, setError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [username, setUsername] = useState("");
  const [userNameError, setUserNameError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassWord, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [recoveryOption, setRecoveryOption] = useState("");
  const [recoveryOptionError, setRecoveryOptionError] = useState(false);
  const [recoveryOptionValue, setRecoveryOptionValue] = useState("");
  const [recoveryOptionValueError, setRecoveryOptionValueError] =
    useState(false);
  const [securityQuestion, setSecurityQuestion] = useState({});
  const [securityQuestionError, setSecurityQuestionError] = useState(false);
  const [securtiyAnsError, setSecurityAnsError] = useState(false);

  const [errMsg, setErrMsg] = useState("");

  const [securityQuestionList, setSecurityQuestionList] = useState([]);

  const handleRecoveryValue = (value) => {
    setRecoveryOptionValue("");
    setRecoveryOptionError(false);
    setRecoveryOption(value);
    setRecoveryDropDown(false);
  };

  const handleSecurityValue = (value) => {
    setSecurityQuestionError(false);
    setSecurityQuestion(value);
    setSecurityDropDown(false);
  };

  const handleForgotPassword = () => {
    if (username?.trim() === "" || username?.length === 0) {
      setErrMsg("Please enter username");
      setUserNameError(true);
      return;
    } else {
      setUserNameError(false);
    }
    if (password?.trim() === "" || password?.length === 0) {
      setErrMsg("Please enter password");
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }
    if (password?.length < 8) {
      setErrMsg("Password should be minimum 8 characters");
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }
    if (password !== confirmPassWord) {
      setErrMsg(`Password does not match please check`);
      setConfirmPasswordError(true);
      return;
    } else {
      setConfirmPasswordError(false);
    }
    if (recoveryOption?.trim() === "" || recoveryOption?.length === 0) {
      setErrMsg("Please choose recovery option");
      setRecoveryOptionError(true);
      return;
    } else {
      setRecoveryOptionError(false);
    }

    if (recoveryOption === "Security Questions") {
      if (Object.keys(securityQuestion).length === 0) {
        setErrMsg("Please choose security Question");
        setSecurityQuestionError(true);
        return;
      } else {
        setSecurityQuestionError(false);
      }
    } else {
      if (
        recoveryOptionValue?.trim() === "" ||
        recoveryOptionValue?.length === 0
      ) {
        setErrMsg("Please enter security answer");
        setRecoveryOptionValueError(true);
        return;
      } else {
        setRecoveryOptionValueError(false);
      }
    }
    if (
      recoveryOptionValue?.trim() === "" ||
      recoveryOptionValue?.length === 0
    ) {
      setErrMsg("Please enter security answer");
      setSecurityAnsError(true);
      return;
    } else {
      setSecurityAnsError(false);
    }

    let forgotPasswordData = {
      user_name: username,
      user_password: password,
    };
    if (
      recoveryOption === "Security Questions" &&
      securityQuestion &&
      Object.keys(securityQuestion).length !== 0
    ) {
      forgotPasswordData = {
        ...forgotPasswordData,
        security_q_1: securityQuestion?.s_id,
        security_a_1: recoveryOptionValue,
      };
    } else if (recoveryOption === "Email ID") {
      forgotPasswordData = {
        ...forgotPasswordData,
        email_id: recoveryOptionValue,
      };
    } else if (recoveryOption === "Phone Nubmer") {
      forgotPasswordData = {
        ...forgotPasswordData,
        mobile_no: recoveryOptionValue,
      };
    }
    forgotPasswordMethod(forgotPasswordData);
  };

  const recoveryArr = [
    { value: "Email ID" },
    { value: "Phone Nubmer" },
    { value: "Security Questions" },
  ];

  const getSecurityQuestions = async () => {
    dispatch(setLoading(true));
    await call({
      ...GET_SECURITY_QUESTION_LIST,
      url: GET_SECURITY_QUESTION_LIST.url,
    })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          setSecurityQuestionList(res?.data?.data?.results);
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
  };

  const forgotPasswordMethod = async (forgotPasswordData) => {
    dispatch(setLoading(true));
    await call(
      {
        ...FORGOT_PASSWORD,
        url: FORGOT_PASSWORD.url,
      },
      forgotPasswordData
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          if (res?.data?.error === "true") {
            setError(res?.data?.message);
          } else {
            setShowAlert(true);
          }
        } else {
          setError(res?.data?.message);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
        setError(err?.message);
      });
  };

  const handleChangeUserName = (e) => {
    setUserNameError(false);
    setUsername(e?.detail?.value);
  };
  const handleSecurityQuestionAns = (e) => {
    setRecoveryOptionValueError(false);
    setSecurityAnsError(false);
    setRecoveryOptionValue(e?.detail?.value);
  };
  const handlePassword = (e) => {
    setPasswordError(false);
    setPassword(e?.detail?.value);
  };
  const handleConfrimPassword = (e) => {
    setConfirmPasswordError(false);
    setConfirmPassword(e?.detail?.value);
  };

  useEffect(() => {
    getSecurityQuestions();
  }, []);

  const navigate = async (path, flag) => {
    dispatch(setLoginModal(flag));
    setTimeout(() => {
      history.push(path);
    }, 200);
  };

  return (
    <IonPage className="login-page-container sigunup-container">
      <div>
        <div className="signup-img-tag signup-header"></div>
        {/* <IonImg
          
          src={process.env.PUBLIC_URL + "/images/logo_horizontal.png"}
        /> */}
        <div className="signup-header signup-header-text mb-20">
          <div className="signup-create-account">Forgot</div>{" "}
          <div className="signup-create-yourself">Password?</div>
        </div>
        <IonItem className={"mb-5 mt-20"}>
          <IonLabel position="floating">Username</IonLabel>
          <IonInput
            placeholder="Pick a Username"
            name="username"
            type="text"
            onIonChange={(e) => handleChangeUserName(e)}
            defaultValue={username}
            clearInput
          ></IonInput>
        </IonItem>

        <div hidden={!userNameError} className="error-message">
          {errMsg}
        </div>
        <IonItem className="mt-20">
          <IonLabel position="floating">Create New Password</IonLabel>
          <IonInput
            placeholder="Create New Password"
            type="password"
            name="password"
            minlength={8}
            onIonChange={(e) => handlePassword(e)}
            defaultValue={password}
            clearInput
          ></IonInput>
        </IonItem>
        <div hidden={!passwordError} className="error-message">
          {errMsg}
        </div>
        <IonItem className="mt-20">
          <IonLabel position="floating">Confirm New Password</IonLabel>
          <IonInput
            placeholder="Re-Enter New Password"
            type="password"
            name="confirmPassWord"
            onIonChange={(e) => handleConfrimPassword(e)}
            defaultValue={confirmPassWord}
            clearInput
          ></IonInput>
        </IonItem>
        <div hidden={!confirmPasswordError} className="error-message">
          {errMsg}
        </div>
        <div
          className="signup-header signup-header-text mt-10 mb-10"
          style={{ fontSize: "16px" }}
        >
          <div className="signup-create-account">
            Provide answers to below Securtiy{" "}
            <span className="signup-create-yourself">
              Questions to Reset Password.
            </span>
          </div>
        </div>
        <div
          className="input-dropdown login-input-field add-account-form"
          style={{
            borderBottom: recoveryDropDown
              ? "3px solid #a4930a"
              : "2px solid #a4930a",
          }}
          onClick={() => {
            setRecoveryDropDown(!recoveryDropDown);
            setSecurityDropDown(false);
          }}
        >
          <div>
            {recoveryOption !== "" ? recoveryOption : "Select an Option"}
          </div>
          <div>
            <IonIcon icon={chevronDownOutline} />
          </div>
        </div>
        <div hidden={!recoveryOptionError} className="error-message">
          {errMsg}
        </div>
        {recoveryDropDown ? (
          <div
            className="login-input-field reffered-by-values signup-input-field w-81"
            style={{ position: "relative" }}
          >
            {recoveryArr?.map((ele, index) => (
              <div key={index}>
                <div
                  className="reffered-by-value"
                  name="recoveryOption"
                  onClick={() => handleRecoveryValue(ele?.value)}
                >
                  {ele?.value}
                </div>
                <hr className="referred-horizontal-line" />
              </div>
            ))}
          </div>
        ) : null}

        {recoveryOption === "Security Questions" && (
          <div
            className="input-dropdown login-input-field add-account-form"
            style={{
              borderBottom: securityDropDown
                ? "3px solid #a4930a"
                : "2px solid #a4930a",
              marginTop: "20px",
            }}
            onClick={() => {
              setSecurityDropDown(!securityDropDown);
              setRecoveryDropDown(false);
            }}
          >
            <div>
              {securityQuestion && Object.keys(securityQuestion).length !== 0
                ? securityQuestion?.question
                : "Choose a security question"}
            </div>
            <div>
              <IonIcon icon={chevronDownOutline} />
            </div>
          </div>
        )}
        <div hidden={!securityQuestionError} className="error-message">
          {errMsg}
        </div>
        {securityDropDown ? (
          <div
            className="login-input-field reffered-by-values signup-input-field w-81"
            style={{ position: "relative" }}
          >
            {securityQuestionList?.length > 0 &&
              securityQuestionList?.map((question) => (
                <div key={question?.s_id}>
                  <div
                    className="reffered-by-value"
                    name="securityQuestion"
                    onClick={() => handleSecurityValue(question)}
                  >
                    {question?.question}
                  </div>
                  <hr className="referred-horizontal-line" />
                </div>
              ))}
          </div>
        ) : null}
        {(recoveryOption !== "" && recoveryOption !== "Security Questions") ||
        (securityQuestion && Object.keys(securityQuestion).length !== 0) ? (
          <div>
            <IonItem className="mt-20">
              <IonLabel position="floating">{recoveryOption}</IonLabel>
              <IonInput
                placeholder={
                  recoveryOption === "Security Questions"
                    ? securityQuestion?.question
                    : recoveryOption
                }
                name="recoveryOptionValue"
                type="text"
                onIonChange={(e) => handleSecurityQuestionAns(e)}
                defaultValue={recoveryOptionValue}
                clearInput
              ></IonInput>
            </IonItem>
            <div hidden={!securtiyAnsError} className="error-message">
              {errMsg}
            </div>
            <div hidden={!recoveryOptionValueError} className="error-message">
              {errMsg}
            </div>
          </div>
        ) : (
          <div className="mt-40 mb-30"></div>
        )}
        <p className="error-message">{error ? error : null}</p>
        <div className="login-btn mt-40 mb-30">
          <IonButton onClick={() => handleForgotPassword()}>
            RESET PASSWORD
          </IonButton>
        </div>
        <div
          className="signup-now text-center mb-20 mt-20"
          onClick={() => {
            navigate("/", true);
          }}
        >
          Go Back to Login.
        </div>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => {
            setShowAlert(false);
            navigate("/", true)
          }}
          cssClass="my-custom-class"
          subHeader={"Your password has been reset successfully"}
        />
      </div>
    </IonPage>
  );
}
export default ForgotPassword;
