import React, { useState } from "react";
import { IonIcon,IonModal } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import "./styles.css";

function ThankYouModel(props) {

  return (
    <div>
      <IonModal
        isOpen={props?.successAlert}
        className="succesful-signup-model thank-you-model-container"
      >
        <div className="successful-model-content">
          <div className="thank-you-model-header">
            <IonIcon
              icon={closeOutline}
              className="thank-you-model-close-icon"
              onClick={props?.handleCancel}
            />
          </div>
          <div className="thank-you-logo">
            <img
              src={process.env.PUBLIC_URL + "/images/successFullyIcon.png"}
            />
          </div>
          <div className="thank-you-model-content-box">
            <div className="thank-yoy-model-heading mt-10">Thank You!</div>
            <div className="thank-you-model-sub-heading mb-10">
             {props?.msg !== undefined ? props?.msg : "for your transaction"}
            </div>
            <div className="thank-you-model-content">
              <div>
                Your Transaction is now being processed. We will let you know
                once the Transaction is marked as complete from master/admin and
                amount will credited to your account.
              </div>
              <div className="mt-20 mb-20">
                In the meantime you can also check the status of your Order
              </div>
              <div
                className="back-to-home mt-10 "
                onClick={props?.handleBackToHome}
              >
                Okay
              </div>
            </div>
          </div>
        </div>
      </IonModal>
    </div>
  );
}

export default ThankYouModel;
