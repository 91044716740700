import React from "react";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute: React.FC<{
  component: React.FC<any>;
  path: string;
  exact: boolean;
}> = (props) => {
  const condition: boolean = performValidationHere();
  return condition ? (
    <Route
      path={props?.path}
      exact={props?.exact}
      component={props?.component}
    />
  ) : (
    <Redirect to="/" />
  );
};
export default PrivateRoute;

function performValidationHere(): boolean {
  let token =
    localStorage.getItem("token") !== undefined &&
    localStorage?.getItem("token") !== "undefined" &&
    localStorage?.getItem("token") !== null;
  let isVerified =
    localStorage.getItem("isVerified") !== undefined &&
    localStorage?.getItem("isVerified") !== "undefined" &&
    localStorage?.getItem("isVerified") !== null;
  return token && isVerified;
}
