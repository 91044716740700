import { IonIcon } from "@ionic/react";
import {
  chevronUpCircleOutline,
  chevronDownCircleOutline,
} from "ionicons/icons";
import { useState } from "react";
import DateRange from "./DateRange";

function FancyReports() {
  const [activeIndex, setActiveIndex] = useState(false);
  const [indexPosition, setIndexPosition] = useState("");
  const [sportName, setSportName] = useState("");
  const [showSportList, setShowSportList] = useState(false);
  const handleActiveIndex = (index) => {
    setActiveIndex(!activeIndex);
    setIndexPosition(index);
  };
  const matchDetails = [
    {
      game: "Cricket",
      marketId: "262969153",
      matchName: "Chennai Super Kings vs Kolkata Knight Riders",
      comm: "Comm",
      commData: "0.00",
      netWL: "Net W/L",
      netWLData: 9.001,
    },
    {
      game: "Cricket",
      marketId: "3425272874",
      matchName: "West Indies vs Australia",
      comm: "Comm",
      commData: 0.0,
      netWL: "Net W/L",
      netWLData: -100000,
    },
  ];
  const sportsList = [
    "All",
    "Cricket",
    "Football",
    "Tennis",
    "Basketball",
    "Baseball",
  ];
  const handleSportList = (value) => {
    setSportName(value);
  };
  const handleSport = () => {
    setShowSportList((prev) => !prev);
  };

  const individualMatchDetails = [
    {
      marketName: "Spark999",
      betID: 547384993,
      placed: "06-06-2023 10:00:00",
      selection:
        "PL of Fancy: Intern..T20 Matches / CSK vs KKR/Csk 10 Over Bet: 156 Back",
      result: "CSK 10 overs 168",
      status: "Win",
      type: "Back",
      odd: 90,
      comm: "00",
      stake: 100,
      winLoss: 90,
    },
    {
      marketName: "T Exchange",
      betID: 547384993,
      placed: "06-06-2023 10:00:00",
      selection:
        "PL of Fancy: Intern..T20 Matches / CSK vs KKR/Csk 10 Over Bet: 156 Back",
      result: "CSK 10 overs 168",
      status: "Loss",
      type: "Back",
      odd: 90,
      comm: "00",
      stake: 100,
      winLoss: -90,
    },
  ];

  return (
    <div className="profit-loss-container">
      <div className="account-statement-header">Filter Type</div>
      <div
        className="flex website-dropdown mt-5 justify-start w-100"
        onClick={() => {
          handleSport();
        }}
      >
        {sportName ? sportName : "Settled"}
        <IonIcon
          className="websites-arrow"
          icon={
            showSportList ? chevronUpCircleOutline : chevronDownCircleOutline
          }
        />
        {showSportList && (
          <div className="wid-left betstatus-list">
            {sportsList.map((value, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    handleSportList(value);
                  }}
                >
                  {value}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <DateRange />
      <div className="account-statement-container">
        {matchDetails &&
          matchDetails?.map((value, index) => (
            <>
              <div
                className="flex-sp-b my-wallet-content-div mt-10"
                key={index}
              >
                <div className="flex-column w-50">
                  <span className="my-wallet-tl-ml">{value.game}</span>
                  <span className="my-wallet-tl-ml font-w300">
                    Market ID : {value.marketId}
                  </span>
                  <span className="my-wallet-tl-ml font-14">{value.matchName}</span>
                </div>
                <div className="w-15">
                  <span>{value.comm}</span>
                  <br />
                  <span>{value.commData}</span>
                </div>
                <div className="w-15">
                  <span>{value.netWL}</span>
                  <br />
                  <span
                    className={`${
                      value.netWLData >= 0 ? "profit-color" : "loss-color"
                    }`}
                  >
                    {value.netWLData}
                  </span>
                </div>
                <div className="mr-10">
                  <IonIcon
                    className="activitylog-arrow"
                    icon={
                      indexPosition === index && activeIndex
                        ? chevronUpCircleOutline
                        : chevronDownCircleOutline
                    }
                    onClick={() => handleActiveIndex(index)}
                  />
                </div>
                
              </div>
              <div>
                {activeIndex && indexPosition === index && (
                  <>
                    {individualMatchDetails &&
                      individualMatchDetails.map((value, index) => (
                        <div className="w-100 flex-sp-b fancty-report-result-div" key={index}>
                          <div className="w-30 fancy-report-result">
                            <p>Market Name : {value.marketName}</p>
                            <p>Bet ID : {value.betID}</p>
                            <p>Placed : {value.placed}</p>
                          </div>
                          <div className="w-40 fancy-report-result">
                            <p>Selection : {value.selection}</p>
                            <p>Result : {value.result}</p>
                          </div>
                          <div className="w-30 fancy-report-result">
                            <div className="status-win">
                              Status:
                              <span
                                className={`${
                                  value.status === "Win"
                                    ? "profit-color"
                                    : "loss-color"
                                }`}
                              >
                                {value.status}
                              </span>
                            </div>
                            <p>Type : {value.type}</p>
                            <p>Odd : {value.odd}</p>
                            <p>Comm : {value.comm}</p>
                            <p>Stake : {value.stake}</p>
                            <p>
                              Win/Loss :
                              <span
                                className={`${
                                  value.winLoss >= 0
                                    ? "profit-color"
                                    : "loss-color"
                                }`}
                              >
                                {value.winLoss}
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}

                    <div className="flex-sp-a fancy-report-total-div mt-5">
                      <div className="flex-column">
                        <span>Market Sub Total :</span>
                        <span className="profit-color">90</span>
                      </div>
                      <div className="flex-column">
                        <span>Comm :</span>
                        <span className="profit-color">0.00</span>
                      </div>
                      <div className="flex-column">
                        <span>Net Market Total</span>
                        <span className="profit-color">90</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ))}
      </div>
    </div>
  );
}
export default FancyReports;
