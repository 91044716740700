import {
  IonBackdrop,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonModal,
  IonPage,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { call } from "../../../components/api/axios";
import { GET_USER_TRANSACTIONS } from "../../../components/api/endpoints";
import { persistData } from "../../../components/common/commonData";
import Transactions from "../../../components/common/transactions";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import { setLoading, setUserTransactions } from "../../../redux/actions";
import "./styles.css";
import CreateTransaction from "../create-transaction";

function ViewTransactions() {
  const dispatch = useDispatch();
  let result = persistData();
  const { userTransactions, userTransactionsFlag } = result;
  const history = useHistory();
  const [trType, setTrType] = useState("all");
  const [all, setAll] = useState(true);
  const [deposit, setDeposit] = useState(false);
  const [withdrawl, setWithdrawl] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [allUserTransactions, setAllUserTransactions] =
    useState(userTransactions);

  const handleAllTransactions = () => {
    setTrType("all");
    setAll(true);
    setDeposit(false);
    setWithdrawl(false);
  };
  const handleDepositTransactions = (e) => {
    setTrType(e.target.value);
    setAll(false);
    setDeposit(true);
    setWithdrawl(false);
  };

  const handleWithDrawalTransactions = (e) => {
    setTrType(e.target.value);
    setAll(false);
    setDeposit(false);
    setWithdrawl(true);
  };

  const getUserTransactions = async () => {
    userTransactionsFlag && dispatch(setLoading(true));
    await call(GET_USER_TRANSACTIONS, {
      user_id: `${localStorage?.getItem("user_id")}`,
    })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setUserTransactions(res?.data?.data?.results));
          dispatch(setLoading(false));
          setAllUserTransactions(res?.data?.data?.results);
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const handleCreateTransaction = () => {
    setShowModel(true);
  };
  const handleCreateTransactionCancel = () => {
    setShowModel(false);
  };

  const duTransactinos = [
    {
      value: "Texch",
      label: "Texch",
    },
    {
      value: 1,
      label: "Texch Deposits",
    },
    {
      value: 2,
      label: "Texch Withdrawl",
    },
  ];

  const webTransactinos = [
    {
      value: "Web",
      label: "Web",
    },
    {
      value: 3,
      label: "Web Deposits",
    },
    {
      value: 4,
      label: "Web Withdrawal",
    },
  ];
  useEffect(() => {
    getUserTransactions();
  }, []);

  return (
    <IonPage className="background">
      <IonContent className="background-none">
        <div className="account-header-container">
          <div className="accoutns-notification">
            <Header title="Transactions" />
          </div>
          <div className="account-header ">
            <div
              className={`${all ? "active" : ""}`}
              onClick={() => handleAllTransactions()}
            >
              ALL
            </div>
            <select
              className={`${deposit ? "active" : ""}`}
              defaultValue="Texch"
              onChange={(e) => handleDepositTransactions(e)}
            >
              {duTransactinos?.map(({ value, label }, i) => (
                <option value={value} key={i}>
                  {label}
                </option>
              ))}
            </select>

            <select
              defaultValue="Web"
              className={`${withdrawl ? "active" : ""}`}
              onChange={(e) => handleWithDrawalTransactions(e)}
            >
              {webTransactinos?.map(({ value, label }, i) => (
                <option value={value} key={i}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <Transactions type={trType} data={allUserTransactions} />
      </IonContent>
      {/* <center>
        <div
          className="add-transaction-icon mb-10"
          onClick={() => handleCreateTransaction()}
        >
          Create Transactionkkkkkkkkkkkkk
        </div>
      </center> */}
      <IonModal className="create-transaction-model" isOpen={showModel}>
        <CreateTransaction
          handleCreateTransactionCancel={handleCreateTransactionCancel}
        />
      </IonModal>
      <Footer />
    </IonPage>
  );
}
export default ViewTransactions;
