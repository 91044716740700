import EventEmitter from "events";

export const getQueryParams = (uri = '') => {
    const queryParams = {};
    uri.replace('?', '').split('&').forEach(param => {
        const arr = param.split('=');
        queryParams[arr[0]] = arr[1];
    });
    return queryParams;
}

export const TRANX_STATUS = {
    1: 'Pending',
    2: 'Approved',
    3: 'Rejected'
}

export const eventEmitter = new EventEmitter();