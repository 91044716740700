import { IonButton, IonIcon, IonInput, IonItem, IonLabel,IonImg } from "@ionic/react";
import { chevronDownOutline, closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { call } from "../../../components/api/axios";
import { ADD_CARD, ADD_CARD_UPDATE } from "../../../components/api/endpoints";
import ThankYouModel from "../../../components/common/thankyou-model";
import Header from "../../../components/header";
import { setLoading } from "../../../redux/actions";
import { getAddPaymentType } from "../../../helper";

function AddCardOrAccount(props) {
  let editAccountData = props?.location?.state?.accountData
  const { setAccountType, accountType } = props
  // console.log({accountType})
  const [uploadImage, setUploadImage] = useState(null);
  const [uploadImgErr, setUploadImgErr] = useState(false);
  const [fileObject, setFileObject] = useState("");

  const accountTypeData = [
    {
      type: "UPI",
      id: 1,
    },
    {
      type: "NEFT/RTGS",
      id: 2,
    },
  ];

  const UPIType = [
    {
      label: "UPI Name",
      name: "upi_name",
      type: "text",
      placeholder: "Enter UPI Name",
      icon:"/images/upi.png"
    },
    {
      label: "Mobile Number",
      name: "upi_mobile_no",
      type: "number",
      placeholder: "Enter mobile number",
      icon:"/images/telePhone.png"
    },
  ];

  const NEFTType = [
    {
      label: "Account Holder Name",
      name: "bank_acc_holder_name",
      type: "text",
      placeholder: "Enter Account holder name",
      icon:"/images/userImg.png"
    },
    {
      label: "Bank Name",
      name: "bank_name",
      type: "text",
      placeholder: "Enter Bank name",
      icon:"/images/bankIcon.png"
    },
    {
      label: "Bank Account Number",
      name: "bank_account_no",
      type: "number",
      placeholder: "Enter account number",
      icon:"/images/passBook.png"
    },
    {
      label: "IFSC Code",
      name: "bank_ifsc",
      type: "text",
      placeholder: "Enter IFSC code",
      icon:"/images/bankIcon.png"
    },
  ];
  

  const history = useHistory();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [paymentMethodDropDown, setPaymentMethodDropDown] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(
     editAccountData !== undefined
      ? accountTypeData[accountType]
      : accountTypeData[1]
  );
  // const [accountType, setAccountType] = useState(UPIType);
  const [paymentMethodErr, setPaymentMethodErr] = useState(false);

  const [successAlert, setSuccessAlert] = useState(false);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e?.detail?.value });
  };
  const handleAddAccount = async () => {
    let AddCardPayload = {
      ...data,
      user_id: `${localStorage?.getItem("user_id")}`,
      card_type: paymentMethod?.id === 5 ? 2 : 1,
    };

    if (paymentMethod?.id === 6) {
      if (Object.keys(AddCardPayload).length < 5) {
        setErrMsg("Please enter all feilds");
        return;
      } else {
        setErrMsg("");
      }
    } else {
      if (Object.keys(AddCardPayload).length < 4) {
        setErrMsg("Please enter all feilds");
        return;
      } else {
        setErrMsg("");
      }
    }

    dispatch(setLoading(true));

    await call(
      {
        ...ADD_CARD,
        url: ADD_CARD?.url,
      },
      AddCardPayload
    )
      .then((res) => {
        dispatch(setLoading(false));
        if (res?.data?.error === "true") {
          setError(res?.data?.message);
        } else {
          setSuccessAlert(true);
          getCardType()
        }
      })
      .catch((err) => {
        setErrMsg(err?.message);
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const handleUpdateAccount = async () => {
    let AddCardPayload = {
      ...data,
      user_id: `${localStorage?.getItem("user_id")}`,
      pa_id: editAccountData?.pa_id,
    };
    if (paymentMethod?.id === 1) {
      if (Object.keys(AddCardPayload).length < 4) {
        setErrMsg("Please enter all feilds");
        return;
      } else {
        setErrMsg("");
      }
    } else {
      if (Object.keys(AddCardPayload).length < 5) {
        setErrMsg("Please enter all feilds");
        return;
      } else {
        setErrMsg("");
      }
    }

    dispatch(setLoading(true));

    await call(
      {
        ...ADD_CARD_UPDATE,
        url: ADD_CARD_UPDATE?.url,
      },
      AddCardPayload
    )
      .then((res) => {
        dispatch(setLoading(false));
        if (res?.data?.error === "true") {
          setError(res?.data?.message);
        } else {
          setSuccessAlert(true);
          getCardType()
        }
      })
      .catch((err) => {
        setErrMsg(err?.message);
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const handleCancel = () => {
    history.goBack();
  };
  const handleCancelModel = () => {
    setSuccessAlert(false);
    setTimeout(() => {
      history.push("/accounts/referral");
    }, 500);
  };
  const handleBackToHome = () => {
    setSuccessAlert(false);
    setTimeout(() => {
      history.push("/");
    }, 500);
  };
  const handlePaymentMethodDropDown = () => {
    setPaymentMethodDropDown(!paymentMethodDropDown);
  };
  const handlePaymentMethod = (method) => {
    setPaymentMethod(method);
    setData([]);
    setPaymentMethodErr(false);
    if (method?.id === 1) {
      setAccountType(UPIType);
    } else if (method?.id === 2) {
      setAccountType(NEFTType);
    }
    setPaymentMethodDropDown(!paymentMethodDropDown);
  };

  const getCardType = () => {
    if (editAccountData?.card_type === 1) {
      setData({
        ...data,
        upi_mobile_no: editAccountData?.upi_mobile_no,
        upi_name: editAccountData?.upi_name,
      });
      setAccountType(UPIType);
      return;
    } else if (editAccountData?.card_type === 2) {
      setData({
        ...data,
        bank_name: editAccountData?.bank_name,
        bank_account_no: editAccountData?.bank_account_no,
        bank_ifsc: editAccountData?.bank_ifsc,
        bank_acc_holder_name: editAccountData?.bank_acc_holder_name
      });
      setAccountType(NEFTType);
      return;
    }
  };

  const handleImageUpload = (e) => {
    setUploadImgErr(false);
    if (e.target.files.length) {
      setUploadImage(URL.createObjectURL(e.target.files[0]));
      setFileObject(e.target.files[0]);
    }
  };

  const handleRemoveUplaoadImage = () => {
    setUploadImage(null);
    setUploadImgErr(true);
  };

  const QRCodeComponent = () => {
    return <>
     <div>
            <div
              className="image-upload-container mt-40 mb-30"
            >
              <>
                {uploadImage ? (
                  <div className="close-icon-image">
                    <img
                      src={uploadImage}
                      alt="dummy"
                      width="100%"
                      height="175"
                    />
                    <IonIcon
                      icon={closeOutline}
                      className="close-icon-logo"
                      onClick={() => handleRemoveUplaoadImage()}
                    />
                  </div>
                ) : (
                  <label htmlFor="upload-button-1">
                    <IonImg
                      className="upload-image-icon"
                      src={process.env.PUBLIC_URL + "/images/qrUpload.png"}
                    />
                  </label>
                )}
              </>
              {uploadImage ? null : (
                <div>Upload your QR code here</div>
              )}
              <input
                type="file"
                id="upload-button-1"
                style={{ display: "none" }}
                onChange={(e) => handleImageUpload(e)}
              />
              <br />
            </div>
          </div>
          <div
            hidden={!uploadImgErr}
            className="error-message error-message-margin"
            style={{ marginBottom: "10px" }}
          >
            {errMsg}
          </div></>
  }
  useEffect(() => {
    if ( editAccountData !== null) {
      getCardType();
    }
  }, []);

  return (
    <div className="">
      <div>
        {/* <Header
          title={
            ( editAccountData !== undefined)
              ? "Edit Card/Account"
              : "Add Card/Account"
          }
        /> */}
      </div>
      <div className="">
        {/* <div className="form-container">
          <div
            className="input-dropdown login-input-field transaction-input add-account-form"
            style={{
              borderBottom: paymentMethodDropDown
                ? "3px solid #a4930a"
                : "2px solid #a4930a",
            }}
            onClick={() => handlePaymentMethodDropDown()}
          >
            <div>
              {paymentMethod?.type !== "" ? (
                <div>
                  <div className="referred-selected-value">
                    Select Payment Method
                  </div>
                  <div>{paymentMethod?.type}</div>
                </div>
              ) : (
                "Select Payment Method"
              )}
            </div>
            <div>
              <IonIcon icon={chevronDownOutline} />
            </div>
          </div>
          {paymentMethodDropDown ? (
            <div className="login-input-field reffered-by-values transaction-input-field add-account-content w-100">
              {accountTypeData?.map((account, index) => (
                <div key={index}>
                  <div
                    className="reffered-by-value"
                    name="refferdBy"
                    onClick={() => handlePaymentMethod(account)}
                  >
                    {account?.type}
                  </div>
                  <hr className="referred-horizontal-line" />
                </div>
              ))}
            </div>
          ) : null}
        </div> */}
        <div
          hidden={!paymentMethodErr}
          className="error-message error-message-margin"
          style={{ marginTop: "5px" }}
        >
          {errMsg}
        </div>
        { accountType == 6 ? QRCodeComponent() :  getAddPaymentType(accountType)?.map((upi) => {
          return (
            <div className="mt-10" key={upi?.label}>
              <IonItem className="mb-5 transaction-input">
              <img className="id-details-icons" src={process.env.PUBLIC_URL + upi?.icon} alt="icon"/>
                {/* <IonLabel position="floating">{upi?.label}</IonLabel> */}
                <IonInput
                  placeholder={upi?.placeholder}
                  type={upi?.type}
                  name={upi?.name}
                  onIonChange={(e) => handleChange(e)}
                  value={data[upi?.name] || ""}
                  clearInput
                  required
                ></IonInput>
              </IonItem>
            </div>
          );
        })}
        {errMsg !== "" && (
          <div className="error-message error-message-margin">
            {errMsg}
          </div>
        )}
        <div>
          {
            <ThankYouModel
              successAlert={successAlert}
              handleCancel={handleCancelModel}
              handleBackToHome={handleBackToHome}
              msg={"Your Card Added Successfully"}
            />
          }
        </div>
        <div className="mt-40 mb-30"></div>
        <div className="login-btn mt-40 mb-5" style={{ width: "100%" }}>
          <IonButton
            onClick={() =>
               editAccountData !== undefined
                ? handleUpdateAccount()
                : handleAddAccount()
            }
          >
            SUBMIT
          </IonButton>
        </div>
        <div
          hidden={!error}
          className="error-message error-message-margin text-center mb-30"
        >
          {errMsg}
        </div>
        {/* <div className="signup-now text-center mt-40">
          <span onClick={() => handleCancel()}>CANCEL</span>
        </div> */}
      </div>
    </div>
  );
}

export default AddCardOrAccount;