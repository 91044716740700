import { IonIcon, IonTabBar, IonTabButton } from "@ionic/react";
import { chatbubble, grid, home, newspaper, person } from "ionicons/icons";
import { useEffect, useState } from "react";
import { isUserVerified } from "../../helper";
import { IonContent, IonImg, IonPage } from "@ionic/react";
import { AiFillHome, AiFillSetting, AiOutlineWechat } from "react-icons/ai";
import { TbBoxMultiple } from "react-icons/tb";
import { HiOutlineClipboardList } from "react-icons/hi";
import "./styles.css";

// const MENU = [
//   {
//     tab: "accounts",
//     href: "/accounts",
//     icon: person,
//   },

//   {
//     tab: "transactions",
//     href: "/transactions",
//     icon: newspaper,
//   },
//   {
//     tab: "home",
//     href: "/",
//     icon: home,
//   },
//   {
//     tab: "support",
//     href: "/support",
//     icon: chatbubble,
//   },
//   {
//     tab: "profile",
//     href: "/profile",
//     icon: grid,
//   },
// ];

const MENU = [
  {
    tab: "accounts",
    img: <TbBoxMultiple />,
    heading: "Multi Market",
    href: "/accounts",
  },
  {
    tab: "transactions",
    img: <HiOutlineClipboardList />,
    heading: "Transactions",
    href: "/transactions",
  },
  {
    tab: "home",
    img: <AiFillHome />,
    href: "/",
    heading: "Home",
  },
  {
    tab: "support",
    img: <AiOutlineWechat />,
    heading: "Support",
    href: "/support",
  },
  {
    tab: "Settings",
    img: <AiFillSetting />,
    heading: "Settings",
    href: "/profile",
  },
];
function Footer() {
  const [active, setActive] = useState("");

  useEffect(() => {
    setActive(
      MENU.find((eachTab) => `#${eachTab.href}` === window.location.hash)?.tab
    );
  }, [window.location.href]);
  let token = localStorage?.getItem("token");
  let isLoggedin = true;
  if (isUserVerified()) {
    isLoggedin = false;
  }

  return (
    <div className={isLoggedin ? "footer-autheticated" : ""}>
      <IonTabBar slot="bottom" color="dark" className="footer-div">
        {MENU.map((menuItem, index) => (
          <IonTabButton
            className="footer-div-button"
            tab={menuItem.tab}
            href={menuItem.href}
            key={index}
          >
            {/* {index === 2 ? (
              <AiFillHome
                className={`${
                  active == menuItem.tab
                    ? "footer-icon-active-clr"
                    : "footer-icon-clr"
                }`}
              />
            ) : (
              <IonImg
                // className="reports-image"
                className={`${
                  active == menuItem.tab
                    ? "footer-icon-active-clr"
                    : "footer-icon-clr"
                }`}
                src={process.env.PUBLIC_URL + menuItem.img}
                alt="report-icons"
              ></IonImg>
            )} */}
            <div
              // className="reports-image"
              className={`${
                active == menuItem.tab
                  ? "footer-icon-active-clr flex-jc-c"
                  : "footer-icon-clr flex-jc-c"
              }`}
            >
              <div className="footer-icon flex-jc-c">{menuItem.img}</div>
            </div>

            {/* <AiFillHome className={`${
                active == menuItem.tab
                  ? "footer-icon-active-clr"
                  : "footer-icon-clr"
              }`}/> */}
            <div className="custom-font">{menuItem.heading}</div>
            {/* <IonIcon
              className={`${
                active == menuItem.tab
                  ? "footer-icon-active-clr"
                  : "footer-icon-clr"
              }`}
              icon={menuItem?.icon}
            /> */}
          </IonTabButton>
        ))}
      </IonTabBar>
    </div>
  );
}

export default Footer;
