import { IonButton } from "@ionic/react";
import { useState } from "react";
import { call } from "../../components/api/axios";
import { SMS_VERIFY } from "../../components/api/endpoints";
const OtpInput = ({
  setIsChangesNumberChanged,
  setIsOpen,
  modalType,
  modalSumbitMethod,
  setIsNumberVerified
}) => {
  const [inputData, setInputData] = useState({
    value: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
    disable: true,
  });
  const [isMobileVerified, setIsMobileVerified] = useState("");
  const [err, setErr] = useState("");

  const handleChange = (value1, event) => {
    setInputData({ ...inputData, [value1]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      !inputData?.otp1?.length ||
      !inputData?.otp2?.length ||
      !inputData?.otp3?.length ||
      !inputData?.otp4?.length ||
      !inputData?.otp5?.length ||
      !inputData?.otp6?.length
    ) {
      return setErr("Please enter OTP");
    } else {
      setErr("");
    }
    if(modalType){
      const otp =`${inputData?.otp1}${inputData?.otp2}${inputData?.otp3}${inputData?.otp4}${inputData?.otp5}${inputData?.otp6}`;
      console.log(otp,"otp");
      modalSumbitMethod(otp)
    } else{
    let smsverifyResult = await smsVerfiy();
    if (smsverifyResult?.data?.error === "true") {
      return setErr(smsverifyResult?.data?.message);
    } else {
      setIsMobileVerified(smsverifyResult?.data?.message);
      setIsMobileVerified(true);
      setIsChangesNumberChanged();
      setIsOpen();
      setIsNumberVerified()
    }
  }
  };
  const smsVerfiy = async () => {
    return call(SMS_VERIFY, {
      userId: `${localStorage?.getItem("user_id")}`,
      otp: +`${inputData?.otp1}${inputData?.otp2}${inputData?.otp3}${inputData?.otp4}${inputData?.otp5}${inputData?.otp6}`,
    });
  };

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      console.log("next");

      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="verify-top-container">
      <div className="otpContainer">
        <input
          name="otp1"
          type="text"
          autoComplete="off"
          className="otpInput"
          value={inputData?.otp1}
          onChange={(e) => handleChange("otp1", e)}
          tabIndex="1"
          maxLength="1"
          onKeyUp={(e) => inputfocus(e)}
        />
        <input
          name="otp2"
          type="text"
          autoComplete="off"
          className="otpInput"
          value={inputData?.otp2}
          onChange={(e) => handleChange("otp2", e)}
          tabIndex="2"
          maxLength="1"
          onKeyUp={(e) => inputfocus(e)}
        />
        <input
          name="otp3"
          type="text"
          autoComplete="off"
          className="otpInput"
          value={inputData?.otp3}
          onChange={(e) => handleChange("otp3", e)}
          tabIndex="3"
          maxLength="1"
          onKeyUp={(e) => inputfocus(e)}
        />
        <input
          name="otp4"
          type="text"
          autoComplete="off"
          className="otpInput"
          value={inputData?.otp4}
          onChange={(e) => handleChange("otp4", e)}
          tabIndex="4"
          maxLength="1"
          onKeyUp={(e) => inputfocus(e)}
        />

        <input
          name="otp5"
          type="text"
          autoComplete="off"
          className="otpInput"
          value={inputData?.otp5}
          onChange={(e) => handleChange("otp5", e)}
          tabIndex="5"
          maxLength="1"
          onKeyUp={(e) => inputfocus(e)}
        />
        <input
          name="otp6"
          type="text"
          autoComplete="off"
          className="otpInput"
          value={inputData?.otp6}
          onChange={(e) => handleChange("otp6", e)}
          tabIndex="6"
          maxLength="1"
          onKeyUp={(e) => inputfocus(e)}
        />
      </div>
      {err !== "" && <div className="err-msg">{err}</div>}
      {isMobileVerified !== "" && (
        <div className="err-msg">{isMobileVerified}</div>
      )}
      <div className="modal-cancel-submt-btn">
        <IonButton
          className="btn-clr"
          type="submit"
          onClick={() => {
            setIsNumberVerified();
            setIsOpen();
          }}
        >
          CANCEL
        </IonButton>
        <IonButton className="btn-clr" type="submit">
          SUBMIT
        </IonButton>
      </div>
    </form>
  );
};

export default OtpInput;
