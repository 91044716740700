import { IonImg, IonIcon } from "@ionic/react";
import React, { useState } from "react";
import {
  chevronUpCircleOutline,
  chevronDownCircleOutline,
} from "ionicons/icons";
import DateRange from "./DateRange";

function AccountStatement() {
  const [webSiteList, setWebSiteList] = useState(false);
  const [selectWebsite, setSelectWebsite] = useState("");
  const [activeIndex, setActiveIndex] = useState(false);
  const [indexPosition, setIndexPosition] = useState("");
  const [transationType, setTransactionType] = useState("");
  const [transactionList, setTransactionList] = useState(false);
  // const [showTickets,setShowTickets] = useState (false)
  const handleActiveIndex = (index) => {
    setActiveIndex(!activeIndex);
    setIndexPosition(index);
  };
  console.log(indexPosition);

  const transactionTypeList = [
    "All",
    "Deposit",
    "Withdraw",
    "Settlement Dep",
    "Settlement With..",
    "Rollback",
    "Voided/Cancel",
    "Unmatched",
  ];
  const statementList = [
    {
      website: "T Exchange",
      statementType: "Win Commission",
      transactionId: "TXN ID: 26801488",
      time: "06-06-2023,  02:54:27",
      creditDebi: "-100.00",
      sportsClosing: "54900.00",
      casinoClosing: "15000.00",
    },
    {
      website: "T Exchange",
      statementType: "Bet Settlement",
      transactionId: "TXN ID: 26801488",
      time: "06-06-2023,  02:54:27",
      creditDebi: "+5000.00",
      sportsClosing: "50000.00",
      casinoClosing: "15000.00",
    },
    {
      website: "T Casino-Park",
      statementType: "Widthdraw",
      transactionId: "TXN ID: 26689758",
      time: "06-06-2023,  02:54:27",
      creditDebi: "-10000.00",
      sportsClosing: "50000.00",
      casinoClosing: "25000.00",
    },
    {
      website: "T Casino-Park",
      statementType: "Deposite",
      transactionId: "TXN ID: 26801488",
      time: "06-06-2023,  02:54:27",
      creditDebi: "+25000.00",
      sportsClosing: "75000.00",
      casinoClosing: "00.00",
    },
    {
      website: "T Exch-Wallet",
      statementType: "Withdraw",
      transactionId: "TXN ID: 26801488",
      time: "06-06-2023,  02:54:27",
      creditDebi: "-25000.00",
      sportsClosing: "100000.00",
      casinoClosing: "00.00",
    },
    {
      website: "T Exch-wallet",
      statementType: "Deposite",
      transactionId: "TXN ID: 26801488",
      time: "06-06-2023,  02:54:27",
      creditDebi: "+100000.0",
      sportsClosing: "00.00",
      casinoClosing: "00.00",
    },
  ];
  const WebsiteTickets = [
    {},
    {
      boxOneContent: "Market ID : 1.210798031 Transaction ID : 3854784",
      boxTwoContent:
        "Bet Place : PL of Market: Test Matches India vs Australia/Match Odds",
      boxThreeContent: "Settled Date & Time : 24-04-2023, 1:01:00",
      boxFourContent: "Selection :Rajasthan Rayals",
      status: "Type: Back",
      welcome: "Odds: 1.05",
      CasinoBalance: "Stake : 100000",
      walletBalance: "Status : Win",
      commission:"comm:00",
      winStatus:"5000",
      runningBalance:"50000",
      walletBalance:"55000",
      win:"Win"
    },
    {
      boxOneContent: "Market ID : 1.210798031 Transaction ID : 3854784",
      boxTwoContent:
        " Deposit/Withdraw :  T Exchange-Casino Payment Mood : T Exch-Wallet",
      boxThreeContent: "Settled Date & Time : 24-04-2023, 1:01:00",
      boxFourContent: "Before Casino Balance : 25000 Deposit/Withdraw : -10000",
      status: "Success",
      welcome: "Welcome",
      CasinoBalance: "CasinoBalance: 15000",
    },
    {
      boxOneContent: "Market ID : 1.210798031 Transaction ID : 3854784",
      boxTwoContent:
        " Deposit/Withdraw :  T Exchange-Casino Payment Mood : T Exch-Wallet",
      boxThreeContent: "Settled Date & Time : 24-04-2023, 1:01:00",
      boxFourContent: "Before Casino Balance : 00 Deposit/Withdraw : 25000",
      status: "Success",
      welcome: "Welcome",
      CasinoBalance: "CasinoBalance: +25000",
    },
    {
      boxOneContent: "Market ID : 1.210798031 Transaction ID : 3854784",
      boxTwoContent:
        " Deposit/Withdraw :  T Exch-wallet Payment Mood : Phone-Pay",
      boxThreeContent: "Settled Date & Time : 24-04-2023, 1:01:00",
      boxFourContent:
        "Before Casino Balance : 100000 Deposit/Withdraw : -25000",
      status: "Success",
      welcome: "Welcome",
      CasinoBalance: "WalletBalance : +75000",
    },
    {
      boxOneContent: "Market ID : 1.210798031 Transaction ID : 3854784",
      boxTwoContent:
        " Deposit/Withdraw :  T Exch-wallet Payment Mood : Phone-Pay",
      boxThreeContent: "Settled Date & Time : 24-04-2023, 1:01:00",
      boxFourContent: "Before Wallet Balance : 00 Deposit/Withdraw : +100000",
      status: "Success",
      welcome: "Welcome",
      CasinoBalance: "WalletBalance : +100000",
    },
  ];
  const webSites = [
    {
      image: "/images/tCasinoParkLogo.png",
      name: "T Casino Park",
    },
    {
      image: "/images/spark247Logo.png",
      name: "Spark 247",
    },
    {
      image: "/images/sparkBookLogo.png",
      name: "Spark Book",
    },
    {
      image: "/images/spark999Logo.png",
      name: "Spark 999",
    },
    {
      image: "/images/tExchangeLogo.png",
      name: "T Exchange",
    },
  ];
  const handleShowwebSite = () => {
    setWebSiteList(!webSiteList);
  };
  const handleWebList = (item) => {
    setSelectWebsite(item);
    setWebSiteList(false);
  };

  const handleTransactionList = (value) => {
    setTransactionType(value);
  };
  const handleShowTransactionList = () => {
    setTransactionList(!transactionList);
  };
  return (
    <div className="account-Statement">
      <div className="flex-sp-b">
        <div className="w-48">
          <div className="account-statement-header">WebSites</div>
          <div
            className="flex website-dropdown mt-5 justify-start"
            onClick={() => handleShowwebSite()}
          >
            {selectWebsite ? selectWebsite : "All"}
            <IonIcon
              className="websites-arrow arrow-position"
              icon={
                webSiteList ? chevronUpCircleOutline : chevronDownCircleOutline
              }
            />
            {webSiteList && (
              <div className="website-list-conaitar list-position w-100">
                {webSites.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className="webs-container flex"
                      onClick={() => handleWebList(value.name)}
                    >
                      <div className="flex website-list">
                        <div className="reports-image-container mr-10 flex">
                          <IonImg
                            className="reports-image"
                            src={process.env.PUBLIC_URL + value.image}
                          ></IonImg>
                        </div>
                        <span className="reports-name">{value.name}</span>
                      </div>
                      <div className="mr-10 select-option">select</div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="w-48">
          <div className="account-statement-header">Transaction Type</div>
          <div
            className="flex website-dropdown mt-5 justify-start"
            onClick={() => handleShowTransactionList()}
          >
            {transationType ? transationType : "All"}
            <IonIcon
              className="websites-arrow"
              icon={
                webSiteList ? chevronUpCircleOutline : chevronDownCircleOutline
              }
            />
            {transactionList && (
              <div className="betstatus-list Transaction-position">
                {transactionTypeList.map((value, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        handleTransactionList(value);
                      }}
                    >
                      {value}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <DateRange />
      <div className="flex w-100 mt-10 pr-5">
        <div className="w-35">
          <span className="my-bet-report-header my-bet-report-match-header">
            Transaction
          </span>
        </div>
        <span className="w-24 my-bet-report-header">Credit/Debit</span>
        <span className="w-24 my-bet-report-header">Closing Bal</span>
        <span className="w-24 my-bet-report-header">Closing Bal</span>
      </div>
      <div className="account-statement-container">
        {statementList.map((value, index) => {
          return (
            <div key={index} >
              <div className="activity-log-text-img-div mt-10">
                <div className="activitylog-text-img-arrow-div">
                  <div className="w-33 match-adds-conatainer">
                    <span>{value.website}</span>
                    <div className="match-adds">{value.statementType}</div>
                    <span>{value.transactionId}</span>
                    <span>{value.time}</span>
                  </div>
                  <div
                    className={`w-20 my-bet-report-header txt-right ${
                      value.creditDebi >= 0 ? "clr-grn" : "clr-red"
                    }`}
                  >
                    {value.creditDebi}
                  </div>
                  <div className="w-20 my-bet-report-header txt-right">
                    {value.sportsClosing}
                  </div>
                  <div className="w-20 my-bet-report-header txt-right">
                    {value.casinoClosing}
                  </div>
                  <IonIcon
                    className="log-arrow"
                    icon={
                      indexPosition === index && activeIndex
                        ? chevronUpCircleOutline
                        : chevronDownCircleOutline
                    }
                    onClick={() => handleActiveIndex(index)}
                  />
                </div>
              </div>
              {activeIndex && indexPosition === index && (
                <div className="activity-log-text-img-div mb-10">
                  {WebsiteTickets.map((value, i) => {
                    if (index === i) {
                      return (
                        <div key={i}>
                          <div className="flex-sp-b w-100 mb-3 aline-strech">
                            <div className="w-40 ticket-left tickets-details">
                              {value.boxOneContent}
                            </div>
                            <div className="w-60 ticket-right tickets-details">
                              {value.boxTwoContent}
                            </div>
                          </div>
                          <div className="flex-sp-b w-100 aline-strech">
                            <div className="w-40 ticket-left tickets-details">
                              {value.boxThreeContent}
                            </div>
                            <div className="w-60 ticket-right tickets-details">
                              {value.boxFourContent}
                            </div>
                          </div>

                          {index === 1 ? (
                            <div>
                              <div className="flex-sp-b w-100">
                                <div className="tickets-details">
                                  {value.status}
                                </div>
                                <div className="tickets-details">
                                  {value.welcome}
                                </div>
                                <div className="tickets-details">
                                  {value.CasinoBalance}
                                </div>
                                <div className="tickets-details">
                                  Status:<span className={`${value.win==="Win"?"clr-grn":"clr-red"}`}>{value.win}</span>
                                </div>
                              </div>
                              <div className="flex-sp-b w-100 ticket-right tickets-details">
                                <div className="tickets-details">
                                  {value.commission}
                                </div>
                                <div className="tickets-details">
                                  Win/Loss:<br/><span className={`${value.winStatus>=0?"clr-grn":"clr-red"}`}>{value.winStatus}</span>
                                </div>
                                <div className="tickets-details">
                                  RunningBalance:<br/>
                                  {value.runningBalance}
                                </div>
                                <div className="tickets-details">
                                  WalletBalance:<br/>
                                  {value.walletBalance}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="flex-sp-b mt-10 w-100">
                              <div className="tickets-details">
                                Status :
                                <span
                                  className={`${
                                    value.status === "Success"
                                      ? "clr-grn"
                                      : "clr-red"
                                  } tickets-details`}
                                >
                                  {value.status}
                                </span>
                              </div>
                              <div className="tickets-details welcome">
                                {value.welcome}
                              </div>
                              <div className="tickets-details">
                                {value.CasinoBalance}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    }
                    return <div></div>;
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AccountStatement;
