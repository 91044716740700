import { IonToast } from "@ionic/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router";
import Loading from "../components/common/Loading";
import GroupCall from "../components/GroupCall";
import GuideLinePage from "../components/guidelines/GuideLinePage";
import AccountReferral from "../pages/accounts/account-referral";
import AddCardOrAccount from "../pages/accounts/add-account";
import CreateId from "../pages/accounts/create-account";
import ViewAccounts from "../pages/accounts/view-accounts";
import ForgotPassword from "../pages/forgot-password";
// import ResetPassword from "../pages/forgot-password/ResetPassword";
import ResetPwdPopup from "../pages/forgot-password/ResetPwdPopup";
import Help from "../pages/help";
import Home from "../pages/home";
import AccountInformation from "../pages/home/accountInfo";
import Support from "../pages/home/support";
import Meetings from "../pages/meetings";
import SuccessfulSignUp from "../pages/model/SuccessfulSignUp";
import MultiWallet from "../pages/multiWallet/MultiWallet";
import Notifications from "../pages/notificatinons";
import Offers from "../pages/notificatinons/Offers";
import UpdateNotifcations from "../pages/notificatinons/update-notifications";
import PrivacyPolicy from "../pages/privacy-policy";
import Profile from "../pages/profile";
import Menu from "../pages/menu/Menu";
import EditProfile from "../pages/profile/editProfile";
import SignUp from "../pages/signup";
import Tickets from "../pages/ticktes";
import CreateTransaction from "../pages/transactions/create-transaction";
import CreateTransactinoFromUser from "../pages/transactions/create-transaction/CreateTransactionFromUser";
import ViewTransactions from "../pages/transactions/view-transactions";
import PrivateRoute from "../PrivateRoute";
import { eventEmitter } from "../utils/AppUtils";
import ReferYourFriend from "../pages/refer-your-friend";
import TexchWebSitesReports from "../pages/texch-websites/TexchWebSitesReports";

function Routes() {
  const [toast, showToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const loading = useSelector((state) => state?.common?.loading);

  useEffect(() => {
    eventEmitter.addListener("toast-message", (message) => {
      setToastMsg(message);
      showToast(true);
      setTimeout(() => {
        setToastMsg("");
        showToast(false);
      }, 3000);
    });
  }, []);

  return (
    <div>
      <IonToast
        isOpen={toast}
        onDidDismiss={() => showToast(false)}
        message={toastMsg}
        duration={3000}
        position="middle"
      />
      <Route path="/index.html" component={Home} />
      <Route path="/guidelines" component={GuideLinePage} exact />
      <Route path="/guidelinespage" component={GuideLinePage} exact />
      {/* <Route path="/login" component={Login} exact /> */}
      <Route path="/signup" component={SignUp} exact />
      <Route path="/successful-signup" component={SuccessfulSignUp} exact />
      <Route path="/forgot-password" component={ForgotPassword} exact />
      <Route path="/" component={Home} exact />
      {/* <PrivateRoute path="/reset-password" component={ResetPassword} exact /> */}
      <PrivateRoute path="/reset-password-popup" component={ResetPwdPopup} exact />
      <PrivateRoute path="/accounts" component={ViewAccounts} exact />
      <PrivateRoute path="/transactions" component={ViewTransactions} exact />
      <PrivateRoute path="/tickets" component={Tickets} exact />
      <PrivateRoute path="/meetings" component={Meetings} exact />
      <PrivateRoute
        path="/create-transaction"
        component={CreateTransaction}
        exact
      />
      <PrivateRoute
        path="/create-transaction-from-user"
        component={CreateTransactinoFromUser}
        exact
      />
      <PrivateRoute path="/notifications" component={Notifications} exact />
      <PrivateRoute path="/offers" component={Offers} exact />
      <PrivateRoute path="/create-id/:id" component={CreateId} exact />
      <PrivateRoute
        path="/accounts/add-account"
        component={AddCardOrAccount}
        exact
      />
      <PrivateRoute
        path="/accounts/add-account/:id"
        component={AddCardOrAccount}
        exact
      />
      <PrivateRoute path="/profile" component={Profile} exact />
      <PrivateRoute path="/side-menu" component={Menu} exact />
      <PrivateRoute path="/edit-profile" component={EditProfile} exact />
      <PrivateRoute path="/support" component={Support} exact />
      <PrivateRoute path="/multi-wallet" component={MultiWallet} exact />
      <PrivateRoute
        path="/terms-and-services"
        component={PrivacyPolicy}
        exact
      />
      <PrivateRoute path="/help" component={Help} exact />
      <PrivateRoute
        path="/texch-websites"
        component={TexchWebSitesReports}
        exact
      />
      <PrivateRoute
        path="/notification/update"
        component={UpdateNotifcations}
        exact
      />
      <PrivateRoute
        path="/accounts/referral"
        component={AccountReferral}
        exact
      />
      <PrivateRoute
        path="/account-info/:id/:webId"
        component={AccountInformation}
        exact
      />
      <PrivateRoute path="/group-call/:id" component={GroupCall} exact />
      <PrivateRoute
        path="/refer-your-friend"
        component={ReferYourFriend}
        exact
      />
      {loading && (
        <div className="app-content-loading">
          <div className="loading_main row">
            <Loading />
          </div>
        </div>
      )}
    </div>
  );
}

export default Routes;
