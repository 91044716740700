import { IonPage, IonContent, IonIcon, IonImg } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { closeSharp, chevronForwardSharp } from "ionicons/icons";
import "./Styles.css";
import { isUserVerified } from "../../helper";

function Menu() {
  const history = useHistory();
  const navigate1 = (path, status) => {
    if (isUserVerified()) {
      history.push({
        pathname: path,
        state: {
          menuitems: status,
          // menuitems : 0
        },
      });
    }
  };
  const balnceInformation = [
    {
      icon: "/images/walletBalance.png",
      title: "Wallet Balance",
      amount: 10000,
    },
    {
      icon: "/images/availableBalance.png",
      title: "Sports Exposer",
      amount: 10000,
    },
    {
      icon: "/images/sportsExposer.png",
      title: "Casino Balance",
      amount: 100,
    },
    {
      icon: "/images/casinoExposer.png",
      title: "Casino Exposer",
      amount: 100,
    },
  ];

  const report = [
    {
      icon: "/images/myWalletImg.png",
      title: "My Wallet",
      id: "my_wallet",
    },
    {
      icon: "/images/myBetsIcon.png",
      title: "My Bets",
      id: "my_bet",
    },
    {
      icon: "/images/fancyReportIcon.png",
      title: "Fancy Report",
      id: "fancy_report",
    },
    {
      icon: "/images/casinoReportsIcon.png",
      title: "Casino Reports",
      id: "casino_reports",
    },
    {
      icon: "/images/accountStatement.png",
      title: "Account Statement",
      id: "accout_statement",
    },
    {
      icon: "/images/profitLossIcon.png",
      title: "Profit & Loss",
      id: "profit_loss",
    },
  ];
  const myAccount = [
    {
      icon: "/images/activityLogsIcon.png",
      title: "Activity Logs",
      id: "activity_log",
    },
    {
      icon: "/images/customStakeIcon.png",
      title: "Custom Stake Buttons",
      id: "custom_stake_buttons",
    },
  ];
  return (
    <IonPage className="edit-profile-background">
      <div className="menu-container mt-20">
        <IonContent className="background-none">
          <div
            className="edit-profile-closeIcon"
            onClick={() => history.push("/#")}
          >
            <IonIcon icon={closeSharp} />
          </div>
          <div className="menu-logo ml-20 mb-5 mt-10">
            <IonImg src="/images/sideMenuLogo.png" />
          </div>
          <div className="notification-list-container edit-profile-wallet-div">
            <div className="menu-profile-balance-heading mb-10">
              Balance Information
            </div>
            {balnceInformation?.map((ele, index) => (
              <div key={index} className="edit-profile-wallet mb-5 mt-5">
                <div className="edit-profile-image-div">
                  <div>
                    <IonImg
                      className="notification-logo mr-20 edit-profile-images-bg"
                      src={process.env.PUBLIC_URL + ele.icon}
                    />
                  </div>
                  <div className="wallet-balance-font">{ele.title}</div>
                </div>
                <div className="menu-profile-wallet-balance">
                  <span
                    className={
                      index < 2
                        ? "wallet-balance-color"
                        : "exposer-wallet-color"
                    }
                  >
                    {ele.amount}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <hr className="profile-notification-border mt-5 mb-5" />

          <div className="notification-list-container edit-profile-wallet-div">
            <div className="edit-profile-balance-heading mb-10">Report</div>
            {report?.map((ele, index) => (
              <div
                key={index}
                onClick={() => navigate1("/texch-websites", ele.id)}
                className="edit-profile-wallet mb-10 mt-10"
              >
                <div className="menu-profile-image-div">
                  <div>
                    <IonImg
                      className="notification-logo mr-20 edit-profile-images-bg"
                      src={process.env.PUBLIC_URL + ele.icon}
                    />
                  </div>
                  <div className="wallet-balance-font">{ele.title}</div>
                </div>
                <div className="menu-side-arrow-icon">
                  <IonIcon icon={chevronForwardSharp} />
                </div>
              </div>
            ))}
          </div>
          <hr className="profile-notification-border mt-10 mb-10" />

          <div className="notification-list-container edit-profile-wallet-div">
            <div className="edit-profile-balance-heading mb-10">My Account</div>
            {myAccount?.map((ele, index) => (
              <div
                key={index}
                onClick={() => navigate1("/texch-websites", ele.id)}
                className="edit-profile-wallet mb-10 mt-10"
              >
                <div className="menu-profile-image-div">
                  <div>
                    <IonImg
                      className="notification-logo mr-20 edit-profile-images-bg"
                      src={process.env.PUBLIC_URL + ele.icon}
                    />
                  </div>
                  <div className="wallet-balance-font">{ele.title}</div>
                </div>
                <div className="menu-side-arrow-icon">
                  <IonIcon icon={chevronForwardSharp} />
                </div>
              </div>
            ))}
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}

export default Menu;
