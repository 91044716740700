import { IonPage} from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { call } from "../../components/api/axios";
import { PRIVACY_POLICY } from "../../components/api/endpoints";
import { persistData } from "../../components/common/commonData";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { setLoading, setPrivacyPolicy } from "../../redux/actions";

const PrivacyPolicy = () => {
  let result = persistData();
  const { pricayPolicyData, pricayPolicyDataFlag } = result;
  const dispatch = useDispatch();
  const [policy, setPolicy] = useState(pricayPolicyData);

  useEffect(() => {
    pricayPolicyDataFlag && dispatch(setLoading(true));
    call(PRIVACY_POLICY).then((res) => {
      setPolicy(res?.data?.data?.result?.policy_text);
      dispatch(setLoading(false));
      dispatch(setPrivacyPolicy(res?.data?.data?.result?.policy_text));
    });
  }, []);

  return (
    <div>
      <IonPage className="background">
     
        <div className="account-header-container">
          
          <Header title={`Terms & Policy`} />
         
        </div>
        <div className="support-home term-and-services-container terms-and-policy-card">
        <div className="terms-policy-icon-container">
<img src={process.env.PUBLIC_URL + '/assets/termsAndPolicy.png'}  alt="terms and policy" className="terms-policy-img"/>
        </div>
          <div className="notification-heading">{`Terms & Policy`}</div>
        <div className="terms-and-services-card">
          <div
            className="notification-heading notifications-info"
            style={{ fontSize: "14px" }}
            dangerouslySetInnerHTML={{ __html: policy }}
          ></div>
          </div>
        </div>
        <Footer />
      </IonPage>
    </div>
  );
};

export default PrivacyPolicy;
