import { IonAlert, IonButton, IonIcon, IonInput, IonPage } from "@ionic/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { call } from "../../components/api/axios";
import { RESET_PASSWORD } from "../../components/api/endpoints";
import Header from "../../components/header";
import { setLoading } from "../../redux/actions";
import { closeCircleOutline, lockClosed } from "ionicons/icons";
import "./styles.css";

function ResetPassword(props) {
  console.log(props);
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [password, setPassword] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [confirmPassWord, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const [errMsg, setErrMsg] = useState("");

  const handleForgotPassword = () => {
    if (oldpassword?.trim() === "" || oldpassword?.length === 0) {
      setErrMsg("Please enter old Password");
      setOldPasswordError(true);
      return;
    } else {
      setOldPasswordError(false);
    }
    if (password?.trim() === "" || password?.length === 0) {
      setErrMsg("Please enter password");
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }
    if (password?.length < 4) {
      setErrMsg("Password should be minimum 8 characters");
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }
    if (password !== confirmPassWord) {
      setErrMsg(`Password does not match please check`);
      setConfirmPasswordError(true);
      return;
    } else {
      setConfirmPasswordError(false);
    }

    let forgotPasswordData = {
      user_id: localStorage.getItem("user_id"),
      current_password: oldpassword,
      new_password: password,
    };
    forgotPasswordMethod(forgotPasswordData);
  };

  const forgotPasswordMethod = async (forgotPasswordData) => {
    dispatch(setLoading(true));
    await call(
      {
        ...RESET_PASSWORD,
        url: RESET_PASSWORD.url,
      },
      forgotPasswordData
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          if (res?.data?.error === "true") {
            setError(res?.data?.message);
          } else {
            setShowAlert(true);
            setTimeout(() => {
              let notificationCount;
              notificationCount = localStorage?.getItem("timestamp");
              localStorage.clear();
              localStorage?.setItem("timestamp", notificationCount);
              window.location.reload();
              setShowAlert(false);
            }, 1000);
          }
        } else {
          setError(res?.data?.message);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
        setError(err?.message);
      });
  };

  const handleOldPassword = (e) => {
    setOldPasswordError(false);
    setOldPassword(e?.detail?.value);
  };

  const handlePassword = (e) => {
    setPasswordError(false);
    setPassword(e?.detail?.value);
  };
  const handleConfrimPassword = (e) => {
    setConfirmPasswordError(false);
    setConfirmPassword(e?.detail?.value);
  };

  return (
    <div className="reset-password-container">
      {/* <div className="account-header-container">
        <Header title={`Reset Password`} />
      </div> */}
      <div className="reset-support-home">
        {/* <IonImg
          className="signup-img-tag signup-header reset-img"
          src={process.env.PUBLIC_URL + "/images/logo.png"}
        />
        <div className="signup-header signup-header-text mb-20">
          <div className="signup-create-account">Reset</div>{" "}
          <div className="signup-create-yourself">Password?</div>
        </div> */}
        <div className="reset-cross-icon mr-10">
          <IonIcon
            icon={closeCircleOutline}
            onClick={props.resetPwdModalcancel}
          />
        </div>
        <div className="reset-heading">Reset Password</div>
        <div className="mt-20 reset-password-input-properties">
          {/* <IonLabel position="floating">Old Password</IonLabel> */}
          <IonIcon
            className="reset-password-user-icon"
            icon={lockClosed}
            slot="start"
          />
          <IonInput
            className="reset-password-ionInput"
            placeholder="Enter Old Password"
            type="password"
            name="oldPassword"
            onIonChange={(e) => handleOldPassword(e)}
            clearInput
          ></IonInput>
        </div>
        <div hidden={!oldPasswordError} className="error-message">
          {errMsg}
        </div>
        <div className="mt-10 reset-password-input-properties">
          {/* <IonLabel position="floating">Create New Password</IonLabel> */}
          <IonIcon
            className="reset-password-user-icon"
            slot="start"
            icon={lockClosed}
          />
          <IonInput
            className="reset-password-ionInput"
            placeholder="Create New Password"
            type="password"
            name="password"
            minlength={4}
            onIonChange={(e) => handlePassword(e)}
            defaultValue={password}
            clearInput
          ></IonInput>
        </div>
        <div hidden={!passwordError} className="error-message">
          {errMsg}
        </div>
        <div className="mt-10 reset-password-input-properties">
          {/* <IonLabel position="floating">Confirm New Password</IonLabel> */}
          <IonIcon
            className="reset-password-user-icon"
            slot="start"
            icon={lockClosed}
          />
          <IonInput
            className="reset-password-ionInput"
            placeholder="Re-Enter New Password"
            type="password"
            name="confirmPassWord"
            onIonChange={(e) => handleConfrimPassword(e)}
            defaultValue={confirmPassWord}
            clearInput
          ></IonInput>
        </div>
        <div hidden={!confirmPasswordError} className="error-message">
          {errMsg}
        </div>
        <p className="error-message">{error ? error : null}</p>
        <div className="reset-password-btn">
          <IonButton onClick={() => handleForgotPassword()} id="present-alert">
            Reset Password
          </IonButton>
        </div>
        <div
          className="reset-password-canceltext mt-10"
          onClick={props.resetPwdModalcancel}
        >
          <span>Cancel</span>
        </div>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => {
            setShowAlert(false);
            history.push("/login");
          }}
          cssClass="my-custom-class"
          subHeader={"Your Password Changed Successfully"}
        />
      </div>
    </div>
  );
}
export default ResetPassword;
