import { IonFab, IonFabButton, IonIcon, IonImg, IonPage } from "@ionic/react";
import { add } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { call } from "../../../components/api/axios";
import {
  GET_USER_WEB_ACCOUNT_INFO,
  GET_USER_WEB_ACCOUNT_RECENT_TRANSACTIONS,
} from "../../../components/api/endpoints";
import Transactions from "../../../components/common/transactions";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import { setLoading } from "../../../redux/actions";
import {
  setRecentUserTransactions,
  setWebAccountInfo,
} from "../../../redux/actions/dataActions";
import "./styles.css";
import { eyeOffSharp,eyeSharp } from "ionicons/icons";


function AccountInformation() {
  const dispatch = useDispatch();
  let webAccountInfo = useSelector((state) => state?.data?.webAccountInfo);
  let recentUserTransactions = useSelector(
    (state) => state?.data?.recentUserTransactions
  );
  const history = useHistory();
  const { id, webId } = useParams();

  const [passwordShow, setPasswordShow] = useState(false);
  const [accontInfo, setAccountInfo] = useState(webAccountInfo);
  const [recentTransactions, setRecentTransactions] = useState(
    recentUserTransactions
  );

  const handleCreateTransaction = () => {
    history.push({
      pathname: "/create-transaction-from-user",
      state: {
        website_user_id: accontInfo?.web_user_id,
        website_id: accontInfo?.website_id,
        websiteName: accontInfo?.web_name,
      },
    });
  };

  const getUserWebAccountInfo = async () => {
    accontInfo?.length === 0 && dispatch(setLoading(true));
    await call(
      {
        ...GET_USER_WEB_ACCOUNT_INFO,
        url: GET_USER_WEB_ACCOUNT_INFO.url,
      },
      { user_id: `${localStorage?.getItem("user_id")}`, web_user_id: id }
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          setAccountInfo(res?.data?.data?.result);
          dispatch(setWebAccountInfo(res?.data?.data?.result));
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const getRecentTransactions = async () => {
    recentTransactions?.length === 0 && dispatch(setLoading(true));
    await call(
      {
        ...GET_USER_WEB_ACCOUNT_RECENT_TRANSACTIONS,
        url: GET_USER_WEB_ACCOUNT_RECENT_TRANSACTIONS.url,
      },
      { user_id: `${localStorage?.getItem("user_id")}`, website_id: webId }
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          setRecentTransactions(res?.data?.data?.results);
          dispatch(setRecentUserTransactions(res?.data?.data?.results));
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  useEffect(() => {
    getUserWebAccountInfo();
    getRecentTransactions();
  }, []);

  return (
    <IonPage className="background">
      <div className="account-header-container">
        <Header title={accontInfo?.web_name || "-"} />
        <div>
          <div className="account-page-info-div">
            <div className="account-page-info-heading">
              <span> Account Information </span>
            </div>
            <div className="account-page-info">
              <div className="username-div">
                <div className="ssplay-username-icon">
              <IonImg src='/images/ssplayUserIcon.png' className="ssplay-user-icon"></IonImg>
                <span className="ssplay-username-span">Username</span>
                </div>
                <span>{accontInfo?.web_user_id || "-"}</span>
              </div>
              <div className="username-div">
              <div className="ssplay-username-icon">
              <IonImg src='/images/ssplayPasswordIcon.png' className="ssplay-password-icon"></IonImg>
                <span className="ssplay-username-span">Password</span>
                </div>
                <div className="ssplay-password-eyeicon">
                <span>
                  {passwordShow
                    ? accontInfo?.website_password || "-"
                    : "**********"}
                </span>
                <div
                className="username-div passowrd-view content-right"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                {passwordShow ? <IonIcon icon={eyeSharp} /> : <IonIcon icon={eyeOffSharp} />}
              </div>{" "}
              </div>
              </div>
              
              <div className="account-balance flex-sp-b">
            <span className="balance-heading">
              Account Balance
            </span>
            <span className="balance-clr">
              {" "}
              {`${localStorage?.getItem("currency_code") || ""} ${
                accontInfo?.website_deposits || 0
              }`}
            </span>
          </div>
            </div>
            
          </div>
          
        </div>

        <div className="recent-transaction-heading" style={{ marginTop: "16px" }}>
          Recent Transactions
        
        </div>
        <div className="user-recent-transaction-list">
          <Transactions type="all" data={recentTransactions} />
        </div>
      </div>
      <IonFab
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        className="add-transaction-icon"
        onClick={() => handleCreateTransaction()}
      >
        <IonFabButton className="add-icon">
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
      <Footer />
    </IonPage>
  );
}

export default AccountInformation;
