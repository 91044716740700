export const validation = (value, method, condition) => {
  let validRegex = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;
  switch (method) {
    case "empty":
      return value === "" && "This field is required";
    case "minLen":
      if (value === "") return "This field is required";
      else if (value?.length < condition)
        return `minimum ${condition} characters required`;
      break;
    case "maxLen":
      if (value === "") return "This field is required";
      else if (value?.length > condition)
        return `must not contain more ${condition} characters`;
      break;
    case "minMaxLen":
      if (value === "") return "This field is required";
      else if (value?.length < condition)
        return `minimum ${condition} characters required`;
      else if (value?.length > condition)
        return `must not contain ${condition} characters`;
      break;
    case "pattern":
      return !validRegex.test(value) && "Enter valid email";
      case "compare":
        if (value === "") return "This field is required";
        else if(value !== condition)
        return "Password and Confirm Password does not match ";
    default:
      return false;
  }
};

export const setLocalStorageItems = (data) => {
    Object?.entries(data)?.map(([key, value]) =>
      localStorage?.setItem(key, value)
    );
  };

  
export const isUserVerified = () => {
  let token = localStorage?.getItem("token");
  let isVerified = localStorage?.getItem("isVerified")
  if(token !== undefined && token !== "undefined" && token && isVerified !== undefined && isVerified !== "undefined" && isVerified) {
    return true
  }
  else return  false
}

const UPIType = [
  {
    label: "UPI Name",
    name: "upi_name",
    type: "text",
    placeholder: "Enter UPI Name",
    icon:"/images/upi.png"
  },
  {
    label: "Mobile Number",
    name: "upi_mobile_no",
    type: "number",
    placeholder: "Enter mobile number",
    icon:"/images/telePhone.png"
  },
];

const NEFTType = [
  {
    label: "Account Holder Name",
    name: "bank_acc_holder_name",
    type: "text",
    placeholder: "Enter Account holder name",
    icon:"/images/userImg.png"
  },
  {
    label: "Bank Name",
    name: "bank_name",
    type: "text",
    placeholder: "Enter Bank name",
    icon:"/images/bankIcon.png"
  },
  {
    label: "Bank Account Number",
    name: "bank_account_no",
    type: "number",
    placeholder: "Enter account number",
    icon:"/images/passBook.png"
  },
  {
    label: "IFSC Code",
    name: "bank_ifsc",
    type: "text",
    placeholder: "Enter IFSC code",
    icon:"/images/bankIcon.png"
  },
];
const QRType =[
  {
   label:"QR code",
   name :"QR code",
   type:"file",
  }
];

export const getAddPaymentType = (paymentId)=> {
  switch (paymentId) {
    case 1:
    case 2:
    case 3:
    case 4:
      return UPIType;
    case 5: 
      return NEFTType 
    case 6:
      return QRType
    default:
      return UPIType
  }
}