import { IonButton, IonIcon, IonPage } from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { call } from "../../components/api/axios";
import {
  GET_USER_TRANSACTIONS,
  GET_USER_WALLET_DETAILS,
} from "../../components/api/endpoints";
import { persistData } from "../../components/common/commonData";
import Transactions from "../../components/common/transactions";
import Footer from "../../components/footer";
import Header from "../../components/header";
import {
  setLoading,
  setUserTransactions,
  setUserWallets,
} from "../../redux/actions";

const MultiWallet = () => {
  const backgroundImage = process.env.PUBLIC_URL + "/images/BG.png";
  let result = persistData();
  const {
    userWalletsFlag,
    userWallets,
    userTransactions,
    userTransactionsFlag,
  } = result;
  let userId = localStorage?.getItem("user_id");
  const dispatch = useDispatch();
  const [userWalletData, setUserWalletData] = useState(
    userWallets && userWallets[0]
  );

  const currencyCodes = [
    {
      currency_code: "₹",
      type: "INR Wallet",
    },
    {
      currency_code: "€",
      type: "EURO Wallet",
    },
    {
      currency_code: "﷼",
      type: "SR Wallet",
    },
    {
      currency_code: "$",
      type: "USD Wallet",
    },
  ];
  let wallet = currencyCodes.filter(
    (item) => item.currency_code === localStorage?.getItem("currency_code")
  );
  const [notificationType, setNotificationType] = useState(wallet[0]);
  const [notificationTypeErr, setNotificationTypeErr] = useState(false);
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const [allUserTransactions, setAllUserTransactions] =
    useState(userTransactions);

  const handleTransactionDropDown = () => {
    setNotificationDropDown(!notificationDropDown);
  };

  const handleNotificationType = (transaction) => {
    setNotificationType(transaction);
    setNotificationTypeErr(false);
    setNotificationDropDown(false);
  };

  const getUserWalletDetails = async () => {
    userWalletsFlag && dispatch(setLoading(true));
    await call(GET_USER_WALLET_DETAILS, { user_id: userId })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          dispatch(setUserWallets(res?.data?.data?.result));
          setUserWalletData(res?.data?.data?.result);
          localStorage?.setItem(
            "master_id",
            res?.data?.data?.result?.master_user_id
          );
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err?.message);
      });
  };

  const getUserTransactions = async () => {
    userTransactionsFlag && dispatch(setLoading(true));
    await call(GET_USER_TRANSACTIONS, { user_id: userId })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setUserTransactions(res?.data?.data?.results));
          dispatch(setLoading(false));
          setAllUserTransactions(res?.data?.data?.results);
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  useEffect(() => {
    getUserTransactions();
    getUserWalletDetails();
  }, []);

  return (
    <div className="background wallet-bg">
      <div className="accoutns-notification">
        <Header title="Wallets" />
      </div>

      <div className="multi-wallet-cont border-none">
        <div className="form-conatiner mb-20">
          <div
            className="input-dropdown input-bg login-input-field transaction-input add-account-form"
            onClick={() => handleTransactionDropDown()}
          >
            <div>
              {notificationType?.type !== "" ? (
                <div>
                  <div>{notificationType?.type}</div>
                </div>
              ) : (
                "Transaction Type"
              )}
            </div>
            <div>
              <IonIcon icon={chevronDownOutline} />
            </div>
          </div>
          {notificationDropDown ? (
            <div className="reffered-by-values transaction-input-field add-account-content custom-input-dropdown">
              {wallet?.map((notification, index) => (
                <div key={index}>
                  <div
                    className="referred-by"
                    name="refferdBy"
                    onClick={() => handleNotificationType(notification)}
                  >
                    {notification?.type}
                  </div>
                  <hr className="referred-horizontal-line" />
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div
          className="multi-wallet-container"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <div className="multi-wallet-balance">
            <div className="w-balance">
              Your Wallet Balance
              <div className="balance">{`${
                localStorage?.getItem("currency_code") || ""
              }${userWalletData?.wallet_balance || 0}`}</div>
            </div>
            <div className="wallet-img">
              <img
                src={process.env.PUBLIC_URL + "/images/Wallet-Icon.png "}
                width={30}
                height={30}
              />
            </div>
          </div>
          <div className="multi-wallet-balance-space-btwn mb-10">
            <Link to="/transactions" className="mutli-wallet-deposit">
              <IonButton className="wallet-deposit-btn">
                <div className="deposit-withdraw-title">Deposit</div>
                <div className="ion-margin-horizontal mr-5">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/Deposit-Arrow.png"}
                    className="depo-with-icon"
                  />
                </div>
              </IonButton>
            </Link>

            <Link to="/transactions">
              <IonButton className="wallet-withdraw-btn">
                <div className="deposit-withdraw-title">Withdraw</div>
                <div className="ion-margin-horizontal mr-5">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/Withdraw-Icon.png"}
                    className="depo-with-icon"
                  />
                </div>
              </IonButton>
            </Link>
          </div>
        </div>

        <div className="recent-transactions mt-20">Recent Transactions</div>
        <div className="user-recent-transaction-list multi-wallet-transaction-list">
          <Transactions type="all" data={allUserTransactions} />
        </div>
      </div>

      <Footer />
    </div>
    
  );
};

export default MultiWallet;
