import { IonImg, IonInput, IonItem, IonLabel, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { call } from "../../../components/api/axios";
import { USER_ACCOUNT_LIST } from "../../../components/api/endpoints";
import { persistData } from "../../../components/common/commonData";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import { config } from "../../../config/local_config";
import { setAccountRefferalInfo, setLoading } from "../../../redux/actions";

const AccountReferral = () => {
  let result = persistData();
  const { accountRefferalFlag, accountRefferal } = result;
  const history = useHistory();
  const dispatch = useDispatch();
  const [userAccountList, setUserAccountList] = useState(accountRefferal);

  const acoountReferralInfo = async () => {
    let userData = {
      user_id: `${localStorage?.getItem("user_id")}`,
    };
    accountRefferalFlag && dispatch(setLoading(true));
    await call(
      {
        ...USER_ACCOUNT_LIST,
        url: USER_ACCOUNT_LIST.url,
      },
      userData
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          setUserAccountList(res?.data?.data?.results);
          dispatch(setAccountRefferalInfo(res?.data?.data?.results));
        } else {
          console.log(res);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const navigate = (account) => {
    history.push({
      pathname: `/accounts/add-account/${account?.card_type}`,
      state: {
        accountData: account,
      },
    });
  };

  useEffect(() => {
    acoountReferralInfo();
  }, []);

  let referred_code = localStorage.getItem("referred_code");
  return (
    <IonPage className="background">
      <div className="account-header-container">
        <Header title={`Account`} />
      </div>
      <div className="support-home edit-profile-container">
        <div>
          <div className="list-cards-container">
            {userAccountList?.map((item, index) => {
              if (item?.card_type === 1) {
                return (
                  <IonItem
                    onClick={() => navigate(item)}
                    className={`mb-20 mt-20 transaction-input existing-upi`}
                    key={index}
                  >
                    <span>{item?.card_name}</span> - {item?.upi_name}
                  </IonItem>
                );
              }
              if (item?.card_type === 2) {
                {
                  return (
                    <IonItem
                      onClick={() => navigate(item)}
                      className={`mb-5 mt-20 transaction-input existing-upi`}
                      key={index}
                    >
                      <span>{item?.card_name} - </span>
                      <span>{item?.bank_account_no}</span>
                    </IonItem>
                  );
                }
              }
              if (item?.card_type === 3) {
                return (
                  <IonItem
                    onClick={() => navigate(item)}
                    className={`mb-5 mt-20 transaction-input existing-upi`}
                    key={index}
                  >
                    <span>{item?.card_name}- </span>
                    <span>{item?.atm_card}</span>
                  </IonItem>
                );
              }

              if (item?.card_type === 4) {
                return (
                  <IonItem
                    onClick={() => navigate(item)}
                    className={`mb-5 mt-20 transaction-input existing-upi`}
                    key={index}
                  >
                    <span>{item?.card_name}- </span>
                    <span>{item?.atm_card}</span>
                  </IonItem>
                );
              }
            })}
          </div>
          <div className="mt-10">
            <Link to="/accounts/add-account" style={{ textDecoration: "none" }}>
              <IonLabel className="transaction-details mt-20">
                {" "}
                ADD NEW CARD
              </IonLabel>
            </Link>
          </div>
          <div className="line-breaker-account" />
          <IonLabel className="invite-friend-heading">{`Invite your Friends to ${config.TITLE}`}</IonLabel>
          <IonItem className={`mb-5 mt-20 transaction-input`}>
            <IonLabel position="floating">REFFERAL CODE</IonLabel>
            <IonInput
              placeholder="XYZQWERTY"
              type="text"
              name="savedCard"
              value={referred_code || ""}
              disabled
              onIonChange={(e) => {
                  // setContact(e.target.value);
                  // setContactErr(false);
              }}
              // value={contact}
              clearInput
            ></IonInput>
          </IonItem>
        </div>
        <div className="info-div">
          <p>Dear User,</p>
          <p>
            We are working on a robust referral system but in the meantime do
            let us know whenever you refer someone by using chat option
          </p>
        </div>
        <div className="copy-invitation-container reset-password-div">
          <div>
            <IonImg
              className="notification-logo ml-20 mr-10"
              src={process.env.PUBLIC_URL + "/images/user_profile.svg"}
            />
          </div>
          <Link to="/reset-password" style={{ textDecoration: "none" }}>
            <div className="notification-heading mb-5 ">{"Reset Password"}</div>
          </Link>
        </div>
      </div>
      <Footer />
    </IonPage>
  );
};

export default AccountReferral;
