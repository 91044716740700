import {
  IonAvatar,
  IonButton,
  IonContent,
  IonIcon,
  IonImg,
  IonModal,
  IonPage,
  useIonAlert,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { call } from "../../components/api/axios";
import { GET_USER_PROFILE_INFO } from "../../components/api/endpoints";
import Footer from "../../components/footer";
// import Header from "../../components/header";
import { config } from "../../config/local_config";
import { setLoading, setProfileDataReducer } from "../../redux/actions";
import "./styles.css";
import { chevronForwardSharp, chevronDownOutline } from "ionicons/icons";
import AddCardOrAccount from "../accounts/add-account";
import ResetPassword from "../forgot-password/ResetPassword";
import CreateTransaction from "../transactions/create-transaction";

function Profile() {
  const [resetPwdModal, setResetPwdmodal] = useState(false);
  const handleResetPassword = () => {
    setResetPwdmodal(true);
  };
  const handleResetPasswordCancel = () => {
    setResetPwdmodal(false);
  };
  let profileDataReducer = useSelector((state) => state?.data?.profileData);
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState(profileDataReducer);
  const [presentAlert] = useIonAlert();
  const [showAddPayment, setShowAddPayment] = useState(false);
  const [paymentDropDown, setPaymentDropDown] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [accountType, setAccountType] = useState(1);
  const [showModel, setShowModel] = useState(false);

  const history = useHistory();

  const handleShowAddPayment = () => {
    setShowAddPayment(true);
  };

  const handleLogout = () => {
    let notificationCount;
    notificationCount = localStorage?.getItem("timestamp");
    localStorage.clear();
    localStorage?.setItem("timestamp", notificationCount);
    window.location.reload();
  };
  const handleCreateTransaction = () => {
    setShowModel(true);
  };
  const handleCreateTransactionCancel = () => {
    setShowModel(false);
  };
  const arr = [
    // {
    //   icon: "/images/userAccount.png",
    //   title: `${config.TITLE} Account`,
    //   content: "Payments Methods",
    //   path: "/accounts/referral",
    // },
    {
      icon: "/images/resetPassword.png",
      title: "Reset Password",
      content: "Account Setting",
      path: "",
    },
    {
      icon: "/images/referAndWin.png",
      title: "Refer & Win",
      content: "Referrals Bonus",
      path: "/refer-your-friend",
    },
    // {
    //   icon: "/images/profileNotification.png",
    //   title: "Notifications",
    //   content: "Message, group & call tones",
    //   path: "/notification/update",
    // },
    {
      icon: "/images/termsAndPolicy.png",
      title: "Terms & Policy",
      content: "Terms, Privacy Policy, Network usage",
      path: "/terms-and-services",
    },
    {
      icon: "/images/helpIcon.png",
      title: "Help",
      content: "Help center, contact us, privacy policy",
      path: "/support",
    },
  ];

  const balnceInformation = [
    // {
    //   icon: "/images/walletBalance.png",
    //   title: "Wallet Balance",
    //   amount: 10000,
    // },
    // {
    //   icon: "/images/availableBalance.png",
    //   title: "Available Balance",
    //   amount: 10000,
    // },
    {
      icon: "/images/referalmembers.png",
      title: "Referal Members",
      amount: 50,
    },
    // {
    //   icon: "/images/referalBonus.png",
    //   title: "Referal Bonus",
    //   amount: 50000,
    // },
    // {
    //   icon: "/images/depositBonus.png",
    //   title: "Deposite Bonus",
    //   amount: 10000,
    // },
  ];
  var paymentMethodType = [
    {
      icon: "/images/phonePe.png",
      type: " Phone Pay",
      id: 1,
    },
    {
      icon: "/images/googlePay.png",
      type: " Google Pay",
      id: 2,
    },
    {
      icon: "/images/amazonPay.png",
      type: " Amazon Pay",
      id: 3,
    },
    {
      icon: "/images/bharatPe.png",
      type: " Bharath Pay",
      id: 4,
    },
    {
      icon: "/images/bankIcon.png",
      type: " NEFT/RTGS",
      id: 5,
    },
    {
      icon: "/images/qrCode.png",
      type: " QR Code",
      id: 6,
    },
    {
      icon: "/images/others.png",
      type: " Others",
      id: 7,
    },
  ];
  const handlePaymentDropDown = () => {
    setPaymentDropDown(!paymentDropDown);
  };

  const handlePaymentMethod = (payment) => {
    // setPaymentTypeId(payment?.id);
    setPaymentType(payment?.type);
    // setPaymentTypeErr(false);
    setPaymentDropDown(false);
    // console.log({payment})
    setAccountType(payment?.id);
  };

  useEffect(() => {
    getProfileInfo();
  }, []);

  const getProfileInfo = async () => {
    profileData?.length === 0 && dispatch(setLoading(true));
    await call(
      {
        ...GET_USER_PROFILE_INFO,
        url: GET_USER_PROFILE_INFO?.url,
      },
      { user_id: `${localStorage?.getItem("user_id")}` }
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          dispatch(setProfileDataReducer(res?.data?.data?.result));
          setProfileData(res?.data?.data?.result);
          localStorage?.setItem(
            "referred_code",
            res?.data?.data?.result?.referred_code
          );
        } else {
          console.log(res?.data?.message);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  return (
    <IonPage className="edit-profile-background">
      <div className="profile-container mt-20">
        <IonContent className="background-none">
          <div
            className="edit-profile-closeIcon"
            onClick={() =>
              history.push({
                pathname: "/#",
                state: {
                  data: profileData,
                },
              })
            }
          >
            <IonIcon icon={chevronForwardSharp} />
          </div>
          <div className="profile-page">
            <div className="profile-icon mr-5">
              <IonImg
                className="edit-profile-avatar"
                src={
                  profileData?.display_picture != "NA"
                    ? profileData?.display_picture
                    : "/images/profileImage.png"
                }
              />
            </div>
            <div className="details">
              <div className="edit-profile-dispaly-flex">
                <div className="user-name">
                  {profileData?.user_name || "N/A"}
                </div>
                <div>
                  <div
                    className="edit-profile-editIcon mt-5"
                    onClick={() =>
                      history.push({
                        pathname: "/edit-profile",
                        state: {
                          data: profileData,
                        },
                      })
                    }
                  >
                    <IonImg
                      className="edit-logo mr-5"
                      src={process.env.PUBLIC_URL + "/images/edit_icon.svg"}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="user-contact">
                {profileData?.auth_token_ts || "N/A"}
              </div>
              <div className="user-details">
                <div className="user-mail">{profileData?.emailId || "N/A"}</div>
                <div className="user-eamil-edit">
                  <div
                    className="edit-profile-editIcon"
                    onClick={() =>
                      history.push({
                        pathname: "/edit-profile",
                        state: {
                          data: profileData,
                        },
                      })
                    }
                  >
                    <IonImg
                      className="edit-logo mr-5"
                      src={process.env.PUBLIC_URL + "/images/edit_icon.svg"}
                    />
                  </div>
                  <div
                    className="edit-profile"
                    onClick={() =>
                      history.push({
                        pathname: "/edit-profile",
                        state: {
                          data: profileData,
                        },
                      })
                    }
                  >
                    Edit Profile
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <hr className="profile-notification-border mb-0 mt-5" />

          <div className="notification-list-container edit-profile-wallet-div">
            <div className="edit-profile-balance-heading mb-10">
              Balance Information
            </div>
            {balnceInformation?.map((ele, index) => (
              <div key={index} className="edit-profile-wallet mb-5 mt-5">
                <div className="edit-profile-image-div">
                  <div>
                    <IonImg
                      className="notification-logo mr-20 edit-profile-images-bg"
                      src={process.env.PUBLIC_URL + ele.icon}
                    />
                  </div>
                  <div className="wallet-balance-font">{ele.title}</div>
                </div>
                <div className="edit-profile-wallet-balance">
                  <span
                    className={
                      index < 2 ? "wallet-balance-color" : "other-wallet-color"
                    }
                  >
                    {ele.amount}
                  </span>
                </div>
              </div>
            ))}
            <div className="edit-profile-buttons-div">
              <div className="deposite-button">
                <IonButton
                  fill="clear"
                  onClick={() => handleCreateTransaction()}
                >
                  Deposit
                </IonButton>
              </div>
              <div className="withdraw-button">
                <IonButton
                  fill="clear"
                  onClick={() => handleCreateTransaction()}
                >
                  Withdraw
                </IonButton>
              </div>
            </div>
          </div>

          <hr className="profile-notification-border mt-0 mb-5" />

          <div className="bg-color">
            <div className="notification-list-container">
              <div className="notification-list" onClick={handleShowAddPayment}>
                <div>
                  <IonImg
                    className="notification-logo mr-20 edit-profile-images-bg"
                    src={process.env.PUBLIC_URL + "/images/userAccount.png"}
                  />
                </div>
                <div>
                  <div className="notification-heading mb-5">Add Account</div>
                  <div className="notification-content">Payments Methods</div>
                </div>
              </div>
              <center>
                <IonModal className="Add-Payment-Modal" isOpen={showAddPayment}>
                  <div className="create-transaction-header ml-20 mb-10">
                    Add account/UPI
                  </div>
                  <div
                    className="input-dropdown login-input-field transaction-input mt-20 add-account-form"
                    style={{
                      borderRadius: "10px",
                    }}
                    onClick={() => handlePaymentDropDown()}
                  >
                    <div className="display-flex-property">
                      <img
                        className="id-details-icons"
                        src={
                          process.env.PUBLIC_URL + "/images/paymentMethod.png"
                        }
                      />
                      <div>{paymentType ? paymentType : "Payment Type"}</div>
                    </div>
                    <div>
                      <IonIcon icon={chevronDownOutline} />
                    </div>
                  </div>
                  {paymentDropDown && (
                    <div
                      id="payment-type-container"
                      className="login-input-field reffered-by-values transaction-input-field add-account-content"
                    >
                      {paymentMethodType?.map((payment, index) => (
                        <div key={index}>
                          <div
                            className="reffered-by-value payment-type"
                            name="refferdBy"
                            onClick={() => handlePaymentMethod(payment)}
                          >
                            <div>
                              <img
                                className="id-details-icons"
                                src={process.env.PUBLIC_URL + payment.icon}
                              ></img>
                              {payment?.type}
                            </div>
                            <div className="select-payment-type">Select</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <AddCardOrAccount accountType={accountType} />
                </IonModal>
              </center>
              {arr?.map((ele, index) => {
                return (
                  <>
                    {ele.title === "Reset Password" ? (
                      <div
                        onClick={handleResetPassword}
                        key={index}
                        className="notification-list"
                      >
                        <div>
                          <IonImg
                            className="notification-logo mr-20 edit-profile-images-bg"
                            src={process.env.PUBLIC_URL + ele?.icon}
                          />
                        </div>
                        <div>
                          <div className="notification-heading mb-5">
                            {ele?.title}
                          </div>
                          <div className="notification-content">
                            {ele?.content}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Link
                        to={ele?.path}
                        key={index}
                        className="notification-list"
                      >
                        <div>
                          <IonImg
                            className="notification-logo mr-20 edit-profile-images-bg"
                            src={process.env.PUBLIC_URL + ele?.icon}
                          />
                        </div>
                        <div>
                          <div className="notification-heading mb-5">
                            {ele?.title}
                          </div>
                          <div className="notification-content">
                            {ele?.content}
                          </div>
                        </div>
                      </Link>
                    )}
                  </>
                );
              })}
            </div>
            <div
              className="logout-container color-bg"
              onClick={() => {
                presentAlert({
                  header: "Logging out...",
                });
                handleLogout();
              }}
            >
              <div>
                <IonImg
                  className="notification-logo mr-20 edit-profile-images-bg"
                  src={process.env.PUBLIC_URL + "/images/logoutIcon.png"}
                />
              </div>
              <div>
                <div className="notification-heading mb-5">Logout</div>
              </div>
            </div>

            {/* <div className="version-details notification-content">
              Last updated - Nov 10, 2022
              <div>App Version - 2.012</div>
            </div> */}
          </div>
          <IonModal
            isOpen={resetPwdModal}
            isDidDismiss={() => setResetPwdmodal(false)}
            className="reset-password-modal"
          >
            <ResetPassword resetPwdModalcancel={handleResetPasswordCancel} />
          </IonModal>
          <IonModal className="create-transaction-model" isOpen={showModel}>
            <CreateTransaction
              handleCreateTransactionCancel={handleCreateTransactionCancel}
            />
          </IonModal>
        </IonContent>
      </div>
      <Footer />
    </IonPage>
  );
}

export default Profile;
