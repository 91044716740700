import { eventEmitter } from "../../utils/AppUtils";
const axios = require("axios");

const base_url = "https://46tozgmoig.execute-api.eu-west-1.amazonaws.com/prod";

export const call = async (
  config = { method: "GET" },
  data = {},
  params = {}
) => {
  let url = base_url + config.url;

  if (config.method === "GET" && data && Object.keys(data).length > 0) {
    url += "?";
    Object.entries(data).forEach(([key, value]) => {
      url += `${key}=${value}&`;
    });
  }
  if (params && Object.keys(params).length > 0) {
    url += "?";
    Object.entries(params).forEach(([key, value]) => {
      url += `${key}=${value}&`;
    });
  }
  if (url.substr(url.length - 1, url.length) === "&") {
    url = url.slice(0, url.length - 1);
  }
  const options = {
    method: config.method,
    url,
    mode: "cors",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    timeout: 60000,
    headers: {
      Authorization: localStorage?.getItem("token"),
    },
  };
  // if (url.includes('profile-get-policy')) {
  //   options.headers = {
  //     auth_token: localStorage?.getItem('token')
  //   }
  // }
  if (["POST", "PUT", "DELETE"].indexOf(config.method) > -1) {
    options.data = data;
  }
  const res = await axios(options);
  if (res?.data?.statusCode === 403) {
    eventEmitter.emit("toast-message", res?.data?.message);
    setTimeout(() => {
      localStorage.clear();
      window.location.href = "/";
    }, 3000);
  }
  return res;
};
