import { IonContent, IonImg, IonPage } from "@ionic/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { call } from "../../components/api/axios";
import { GET_OFFERS_AND_NOTIFICATIONS } from "../../components/api/endpoints";
import { persistData } from "../../components/common/commonData";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { setLoading, setNotiications } from "../../redux/actions";
import { useHistory } from "react-router";

function Offers() {
  let result = persistData();
  const { notificationData, notificationDataFlag } = result;
  const dispatch = useDispatch();
const history=useHistory();
  const [allNotficationData, setNotificationData] = useState(notificationData);
  const [notificationCount, setNotificationCount] = useState(0);

  const getOffersAndNotifications = async () => {
    notificationDataFlag && dispatch(setLoading(true));
    await call(GET_OFFERS_AND_NOTIFICATIONS, {
      user_id: localStorage?.getItem("user_id"),
    })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));

          let results = res?.data?.data?.results;
          results?.forEach((notification) => {
            if (
              moment(notification?.create_at).format("YYYY-MM-DD  hh:mm:ss") <
              localStorage.getItem("timestamp")
            )
              setNotificationCount((prev) => prev + 1);
          });
          setNotificationData(results);
          dispatch(setNotiications(results));
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  useEffect(() => {
    localStorage.setItem(
      "timestamp",
      moment(new Date()).format("YYYY-MM-DD  hh:mm:ss")
    );
    getOffersAndNotifications();
  }, []);

  return (
    <IonPage className="background">
      <IonContent className="background-none">
        <div className="account-header-container">
          <Header title={"Offers"} page="offers" />
          <hr className="notification-border" />
        </div>
        <div>
          {allNotficationData?.length > 0 &&
            allNotficationData.map((notification, i) => {
              return (
                <div
                  className="notifications-view-all"
                  key={i}
                  onClick={notification?.offer_name==="REFERAL UR FRIEND"?()=>history.push("/refer-your-friend"):null}
                >
                  <div>
                    <IonImg
                      className="offer-img"
                      src={process.env.PUBLIC_URL + "/images/offer_logo.svg"}
                    />
                  </div>
                  <div className="ml-20">
                    <div className="website-name mb-5">
                      {notification?.offer_name || ""}
                    </div>
                    <div className="website-username">
                      {notification?.offer_description || ""}
                    </div>
                    <div className="website-username">
                      <span className="green-clr">Created On</span>:{" "}
                      {notification?.offer_date || ""}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {allNotficationData?.length === 0 && (
          <div className="transaction-no-data-found">No Data Found</div>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
}

export default Offers;
