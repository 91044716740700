import moment from "moment";
import { config } from "../../../config/local_config";
import { TRANX_STATUS } from "../../../utils/AppUtils";
import "./styles.css";

const COLOR_CLASS = {
  1: "warn",
  2: "success",
  3: "error",
};
const TRNX_TYPE = {
  INTERNAL_DEPOSIT: 1,
  INTERNAL_WITHDRAWL: 2,
  EXTERNAL_DEPOSIT: 3,
  EXTERNAL_WITHDRAWL: 4,
};

export default function Transactions({ type, data }) {
  let allTransactions =
    type === "all"
      ? data
      : data?.filter((transaction) => transaction?.trnx_type == type);
  if (type === "Texch") {
    allTransactions = data?.filter(
      (transaction) =>
        transaction?.trnx_type == TRNX_TYPE.INTERNAL_DEPOSIT ||
        transaction?.trnx_type == TRNX_TYPE.INTERNAL_WITHDRAWL
    );
  } else if (type === "Web") {
    allTransactions = data?.filter(
      (transaction) =>
        transaction?.trnx_type == TRNX_TYPE.EXTERNAL_DEPOSIT ||
        transaction?.trnx_type == TRNX_TYPE.EXTERNAL_WITHDRAWL
    );
  }

  return (
    <div>
      {allTransactions && allTransactions.length > 0 ? (
        allTransactions?.map((transaction, index) => {
          let transactionType =
            transaction?.trnx_type === TRNX_TYPE.EXTERNAL_DEPOSIT
              ? "Web Deposit"
              : transaction?.trnx_type === TRNX_TYPE.INTERNAL_DEPOSIT
              ? `${config.TITLE} Deposit`
              : transaction?.trnx_type === TRNX_TYPE.EXTERNAL_WITHDRAWL
              ? "Web Withdrawl"
              : transaction?.trnx_type === TRNX_TYPE.INTERNAL_WITHDRAWL
              ?  `${config.TITLE} Withdrawl`
              : "";
          return (
            <div key={index} className="your-accounts-container">
              <div className="accounts-box">
                <div className="accounts-content">
                  <div className="account-content-text">
                    <div className="website-name mb-5">
                      {transactionType} - {transaction?.payment_method}
                    </div>
                    <div className="website-username">
                      {`${moment(transaction?.update_at).format(
                        "MMM Do YYYY, hh:mm A"
                      )} | Trxn ID - ${
                        transaction?.trnx_id.substring(0, 10) +
                        "....." +
                        transaction?.trnx_id.substring(
                          transaction?.trnx_id.length - 5,
                          transaction?.trnx_id.length
                        )
                      }`}
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className={`${
                      transactionType.includes("Deposit")
                        ? ""
                        : "transaction-details"
                    } accounts-view`}
                  >
                    {transactionType.includes("Deposit")
                      ? `+ ${localStorage?.getItem("currency_code") || ""}`
                      : `- ${
                          localStorage?.getItem("currency_code") || ""
                        }`}{" "}
                    {transaction?.trnx_amount}
                  </div>
                  <div
                    className={`font-13 ${
                      COLOR_CLASS[transaction.trnx_status]
                    }`}
                  >
                    {TRANX_STATUS[transaction.trnx_status]}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="transaction-no-data-found">No Data Found</div>
      )}
    </div>
  );
}
