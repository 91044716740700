import {
  IonButton,
  IonCheckbox,
  IonImg,
  IonModal,
  IonPage,
} from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";

function SuccessfulSignUp(props) {
  let history = useHistory();
  const [flag, setFlag] = useState(true);

  const arr = [
    {
      value: "lotusbook7.com",
      isChecked: true,
    },
    {
      value: "lotusbookechg.com",
      isChecked: true,
    },
    {
      value: "lotusexchange.com",
      isChecked: true,
    },
    {
      value: "lotusexchange247.com",
      isChecked: true,
    },
    {
      value: "lotusexchange365.com",
      isChecked: true,
    },
  ];

  const handleSubmit = async () => {
    await setFlag(false);
    history.push("/");
  };

  const handleSkipForNow = async () => {
    await setFlag(false);
    history.push("/");
  };

  return (
    <IonPage style={{ overFlowY: "auto" }}>
      <div className="succesful-signup-model-container">
        <IonImg
          className="img-tag"
          src={process.env.PUBLIC_URL + "/images/logo.png"}
        />
        <IonModal isOpen={flag} className="succesful-signup-model">
          <div className="successful-model-content">
            <div className="successful-model-text-heading">
              <div>Congratulations!</div>
              <div>Your account has been</div>
              <div>created succesfuly.</div>
            </div>
            <p className="successful-model-text-content mt-20 mb-20">
              You can create IDs for different websites in single click, please
              select the websites you want to proceed with.
            </p>
            <div className="default-website-content mt-20 mb-30">
              {arr?.map((ele, index) => (
                <div key={index} className="default-website-names mt-10 mb-10">
                  <div className="website-checkbox mr-10">
                    <IonCheckbox
                      slot="end"
                      name={ele.value}
                      checked={ele?.isChecked}
                      //   onIonChange={(e) => handleCheckBox(e)}
                    />
                  </div>
                  <div>{ele?.value}</div>
                </div>
              ))}
            </div>
            <div className="login-btn mt-40 mb-30">
              <IonButton onClick={() => handleSubmit()}>Submit</IonButton>
            </div>
            <div className="skip-for-now text-center mb-20 mt-40">
              <span onClick={() => handleSkipForNow()}>Skip for Now</span>
            </div>
          </div>
        </IonModal>
      </div>
    </IonPage>
  );
}

export default SuccessfulSignUp;
