import React, { useState } from "react";
import Footer from "../../components/footer";
import { IonContent, IonImg, IonPage } from "@ionic/react";
import MainHeader from "../home/MainHeader";
import ProfitLoss from "./ProfitLoss";
import MyWallet from "./MyWallet";
import FancyReports from "./FancyReports";
import AccountStatement from "./AccountStatement";
import ActivityLogs from "./ActivityLogs";
import MyBets from "./MyBets";
import { isUserVerified } from "../../helper";
import "./Styles.css";
import AccountInformation from "../home/accountInfo";
import { useHistory } from "react-router";
import CustomStakeButtons from "./CustomStakeButtons";
import CasinoReports from "./CasinoReports";
import CasinoWallet from "./CasinoWallet";

function TexchWebSitesReports() {
  const history = useHistory();

  const reportsContainers = [
    {
      img: "/images/myBets.png",
      name: "My Bet",
      id: "my_bet",
    },
    {
      img: "/images/profit&LossIcon.png",
      name: "Profit & Loss",
      id: "profit_loss",
    },
    {
      img: "/images/myWalletImg.png",
      name: "My Wallet",
      id: "my_wallet",
    },
    {
      img: "/images/myWalletImg.png",
      name: "Casino Wallet",
      id: "casino_wallet",
    },
    {
      img: "/images/accountStatementIcon.png",
      name: "Account Statement",
      id: "accout_statement",
    },
    {
      img: "/images/casinoReport.png",
      name: "Casino Report",
      id: "casino_reports",
    },
    {
      img: "/images/fancyReport.png",
      name: "Fancy Report",
      id: "fancy_report",
    },
    {
      img: "/images/activityLogs.png",
      name: "Activity Logs",
      id: "activity_log",
    },
    {
      img: "/images/customStakeIcon.png",
      name: "Custom Buttons",
      id: "custom_stake_buttons",
    },
  ];
  const activeTab = [
    {
      id: 0,
      tab: <MyBets />,
    },
    {
      id: 1,
      tab: <ProfitLoss />,
    },
    {
      id: 2,
      tab: <MyWallet />,
    },
    {
      id: 3,
      tab: <CasinoWallet />,
    },
    {
      id: 4,
      tab: <AccountStatement />,
    },
    {
      id: 5,
      tab: <CasinoReports />,
    },
    {
      id: 6,
      tab: <FancyReports />,
    },
    {
      id: 7,
      tab: <ActivityLogs />,
    },
    {
      id: 8,
      tab: <CustomStakeButtons />,
    },
  ];

  let menuIndex = reportsContainers.findIndex(
    (itm) => itm.id === history.location?.state?.menuitems
  );
  menuIndex = menuIndex <= 0 ? 0 : menuIndex;
  const [active, setActive] = useState(menuIndex);
  const handleActiveState = (index) => {
    setActive(index);
  };
  return (
    <>
      <IonPage className="homepage-bg">
        <IonContent className="homepage-bg">
          <MainHeader notificationCount={0} userWalletData={100000000} />
          <div>
            <div className="reports-main-box">
              <div className="flex reports-scroll-container ">
                {reportsContainers.map((value, index) => {
                  return (
                    <div
                      className={`${
                        active == index ? "active-report" : "report-container"
                      }`}
                      key={index}
                      onClick={() => handleActiveState(index)}
                    >
                      <IonImg
                        className="reports-image"
                        src={process.env.PUBLIC_URL + value.img}
                        alt="report-icons"
                      ></IonImg>
                      <div className="reports-text">{value.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {activeTab?.map(({ id, tab }) => {
            return id === active && tab;
          })}
        </IonContent>
        <Footer />
      </IonPage>
    </>
  );
}

export default TexchWebSitesReports;
