import { useState } from "react";
import "./Styles.css";
import { IonIcon, IonImg } from "@ionic/react";
import {
  chevronDownCircleOutline,
  chevronUpCircleOutline,
} from "ionicons/icons";
function ActivityLogs() {
  const activityLogContainers = [
    {
      id: "1",
      img: "/images/tCasinoParkLogo.png",
      name: "T Casino Park",
    },
    {
      id: "2",
      img: "/images/spark999Logo.png",
      name: "Spark999",
    },
    {
      id: "3",
      img: "/images/spark247Logo.png",
      name: "Spark247",
    },
    {
      id: "4",
      img: "/images/tCasinoParkLogo.png",
      name: "T Casino Park",
    },
  ];
  const activeLogInsideData = [
    {
      id: "1",
      leftSideData: "Login Date & Time",
      rightSideData: "24-05-2023 | 10:30:22 am",
    },
    {
      id: "2",
      leftSideData: "Login Status",
      rightSideData: "Success",
    },
    {
      id: "3",
      leftSideData: "IP Adress",
      rightSideData: "183.82.119.59",
    },
    {
      id: "4",
      leftSideData: "Device Info Platform",
      rightSideData:
        "Android 6.0 Nexus 5 Chrome Mobile 112.0.0,Time Zone Asia/Kolkata,India",
    },
    {
      id: "1",
      leftSideData: "Website",
      rightSideData: "https://tcasinopark.club",
    },
  ];
  const [activeLogData, setActiveLogData] = useState("");
  const [activeIndex, setActiveIndex] = useState(false);
  const handleActiveLogDta = (value) => {
    setActiveLogData(value);
    setActiveIndex(!activeIndex);
  };
  return (
    <div className="profit-loss-container">
      <h1 className="activityLog-user-text">User Activity (Last 15 Logins)</h1>
      <div>
        <div className="activity-log-main-div">
          {activityLogContainers.map((value, index) => (
            <div key={index}>
              <div className="activity-log-text-img-div mb-10">
                <div className="activitylog-text-img-arrow-div">
                  <div className="activitylog-game-name">{value.name}</div>
                  <div className="activitylog-img-arrow">
                    <IonImg
                      className="activitylog-games-image"
                      src={process.env.PUBLIC_URL + value.img}
                    ></IonImg>
                    <IonIcon
                      className="activitylog-arrow"
                      icon={
                        activeLogData === value.id && activeIndex
                          ? chevronUpCircleOutline
                          : chevronDownCircleOutline
                      }
                      onClick={() => handleActiveLogDta(value.id)}
                    />
                  </div>
                </div>
                <div>
                  {activeLogData === value.id && activeIndex && (
                    <div className="activeLog-inside-text">
                      {activeLogInsideData.map((value, index) => (
                        <div
                          key={index}
                          className="activeLog-left-right-text mb-5"
                        >
                          <span>{value.leftSideData}</span>
                          <span
                            style={{
                              color:
                                value.rightSideData ===
                                "https://tcasinopark.club"
                                  ? "#f0da2c"
                                  : "",
                              textAlign: "right",
                              width: "142px",
                            }}
                          >
                            {value.rightSideData}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ActivityLogs;
