import React, { useState } from 'react'
import "./style.css"
import videoIcon from "./assents/videoIcon.png"
import callIcon from "./assents/callIcon.png"
import moreIcon from "./assents/moreIcon.png"
import micIcon from "./assents/micIcon.png"
import info from "./assents/info.png"
import chat from "./assents/chatIcon.png"
import micOff from "./assents/micOffIcon.png"
import videoOffImg from "./assents/videoOffIcon.png"

function VideoCallButtons() {
    const [showMoreOption,setShowMoreOption]=useState(false)
    const [muteAudio,setMuteAudio]=useState(true)
    const [videoOff,setVideoOff]=useState(true)
    const handleclick=()=>{
          setShowMoreOption((prev)=>!prev);
    }
    const handleMute=()=>{
        setMuteAudio((prev)=>!prev)
    }
    const handleVideoOff=()=>{
        setVideoOff((prev)=>!prev)
    }
  return (
    <div className='video-call-controlls'>
        <center>
        <div className='mic-button-conatiner'>
         <div className='mic-button'>
         <div onClick={handleMute} className='mic-icon'>
            {muteAudio ? ( <img src={micIcon} alt='mic-img'/>):( <img src={micOff} alt='mic-img'/>)}
        
         </div>
         </div>
         <div className='more-options'>
            {showMoreOption&&<div>  
                <img className='info-icon' src={info}></img>
                <img className='chat-icon' src={chat}></img>
            </div>}
         </div>
        </div>
        <div className='call-buttons-container'>
            <div onClick={handleVideoOff} className='video-call-icon'>
            {videoOff ? <img className='video-call-on' src={videoIcon} alt='video-img'/>:<img className='video-call-off' src={videoOffImg} alt='video-img'/>}
            </div>
            <div className='call-button-icon'>
            <img src={callIcon} alt='video-img'/>
            <span>10:15</span>
            </div>
            <div onClick={handleclick} className='more-button-icon'>
            <img src={moreIcon} alt='video-img'/>
            </div>
        </div>
        </center>
    </div>
  )
}

export default VideoCallButtons