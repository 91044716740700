import * as actionTypes from "./actionConstants";

export const setProfileDataReducer = (data) => {
  return {
    type: actionTypes.SET_PROFILE_DATA,
    data,
  };
};

export const setOffersAndNotification = (data) => {
  return {
    type: actionTypes.SET_OFFERS_AND_NOTIFICATIONS,
    data,
  };
};

export const setUserWallets = (data) => {
  return {
    type: actionTypes.SET_USER_WALLETS,
    data,
  };
};

export const setUpcomingEvents = (data) => {
  return {
    type: actionTypes.SET_UPCOMING_EVENTS,
    data,
  };
};

export const setUserTransactions = (data) => {
  return {
    type: actionTypes.SET_USER_TRANSACTIONS,
    data,
  };
};

export const setWebAccountsList = (data) => {
  return {
    type: actionTypes.SET_WEB_ACCOUNT_LIST,
    data,
  };
};

export const setWebAccountRegisteredList = (data) => {
  return {
    type: actionTypes.SET_WEB_ACCOUNT_REGISTERED_LIST,
    data,
  };
};

export const setAccountRefferalInfo = (data) => {
  return {
    type: actionTypes.SET_ACCOUNT_REFFERAL,
    data,
  };
};

export const setALlTicktes = (data) => {
  return {
    type: actionTypes.SET_ALL_TICKETS,
    data,
  };
};

export const setALlMeetings = (data) => {
  return {
    type: actionTypes.SET_ALL_MEETINGS,
    data,
  };
};

export const setSupportData = (data) => {
  return {
    type: actionTypes.SET_SUPPORT_DATA,
    data,
  };
};

export const setRecentUserTransactions = (data) => {
  return {
    type: actionTypes.RECENT_USER_TRANSACTIONS,
    data,
  };
};

export const setWebAccountInfo = (data) => {
  return {
    type: actionTypes.WEB_ACCOUNT_INFO,
    data,
  };
};


export const setPrivacyPolicy = (data) => {
  return {
    type: actionTypes.SET_PRIVACY_POLICY,
    data,
  };
};

export const setNotiications = (data) => {
  return {
    type: actionTypes.SET_NOTIFICATIONS,
    data,
  };
};

export const setMeetingStartStatus = (data) => {
  return {
      type:actionTypes.SET_MEETING_IS_STARTED,
      data
  }
}

export const setMinimize = (data) => {
  return {
      type:actionTypes.SET_MINIMIZE,
      data
  }
}
export const setMaxmize = (data) => {
  return {
      type:actionTypes.SET_MAXMIZE,
      data
  }
}
export const setLoginModal = (data) => {
  return {
      type:actionTypes.SET_LOGIN_MODAL,
      data
  }
}

export const setAuthenticatorModal = (data) => {
  return {
      type:actionTypes.SET_AUTHENTICATOR_MODAL,
      data
  }
}