import moment from "moment";
import { config } from "../../../config/local_config";
import { TRANX_STATUS } from "../../../utils/AppUtils";

const COLOR_CLASS = {
  1: "success",
  0: "error",
  2: "web-site-name",
  3: "error"
};

const TRNX_TYPE = {
    INTERNAL_DEPOSIT: 1,
    INTERNAL_WITHDRAWL: 2,
    EXTERNAL_DEPOSIT: 3,
    EXTERNAL_WITHDRAWL: 4,
  };

export default function ViewTickets({ type, data, TxData }) {
  let allTickets = [];
  let allTransactions = [];

  if (type === "open") {
    allTickets = data?.filter((ticket) => ticket?.ticket_status === 0);
    allTransactions = TxData?.filter((transaction) => transaction?.trnx_status == "1")
  } else if (type === "closed") {
    allTickets = data?.filter((ticket) => ticket?.ticket_status === 1);
    allTransactions = TxData?.filter((transaction) => transaction?.trnx_status == "2")
  } else {
    allTickets = data;
    allTransactions = TxData
  }

  return (
    <div>
      {allTickets && allTickets.length > 0 && (
        allTickets?.map((ticket, index) => {
          let transactionType =
            ticket?.ticket_status === 1
              ? "Create New Account ID"
              : "Internal Withdrawal Request";

          return (
            <div key={index} className="your-accounts-container">
              <div className="accounts-box">
                <div className="accounts-content">
                  <div className="account-content-text">
                    <div className="website-name mb-5">{transactionType}</div>
                    <div className="website-username">
                      {`${ticket?.request_id} | ${ticket?.web_name} | ${
                        ticket?.ticket_status === 1 ? "Completed" : "Pending"
                      } `}
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className={`font-13 font-bold ${
                      COLOR_CLASS[ticket?.ticket_status]
                    }`}
                  >
                    {ticket?.ticket_status === 1 ? "Completed" : "Pending"}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
      {allTransactions && allTransactions.length > 0 && (
        allTransactions?.map((transaction, index) => {
          let transactionType =
            transaction?.trnx_type === TRNX_TYPE.EXTERNAL_DEPOSIT
              ? "Web Deposit"
              : transaction?.trnx_type === TRNX_TYPE.INTERNAL_DEPOSIT
              ? `${config.TITLE} Deposit`
              : transaction?.trnx_type === TRNX_TYPE.EXTERNAL_WITHDRAWL
              ? "Web Withdrawl"
              : transaction?.trnx_type === TRNX_TYPE.INTERNAL_WITHDRAWL
              ?  `${config.TITLE} Withdrawl`
              : "";
          return (
            <div key={index} className="your-accounts-container">
              <div className="accounts-box">
                <div className="accounts-content">
                  <div className="account-content-text">
                    <div className="website-name mb-5">
                      {transactionType} - {transaction?.payment_method}
                    </div>
                    <div className="website-username">
                      {`${moment(transaction?.update_at).format(
                        "MMM Do YYYY, hh:mm A"
                      )} | Trxn ID - ${
                        transaction?.trnx_id.substring(0, 10) +
                        "....." +
                        transaction?.trnx_id.substring(
                          transaction?.trnx_id.length - 5,
                          transaction?.trnx_id.length
                        )
                      }`}
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className={`${
                      transactionType.includes("Deposit")
                        ? ""
                        : "transaction-details"
                    } accounts-view`}
                  >
                    {transactionType.includes("Deposit")
                      ? `+ ${localStorage?.getItem("currency_code") || ""}`
                      : `- ${
                          localStorage?.getItem("currency_code") || ""
                        }`}{" "}
                    {transaction?.trnx_amount}
                  </div>
                  <div
                    className={`font-13 ${
                      COLOR_CLASS[transaction.trnx_status]
                    }`}
                  >
                    {TRANX_STATUS[transaction.trnx_status]}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) }
    </div>
  );
}
