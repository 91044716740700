import {
  IonButton,
  IonIcon,
  IonImg,
  IonInput,
  IonLabel,
  IonModal,
  IonRow,
  IonSpinner
} from "@ionic/react";
import { closeCircleOutline, eye, eyeOff } from "ionicons/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { call } from "../../components/api/axios";
import { LOGIN, REQUEST_AUTHENTICATOR_OTP } from "../../components/api/endpoints";
import { setLocalStorageItems, validation } from "../../helper";
import { setAuthenticatorModal, setLoginModal } from "../../redux/actions/dataActions";
import "./styles.css";

const Login = () => {
  const history = useHistory();
  const loginModal = useSelector((state) => state?.data?.loginModal);
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [errorObj, setErrorObj] = useState({});

  const [loginData, setLoginData] = useState({
    user_name: "",
    user_password: "",
  });

  const LOGIN_INPUT_FIELDS = [
    {
      name: "user_name",
      id: "user_name",
      label: "",
      value: loginData?.user_name || "",
      type: "text",
      placeholder: "Username or Email",
      icon: "",
      required: true,
      rules: {
        method: "empty",
        condition: "",
      },
    },
    {
      name: "user_password",
      id: "user_password",
      label: "",
      value: loginData?.user_password || "",
      type: togglePassword ? "text" : "password",
      placeholder: "Password",
      icon: togglePassword ? eye : eyeOff,
      required: true,
      rules: {
        method: "empty",
        condition: "",
      },
    },
  ];

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.detail.value });
    setErrorObj({ ...errorObj, [e.target.name]: false });
  };

  const validate = async () => {
    let arr = [];
    LOGIN_INPUT_FIELDS?.forEach((input) => {
      let errMsg;
      if (input?.required) {
        errMsg = validation(
          input?.value,
          input?.rules?.method,
          input?.rules?.condition
        );
        if (errMsg) {
          return arr.push({ [input.name]: errMsg });
        } else {
          return arr.push({ [input.name]: false });
        }
      }
    });

    let temp;
    arr?.forEach((item) => {
      temp = { ...temp, ...item };
    });
    setErrorObj({ ...errorObj, ...temp });
    return temp;
  };

  const requestOTP = async () => {
    await call(
      { ...REQUEST_AUTHENTICATOR_OTP, url: REQUEST_AUTHENTICATOR_OTP.url },
      { userId: localStorage?.getItem("user_id") }
    )
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  const handleLogin = async () => {
    let isValid = await validate();

    if (Object.keys(isValid).length === 0) {
      return;
    }

    for (let key in isValid) {
      if (isValid[key]) {
        return;
      }
    }
    setLoading(true);
    await call({ ...LOGIN, url: LOGIN.url }, loginData)
      .then((res) => {
        if (res?.data?.data?.result?.auth_token) {
          setLoading(false);
          let temp = res?.data?.data?.result;
          setLocalStorageItems({
            token: temp?.auth_token,
            user_id: temp?.user_id,
            currency_code: temp?.currency_code,
            master_id: temp?.master_id,
          });
        dispatch(setLoginModal(false));
        // if(temp?.isVerified || temp?.contactType === "secondary") {
        //   dispatch(setAuthenticatorModal(true))
        //   requestOTP()
        // }
        // else {
        //   setLoading(false);
        //   navigate("/guidelinespage")
        // }
      localStorage?.setItem("isVerified", true);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          setLoading(false);
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        setErrMsg(err?.message);
      });
  };

  // useEffect(() => {
  //   if (localStorage.getItem("token") != null) {
  //     history.push("/");
  //   }
  // }, []);

  const navigate = async (path) => {
    dispatch(setLoginModal(false));
    dispatch(setAuthenticatorModal(false));
    setTimeout(() => {
      history.push(path);
    }, 200);
  };

  return (
    <IonModal isOpen={loginModal} className="login-model">
      <div className="ion-padding">
        <IonIcon
          icon={closeCircleOutline}
          className="close-login"
          onClick={() => dispatch(setLoginModal(false))}
        />
        <IonRow className="login-header-logo-conatiner ion-margin-bottom">
          <IonLabel className="loginHeading">Login</IonLabel>
          <IonImg
            src={process.env.PUBLIC_URL + "/images/LoginLogo.png"}
            alt="loginLogo"
            className="logoImage"
          />
        </IonRow>
        {LOGIN_INPUT_FIELDS?.map(
          ({ name, icon, id, label, placeholder, type, value }) => (
            <div key={id}>
              <IonRow className="ion-margin-vertical login-input-container">
                <IonInput
                  name={name}
                  value={value}
                  type={type}
                  placeholder={placeholder}
                  className="login-field"
                  onIonChange={(e) => handleChange(e)}
                ></IonInput>
                {icon && (
                  <IonIcon
                    icon={icon}
                    className="eye-icon"
                    onClick={() => setTogglePassword((prev) => !prev)}
                  />
                )}
              </IonRow>
              <IonLabel size="small" color="danger">
                {errorObj[name] && errorObj[name]}
              </IonLabel>
            </div>
          )
        )}
        <div
          className="ion-text-end forgot-password-text"
          onClick={() => navigate("/forgot-password")}
        >
          Forgot password?
        </div>
        <IonLabel size="small" color="danger">
          {errMsg && errMsg}
        </IonLabel>
        <IonButton
          type="submit"
          fill="clear"
          className="loginButton"
          disabled={loading}
          onClick={() => handleLogin()}
        >
          {loading ? (
            <div className="sign-in-loading">
              <span>Signing in... </span>
              <IonSpinner name="crescent" />
            </div>
          ) : (
            "Sign in"
          )}
        </IonButton>
        <div className="signup-link">
          <p>
            Don't have an account?{" "}
            <span onClick={() => navigate("/signup")}> Sign Up</span>
          </p>
        </div>
      </div>
    </IonModal>
  );
};
export default Login;
