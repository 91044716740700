import { useState } from "react";
import { CiWallet } from "react-icons/ci";
import { TfiWallet } from "react-icons/tfi";
import { AiOutlineGift } from "react-icons/ai";
import { FaHandHoldingUsd } from "react-icons/fa";
import {
  chevronUpCircleOutline,
  chevronDownCircleOutline,
} from "ionicons/icons";
import { IonIcon } from "@ionic/react";

function WalletBalanceDetails() {
  const [activeIndex, setActiveIndex] = useState(true);
  const handleActiveIndex = (index) => {
    setActiveIndex(!activeIndex);
  };
  const sportsBalance = [
    {
      balType: "Sports Bal",
      amount: "100000",
    },
    {
      balType: "Exposer",
      amount: "5000",
    },
    {
      balType: "Ref Bonus",
      amount: "2000",
    },
    {
      balType: "Other Bonus",
      amount: "1000",
    },
  ];
  const casinoBalance = [
    {
      balType: "Casino Bal",
      amount: "100000",
    },
    {
      balType: "Exposer",
      amount: "5000",
    },
    {
      balType: "Bonus",
      amount: "2000",
    },
  ];
  const usedBonusBalance = [
    {
      balType: "Sports",
      amount: "2000",
    },
    {
      balType: "Casino",
      amount: "3000",
    },
  ];
  return (
    <div className="my-wallet-main-container">
      <div className="flex-sp-a">
        <div className="flex-sp-a my-wallet-display-div">
          <div className="pad-5">
            <i className="flex-jc-c">
              <CiWallet />
            </i>
            <div>My Wallet</div>
          </div>
          <div>100000</div>
          <div>
            <IonIcon
              className="activitylog-arrow"
              icon={
                activeIndex ? chevronUpCircleOutline : chevronDownCircleOutline
              }
              onClick={() => handleActiveIndex()}
            />
          </div>
        </div>
        <div className="flex-sp-a my-wallet-display-div">
          <div className="pad-5">
            <i className="flex-jc-c">
              <TfiWallet color="#0ac71c" />
            </i>
            <div>Casino Balance</div>
          </div>
          <div>125000</div>
        </div>
      </div>

      {activeIndex && (
        <>
          <div className="flex-sp-a mt-5">
            <div className="h-120px flex-column my-wallet-display-div">
              {sportsBalance &&
                sportsBalance?.map((value, index) => (
                  <div key={index} className="flex my-wallet-balance-div mt-5">
                    <spna className="w-60 pl-5">{value.balType}</spna>
                    <span
                      className={`${
                        value.balType === "Exposer" ? "loss-color" : ""
                      }`}
                    >
                      {value.amount}
                    </span>
                  </div>
                ))}
              <div className="flex mt-5">
                <spna className="w-60 pl-10">Total</spna>
                <span>97000</span>
              </div>
            </div>
            <div className="h-120px flex-column my-wallet-display-div">
              {casinoBalance &&
                casinoBalance?.map((value, index) => (
                  <div key={index} className="flex my-wallet-balance-div mt-5">
                    <spna className="w-60 pl-5">{value.balType}</spna>
                    <span
                      className={`${
                        value.balType === "Exposer" ? "loss-color" : ""
                      }`}
                    >
                      {value.amount}
                    </span>
                  </div>
                ))}
              <div className="flex mt-5">
                <spna className="w-60 pl-10">Total</spna>
                <span>27000</span>
              </div>
            </div>
          </div>

          <div className="flex-sp-a mt-5">
            <div className="flex flex-column w-48">
              <div className="flex-sp-a my-wallet-display-div w-100">
                <div className="pad-5">
                  <i className="flex-jc-c">
                    <TfiWallet color="#0ac71c" />
                  </i>
                  <div>Available Balance</div>
                </div>
                <div>119000</div>
              </div>
              <div className="flex-sp-a my-wallet-display-div w-100 mt-5">
                <div className="pad-5">
                  <i className="flex-jc-c">
                    <AiOutlineGift color="#f29f0d" />
                  </i>
                  <div>Ledger Balance with All Bonus</div>
                </div>
                <div>130000</div>
              </div>
            </div>
            <div className="h-132px flex-column my-wallet-display-div">
              <div className="flex-ac">
                <i className="flex-jc-c mar-10">
                  <FaHandHoldingUsd color="#e7200b" />
                </i>
                <div>Used Bonus</div>
              </div>
              {usedBonusBalance &&
                usedBonusBalance?.map((value, index) => (
                  <div key={index} className="flex my-wallet-balance-div mt-5">
                    <spna className="w-60 pl-5">{value.balType}</spna>
                    <span>{value.amount}</span>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default WalletBalanceDetails;
