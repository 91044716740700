import { Browser } from "@capacitor/browser";
import { IonAvatar, IonContent, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { call } from "../../../components/api/axios";
import {
  GET_USER_WEB_ACCOUNTS_LIST_TO_REGISTER,
  GET_USER_WEB_ACCOUNTS_REGISTRED_LIST
} from "../../../components/api/endpoints";
import { persistData } from "../../../components/common/commonData";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import { setLoading } from "../../../redux/actions";
import {
  setWebAccountRegisteredList,
  setWebAccountsList
} from "../../../redux/actions/dataActions";
import "./styles.css";

function ViewAccounts(props) {
  const result = persistData();
  const {
    webAccountsList,
    webAccountsListFlag,
    webAccountsRegisteredList,
    webAccountsRegisteredListFlag,
  } = result;
  let userId = localStorage?.getItem("user_id");
  const history = useHistory();
  const dispatch = useDispatch();
  let parnterStatus = props?.location?.state?.data;
  const [activeTab, setActiveTab] = useState(parnterStatus === 1 ? false : true);
  const [registeredWebAccounts, setRegisteredWebAccounts] = useState(
    webAccountsRegisteredList
  );
  const [webAccountsToRegister, setWebAccountToRegister] =
    useState(webAccountsList);

  const handleAccounts = () => {
    setActiveTab(true);
  };
  const handleAvailablePartners = () => {
    setActiveTab(false);
  };

  const getUserWebRegisteredAccounts = async () => {
    webAccountsListFlag && dispatch(setLoading(true));
    await call(
      GET_USER_WEB_ACCOUNTS_REGISTRED_LIST,

      { user_id: userId }
    )
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          setRegisteredWebAccounts(res?.data?.data?.results);
          dispatch(setWebAccountRegisteredList(res?.data?.data?.results));
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const getWebAccountsToRegister = async () => {
    webAccountsRegisteredListFlag && dispatch(setLoading(true));
    await call(GET_USER_WEB_ACCOUNTS_LIST_TO_REGISTER, { user_id: userId })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          setWebAccountToRegister(res?.data?.data?.results);
          dispatch(setWebAccountsList(res?.data?.data?.results));
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  const openCapacitorSite = async (capUrl) => {
    if (!(capUrl.match("http://") || capUrl.match("https://"))) {
      capUrl = "http://" + capUrl;
    }
    //window.open(capUrl,'_system');
    await Browser.open({ url: capUrl });
  };
  const handleCreateId = (accountData) => {
    history.push({
      pathname: `/create-id/${accountData?.web_id}`,
      state: {
        data: accountData,
      },
    });
  };
  const webAlreadyRegisterd = (webId) => {
    return registeredWebAccounts.some(function (web) {
      return web.website_id === webId ? true : false;
    });
  };

  useEffect(() => {
    getUserWebRegisteredAccounts();
    getWebAccountsToRegister();
  }, []);

  return (
    <IonPage className="background">
      <IonContent className="background-none">
        <div className="accounts-container">
          <div className="account-header-container">
            <div>
              <Header title="Accounts" />
            </div>
            <div className="account-header">
              <div
                className={`${activeTab ? "active" : ""}`}
                onClick={() => handleAccounts()}
              >
                Login Wesite
              </div>
              <div
                className={`${activeTab ? "" : "active"}`}
                onClick={() => handleAvailablePartners()}
              >
                Available Wesite
              </div>
            </div>
          </div>

          {activeTab ? (
            <div className="account-container-scroll">
              {registeredWebAccounts && registeredWebAccounts?.length === 0 ? (
                <div className="transaction-no-data-found">No Data Found</div>
              ) : (
                <>
                  {registeredWebAccounts &&
                    registeredWebAccounts?.length > 0 &&
                    registeredWebAccounts.map((account, index) => {
                      return (
                        <div className="your-accounts-container" key={index}>
                          <div className="accounts-box">
                            <div className="accounts-content">
                              <IonAvatar>
                                <img
                                  src={
                                    account?.web_logo_url !== undefined &&
                                    account?.web_logo_url != "NA"
                                      ? account?.web_logo_url
                                      : process.env.PUBLIC_URL +
                                        "/images/profileAvatart.webp"
                                  }
                                  width={30}
                                  height={30}
                                />
                              </IonAvatar>
                              <div className="account-content-text ml-10">
                                <div className="website-name mb-5">
                                  {account?.web_name || "N/A"}
                                </div>
                                <div className="website-username">
                                  {`Username - ${
                                    account?.web_user_id || "N/A"
                                  }`}
                                </div>
                                <div className="last-activity">
                                  {`Last Activity - ${
                                    account?.update_at || "N/A"
                                  }`}
                                </div>
                                <div className="last-activity web-site-name">
                                  <span
                                    onClick={() =>
                                      openCapacitorSite(account?.web_url)
                                    }
                                  >
                                    {account?.web_url || ""}
                                  </span>
                                  {/* <a href={`${account?.web_url}`} target="_blank">
                                    {account?.web_url || ""}
                                  </a> */}
                                </div>
                              </div>
                            </div>
                            <div>
                              <Link
                                to={`/account-info/${account?.web_user_id}/${account?.website_id}`}
                                className="accounts-view"
                              >
                                view
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
          ) : (
            <div className="account-container-scroll">
              {webAccountsToRegister && webAccountsToRegister?.length === 0 ? (
                <div className="transaction-no-data-found">No Data Found</div>
              ) : (
                <>
                  {webAccountsToRegister &&
                    webAccountsToRegister?.length > 0 &&
                    webAccountsToRegister.map((account, index) => {
                      return webAlreadyRegisterd(account.web_id) ? null : (
                        <div className="your-accounts-container" key={index}>
                          <div className="accounts-box">
                            <div className="accounts-content">
                              <IonAvatar>
                                <img
                                  src={
                                    account?.web_logo_url != "NA"
                                      ? account?.web_logo_url
                                      : process.env.PUBLIC_URL +
                                        "/images/profileAvatart.webp"
                                  }
                                  width={30}
                                  height={30}
                                />
                              </IonAvatar>
                              <div className="account-content-text ml-10">
                                <div className="website-name mb-5">
                                  {account?.web_name || "N/A"}
                                </div>
                                <div className="last-activity">
                                  {`Min Deposit - ${
                                    localStorage?.getItem("currency_code") || ""
                                  } ${account?.web_min_deposit || 0}`}
                                </div>
                                <div className="last-activity web-site-name">
                                  <span
                                    onClick={() =>
                                      openCapacitorSite(account?.web_url)
                                    }
                                  >
                                    {account?.web_url || ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="create-id"
                              onClick={() => handleCreateId(account)}
                            >
                              Create Id
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
          )}
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
}

export default ViewAccounts;
