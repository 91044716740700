import React from "react";
import {
  AiFillLinkedin,
  AiFillInstagram,
  AiFillFacebook,
  AiOutlineTwitter,
} from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";

function SocialNetworks() {
  const sportName = [
    "Cricket",
    "Football",
    "Tennis",
    "Horse Racing",
    "Basketball",
  ];
  const socialMeadiaLogos = [
    {
      logo: <FaTelegramPlane />,
      cssClass: "social-telegram",
    },
    {
      logo: <AiFillInstagram />,
      cssClass: "social-insta",
    },
    {
      logo: <AiFillFacebook />,
      cssClass: "social-facebook",
    },
    {
      logo: <AiOutlineTwitter />,
      cssClass: "social-twitter",
    },
    {
      logo: <AiFillLinkedin />,
      cssClass: "social-linkedin",
    },
  ];
  return (
    <div>
      <div className="social-network flex">
        <img
          className="provider-image"
          src={process.env.PUBLIC_URL + "/assets/betFairLogo.png"}
        ></img>
        |
        <img
          className="provider-image"
          src={process.env.PUBLIC_URL + "/assets/sportLogo.png"}
        ></img>
        |
        <img
          className="provider-image"
          src={process.env.PUBLIC_URL + "/assets/sportrederLogo.png"}
        ></img>
      </div>
      <div className="flex-sp-a padding-5">
        {sportName.map((value, index) => {
          return (
            <div key={index} className="sport-name">
              {value}
            </div>
          );
        })}
      </div>
      <center className="mt-20">
        <span className="social-network-text">Social Networks</span>
      </center>
      <div className="flex-sp-a mt-10">
        {socialMeadiaLogos.map((value, index) => {
          return (
            <div
              key={index}
              className={`${value.cssClass} social-logo-icons flex-jc-c`}
            >
              {value.logo}
            </div>
          );
        })}
      </div>
      <div className="copy-right-text mt-20 mb-20 flex-jc-c">
        Copyright 2023, Texchange. All Right Reserved
      </div>
    </div>
  );
}
export default SocialNetworks;
