import { Browser } from "@capacitor/browser";
import { IonIcon, IonImg, IonText } from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import GuideImg from "./bookIcon.png";
import DownloadIcon from "./downloadIcon.png";
import "./GuideLinePage.css";
import newTextLogo from "./newLogo.png";

function GuideLinePage() {
  let apkUrl = "https://texch-apk.s3.amazonaws.com/Authenticator.apk"
  const GuideLines = [
    {
      id: 1,
      num: "1",
      guideLine: "Install Authenticator App",
    },
    {
      id: 2,
      num: "2",
      guideLine: "Register With Your Existing Mobile Number",
    },
    {
      id: 3,
      num: "3",
      guideLine: "Enter Your Mobile Number",
    },
    {
      id: 4,
      num: "4",
      guideLine: "Enter OTP Received on Your Mobile Number",
    },
    {
      id: 5,
      num: "5",
      guideLine: "Click on Submit",
    },
    {
      id: 6,
      num: "6",
      guideLine: "Again Login to EXCH Authenticator App",
    },
    {
      id: 7,
      num: "7",
      guideLine:
        "The Installation is Only For New Users Rest All Users Can Login Directly",
    },
  ];
  const openCapacitorSite = async (capUrl) => {
    if (!(capUrl.match("http://") || capUrl.match("https://"))) {
      capUrl = "http://" + capUrl;
    }
    console.log({capUrl})
    //window.open(capUrl,'_system');
    await Browser.open({ url: capUrl });
  };
  return (
    <div className="main-div display-flex-property">
      <div className="guideline-content">
        <Link className="guidlines-goback" to="/">
          <IonIcon icon={arrowBackOutline} />
          <span>Go Back</span>
        </Link>
        <div className="logo-div display-flex-property">
          <IonImg className="text-logo" src={newTextLogo} alt="logo" />
        </div>
        <div onClick={()=>openCapacitorSite(apkUrl)}>
          <div className="api-link-container display-flex-property">
            <IonImg
              className="download-icon"
              src={DownloadIcon}
              alt="download-icon"
            />

            <b className="download-text">DOWNLOAD APP</b>
          </div>
        </div>

        <div className="guide-div display-flex-property">
          <IonImg className="guide-image" src={GuideImg} alt="Guide-Image" />
          <span>How to Install Guide</span>
        </div>

        {GuideLines &&
          GuideLines.map((guide, index) => (
            <div key={index} className="guideline-div display-flex-property">
              <div className="one-image-div display-flex-property">
                <IonText className="one-image">{guide.num}</IonText>
              </div>
              <div className="guideline-text">
                <p>{guide.guideLine} </p>
              </div>
            </div>
          ))}

        <div className="support-link">
          <a href="/">For Support Click Here</a>
        </div>
      </div>
    </div>
  );
}

export default GuideLinePage;
