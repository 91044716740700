import { IonButton, IonImg, IonInput, IonIcon } from "@ionic/react";
import React, { useState } from "react";
import {
  chevronUpCircleOutline,
  chevronDownCircleOutline,
} from "ionicons/icons";
import DateRange from "./DateRange";

function MyBets() {
  const [webSiteList, setWebSiteList] = useState(false);
  const [selectWebsite, setSelectWebsite] = useState("");
  const [activeIndex, setActiveIndex] = useState(false);
  const [indexPosition, setIndexPosition] = useState("");
  const [showBetStatus, setShowBetStatus] = useState(false);
  const [betSiteName, setBetSiteName] = useState("");
  const [showSportList, setShowSportList] = useState(false);
  const [sportName, setSportName] = useState("");
  const handleActiveIndex = (index) => {
    setActiveIndex(!activeIndex);
    setIndexPosition(index);
  };
  const handleBetStatus = () => {
    setShowBetStatus(!showBetStatus);
  };
  const handleBetStatusList = (value) => {
    setBetSiteName(value);
  };
  const handleSportList = (value) => {
    setSportName(value);
  };
  const handleSport = () => {
    setShowSportList((prev) => !prev);
  };
  const webSites = [
    {
      image: "/images/tCasinoParkLogo.png",
      name: "T Casino Park",
      heading: "Benelux Slingshot",
      type: "Back",
      odds: "--",
      gameName: "Casino",
      stake: "10000",
      returns: "--",
    },
    {
      image: "/images/spark247Logo.png",
      name: "Spark 247",
      heading: "India vs Australia",
      type: "Back",
      odds: "1.54",
      gameName: "Cricket",
      stake: "10000",
      returns: "5400",
    },
    {
      image: "/images/sparkBookLogo.png",
      name: "Spark Book",
      heading: "India vs Australia",
      type: "NO",
      odds: "90",
      gameName: "10Overs | 145 NO",
      stake: "20000",
      returns: "--",
    },
    {
      image: "/images/spark999Logo.png",
      name: "Spark 999",
      heading: "India vs Australia",
      type: "Back",
      odds: "1.54",
      gameName: "Cricket",
      stake: "10000",
      returns: "1450",
    },
    {
      image: "/images/tExchangeLogo.png",
      name: "T Exchange",
      heading: "India vs Australia",
      type: "Back",
      odds: "1.78",
      gameName: "Cricket",
      stake: "10000",
      returns: "5450",
    },
  ];
  const plDetails = [
    {
      id: "1",
      leftSideData: "Bet ID",
      rightSideData: "123456789",
    },
    {
      id: "2",
      leftSideData: "Market ID",
      rightSideData: "12247744",
    },
    {
      id: "3",
      leftSideData: "settled Date & Time",
      rightSideData: "24-05-2023 | 10:30:22 am",
    },
    {
      id: "4",
      leftSideData: "Bet Place",
      rightSideData:
        "PL of Market: Test Matches /India vs Australia /Match Adds",
    },
    {
      id: "5",
      leftSideData: "Runner Name",
      rightSideData: "India",
      className: "bgColorBlue padding-2",
    },
    {
      id: "6",
      leftSideData: "Side",
      rightSideData: "Back",
      className: "bgColorBlue padding-2",
    },
    {
      id: "7",
      leftSideData: "Odds Rate",
      rightSideData: "1.54",
      className: "bgColorBlue padding-2",
    },
    {
      id: "8",
      leftSideData: "Bet Amount",
      rightSideData: "1000000",
      className: "bgColorBlue padding-2",
    },
  ];
  const plDetailsCasino = [
    {
      id: "1",
      leftSideData: "Bet ID",
      rightSideData: "123456789",
    },
    {
      id: "2",
      leftSideData: "Market ID",
      rightSideData: "12247744",
    },
    {
      id: "3",
      leftSideData: "settled Date & Time",
      rightSideData: "24-05-2023 | 10:30:22 am",
    },
    {
      id: "4",
      leftSideData: "Bet Place",
      rightSideData:
        "PL of Market: Benelux Slingshot (Auto Roulette La Partage)",
    },
    {
      id: "5",
      leftSideData: "Runner Name",
      rightSideData: "Benelux Slingshot(Auto Roulette La)",
      className: "bgColorBlue padding-2",
    },
    {
      id: "6",
      leftSideData: "Side",
      rightSideData: "Back",
      className: "bgColorBlue padding-2",
    },
    {
      id: "7",
      leftSideData: "Odds Rate",
      rightSideData: "000",
      className: "bgColorBlue padding-2",
    },
    {
      id: "8",
      leftSideData: "Bet Amount",
      rightSideData: "10000",
      className: "bgColorBlue padding-2",
    },
  ];

  const plDetailsFancy = [
    {
      id: "1",
      leftSideData: "Bet ID",
      rightSideData: "123456789",
    },
    {
      id: "2",
      leftSideData: "Market ID",
      rightSideData: "12247744",
    },
    {
      id: "3",
      leftSideData: "settled Date & Time",
      rightSideData: "24-05-2023 | 10:30:22 am",
    },
    {
      id: "4",
      leftSideData: "Bet Place",
      rightSideData:
        "PL of Market: Test Matches /India vs Australia /Match Adds/10 Overs- 145 No",
    },
    {
      id: "5",
      leftSideData: "Runner Name",
      rightSideData: "Australia",
      className: "bgColorBlue padding-2",
    },
    {
      id: "6",
      leftSideData: "Side",
      rightSideData: "No",
      className: "bgColorBlue padding-2",
    },
    {
      id: "7",
      leftSideData: "Odds Rate",
      rightSideData: "90",
      className: "bgColorBlue padding-2",
    },
    {
      id: "8",
      leftSideData: "Bet Amount",
      rightSideData: "20000",
      className: "bgColorBlue padding-2",
    },
  ];

  const betStatusList = ["All", "Open", "Settled", "Won", "Lost", "Void"];
  const sportsList = [
    "All",
    "Cricket",
    "Football",
    "Tennis",
    "Basketball",
    "Baseball",
  ];
  const handleShowwebSite = () => {
    setWebSiteList((prev) => !prev);
  };
  const handleWebList = (item) => {
    setSelectWebsite(item);
    // setWebSiteList(false);
  };

  return (
    <div className="account-Statement">
      <div className="account-statement-header">Multi Market</div>
      <div
        className="flex website-dropdown mt-5 justify-start"
        onClick={handleShowwebSite}
      >
        {selectWebsite ? selectWebsite : "All"}
        <IonIcon
          className="websites-arrow"
          icon={webSiteList ? chevronUpCircleOutline : chevronDownCircleOutline}
        />
        {webSiteList && (
          <div className="website-list-conaitar my-bets-list-position w-100">
            {webSites.map((value, index) => {
              return (
                <div
                  key={index}
                  className="webs-container flex"
                  onClick={() => handleWebList(value.name)}
                >
                  <div className="flex website-list">
                    <div className="reports-image-container mr-10 flex">
                      <IonImg
                        className="reports-image"
                        src={process.env.PUBLIC_URL + value.image}
                      ></IonImg>
                    </div>
                    <span className="reports-name">{value.name}</span>
                  </div>
                  <div className="mr-10 select-option">select</div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="w-100 h-75 flex-sp-b bet-status-container">
        <div className="w-48">
          <div className="account-statement-header">Bet Status</div>
          <div
            className="flex website-dropdown mt-5 justify-start w-100"
            onClick={() => {
              handleBetStatus();
            }}
          >
            {betSiteName ? betSiteName : "All"}
            <IonIcon
              className="websites-arrow arrow-position"
              icon={
                showBetStatus
                  ? chevronUpCircleOutline
                  : chevronDownCircleOutline
              }
            />
            {showBetStatus && (
              <div className="betstatus-list">
                {betStatusList.map((value, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        handleBetStatusList(value);
                      }}
                    >
                      {value}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="w-48">
          <div className="account-statement-header">Select Games</div>
          <div
            className="flex website-dropdown mt-5 justify-start w-100"
            onClick={() => {
              handleSport();
            }}
          >
            {sportName ? sportName : "All"}
            <IonIcon
              className="websites-arrow"
              icon={
                showSportList
                  ? chevronUpCircleOutline
                  : chevronDownCircleOutline
              }
            ></IonIcon>
            {showSportList && (
              <div className="betstatus-list">
                {sportsList.map((value, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        handleSportList(value);
                      }}
                    >
                      {value}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="website-select-date flex">
        <div className="flex flex-column w-45">
          <span className="mt-5 ml-5">Start Date</span>
          <IonInput
            className="website-date text-uppercase start-end-date mt-5"
            type="date"
          >
            <IonImg
              src={process.env.PUBLIC_URL + "/images/CalenderIcon.png"}
              alt="calendar-icon"
              className="calendar-icon"
            />
          </IonInput>
        </div>
        <IonImg
          className="arrow-image mt-10"
          src={process.env.PUBLIC_URL + "/images/leftRightArrow.png"}
        ></IonImg>
        <div className="flex flex-column w-45">
          <span className="mt-5 ml-5">End Date</span>
          <IonInput
            className="website-date text-uppercase start-end-date mt-5"
            type="date"
          >
            <IonImg
              src={process.env.PUBLIC_URL + "/images/CalenderIcon.png"}
              alt="calendar-icon"
              className="calendar-icon"
            />
          </IonInput>
        </div>
      </div>
      <div className="flex w-100 mt-10 pr-10">
        <div className="w-33">
          <span className="my-bet-report-header my-bet-report-match-header">
            Match
          </span>
        </div>
        <span className="w-16 my-bet-report-header">Type</span>
        <span className="w-16 my-bet-report-header">Odds</span>
        <span className="w-16 my-bet-report-header">Stake</span>
        <span className="w-16 my-bet-report-header">Returns</span>
      </div>
      <div className="account-statement-container">
        {webSites.map((value, index) => {
          return (
            <div>
              <div
                key={index}
                className="activity-log-text-img-div mb-10 mt-10"
              >
                <div className="activitylog-text-img-arrow-div">
                  <div className="w-33 match-adds-conatainer">
                    <span>{value.name}</span>
                    <div className="match-adds">{value.heading}</div>
                    <span>{value.gameName}</span>
                  </div>
                  <div className="w-16 my-bet-report-header txt-left">
                    {value.type}
                  </div>
                  <div className="w-16 my-bet-report-header txt-left">
                    {value.odds}
                  </div>
                  <div className="w-16 my-bet-report-header txt-left">
                    {value.stake}
                  </div>
                  <div className="w-16 my-bet-report-header returns-class ">
                    {value.returns}
                  </div>

                  <IonIcon
                    className="log-arrow"
                    icon={
                      indexPosition === index && activeIndex
                        ? chevronUpCircleOutline
                        : chevronDownCircleOutline
                    }
                    onClick={() => handleActiveIndex(index)}
                  />
                </div>
              </div>
              {activeIndex && indexPosition === index && (
                <div className="activeLog-inside-text">
                  {plDetails.map((value, index) => (
                    <div
                      key={index}
                      className={`activeLog-left-right-text mb-5 ${value.className}`}
                    >
                      <span className="w-45">{value.leftSideData}</span>
                      <span className="text-end">{value.rightSideData}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MyBets;
