import { IonContent, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { call } from "../../components/api/axios";
import { ALL_TICKETS, GET_USER_TRANSACTIONS } from "../../components/api/endpoints";
import { persistData } from "../../components/common/commonData";
import ViewTickets from "../../components/common/tickets";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { setALlTicktes, setLoading, setUserTransactions } from "../../redux/actions";

function Tickets(props) {
  const result = persistData();
  const { allTicketsData, allTicketsDataFlag, userTransactions, userTransactionsFlag } = result;
  let userId = localStorage?.getItem("user_id");
  let status = props?.location?.state?.data;
  const dispatch = useDispatch();
  const [all, setAll] = useState(status === 1 ? false : true);
  const [openTickets, setOpenTickets] = useState(false);
  const [closedTickets, setClosedTickets] = useState(
    status === 1 ? true : false
  );
  const [allTickets, setAllTickets] = useState(allTicketsData);
  const [allUserTransactions, setAllUserTransactions] =
  useState(userTransactions);

  const handleAllTransactions = () => {
    setAll(true);
    setOpenTickets(false);
    setClosedTickets(false);
  };
  const handleDepositTransactions = () => {
    setAll(false);
    setOpenTickets(true);
    setClosedTickets(false);
  };

  const handleWithDrawalTransactions = () => {
    setAll(false);
    setOpenTickets(false);
    setClosedTickets(true);
  };

  const getAllTickets = async () => {
    allTicketsDataFlag && dispatch(setLoading(true));
    await call(ALL_TICKETS, { user_id: userId })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLoading(false));
          setAllTickets(res?.data?.data?.results);
          dispatch(setALlTicktes(res?.data?.data?.results));
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  const getUserTransactions = async () => {
    userTransactionsFlag && dispatch(setLoading(true));
    await call(GET_USER_TRANSACTIONS, {
      user_id: `${localStorage?.getItem("user_id")}`,
    })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setUserTransactions(res?.data?.data?.results));
          dispatch(setLoading(false));
          setAllUserTransactions(res?.data?.data?.results);
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };


  useEffect(() => {
    getAllTickets();
    getUserTransactions();
  }, []);

  return (
    <IonPage className="background">
      <IonContent className="background-none">
        <div className="account-header-container">
          <div className="accoutns-notification">
            <Header title="Tickets" />
          </div>
          <div className="account-header ">
            <div
              className={`${all ? "active" : ""}`}
              onClick={() => handleAllTransactions()}
            >
              ALL
            </div>
            <div
              className={`${openTickets ? "active" : ""}`}
              onClick={() => handleDepositTransactions()}
            >
              OPEN
            </div>
            <div
              className={`${closedTickets ? "active" : ""}`}
              onClick={() => handleWithDrawalTransactions()}
            >
              CLOSED
            </div>
          </div>
        </div>
        {all && <ViewTickets type="all" data={allTickets} TxData={allUserTransactions} />}
        {openTickets && (
          <div>
            <ViewTickets type="open" data={allTickets} TxData={allUserTransactions} />
          </div>
        )}
        {closedTickets && (
          <div>
            <ViewTickets type="closed" data={allTickets} TxData={allUserTransactions} />
          </div>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
}

export default Tickets;
